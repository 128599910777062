import { ArrowDropDown } from '@mui/icons-material';
import { Button, Divider, ListItemIcon, MenuItem } from '@mui/material';
import { useMenu } from '@prismamedia/one-components';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import type { BreadCrumb } from '../..';
import { paths } from '../../../../../../../../routing/Router/paths';
import { DEFAULT_ROOT_CATEGORY } from '../../../../../../constants';
import type { Category } from '../../../../../../types';
import { navigateTo } from '../../../../../../utils';
import { useStyles as useSharedStyles } from '../../../../styles';
import { useStyles } from '../../styles';
import {
  BreadCrumbMenuItemTypes,
  CATEGORY_MENU_ITEMS,
  ROOT_CATEGORY_MENU_ITEMS,
} from './constants';

interface BreadCrumbMenuProps {
  activeCategory: Category | null;
  breadCrumb: BreadCrumb;
}

export const Menu: FC<BreadCrumbMenuProps> = ({
  activeCategory,
  breadCrumb,
}) => {
  const { openMenu, closeMenu } = useMenu();
  const routeParams = useParams();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const filteredMenuItems = useMemo(() => {
    const isRootActiveCategory =
      activeCategory?.level === DEFAULT_ROOT_CATEGORY.level;

    if (isRootActiveCategory) {
      const isRootCategoryHasChildren = !!activeCategory.children.length;

      return ROOT_CATEGORY_MENU_ITEMS.filter(({ id }) =>
        isRootCategoryHasChildren
          ? id !== BreadCrumbMenuItemTypes.CREATE_ELEMENT
          : true,
      );
    }

    return CATEGORY_MENU_ITEMS;
  }, [activeCategory]);

  const handleItemSelect = useCallback(
    (item: Record<'id', BreadCrumbMenuItemTypes>) => () => {
      const search = new URLSearchParams({ from: 'Breadcrumbs' }).toString();

      if (
        item.id === BreadCrumbMenuItemTypes.EDIT_TEMPLATE ||
        item.id === BreadCrumbMenuItemTypes.EDIT_ROOT_TEMPLATE
      ) {
        navigateTo({
          pathname: paths.PAGE_EDIT,
          routeParams,
          opts: { search },
        });
      } else if (item.id === BreadCrumbMenuItemTypes.CREATE_ELEMENT) {
        navigateTo({
          pathname: paths.PAGE_CREATE,
          routeParams,
          opts: { search },
        });
      }

      closeMenu();
    },
    [closeMenu, routeParams],
  );

  return (
    <Button
      classes={{ root: classes.buttonItem }}
      endIcon={<ArrowDropDown fontSize="large" />}
      size="medium"
      variant="text"
      onClick={(e) => {
        openMenu(
          e.currentTarget,
          filteredMenuItems.map((item, i) => [
            item.widthDivider && <Divider key={`${item.label}-divider-${i}`} />,
            <MenuItem
              classes={{ root: sharedClasses.actionsMenu }}
              key={item.label || `menu-item-${i}`}
              disabled={item.disabled}
              onClick={handleItemSelect(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>

              {item.label}
            </MenuItem>,
          ]),
        );
      }}
    >
      {breadCrumb.title}
    </Button>
  );
};
