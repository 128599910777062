import { FocusPoint, IframelyMedia } from '@prismamedia/one-components';
import { ContentState, EditorState, genKey } from 'draft-js';
import { editorStateToJSON } from 'megadraft';
import { filter, mergeDeepRight } from 'ramda';
import {
  ArticleStatus,
  GetArticle_article,
  GetArticle_article_articleQualifiers,
} from '../../__generated__/queries-web';
import { Crop, MediaBlock } from '../../types/draft';
import { SetArticleTagsActionPayload } from '../ArticleEdit/reducer';

enum SlideshowEditActionTypeEnum {
  ADD_SLIDE = 'ADD_SLIDE',
  REMOVE_ARTICLE_TAG = 'REMOVE_ARTICLE_TAG',
  REMOVE_SLIDES = 'REMOVE_SLIDES',
  SET_ARTICLE_STATUS = 'SET_ARTICLE_STATUS',
  SET_ARTICLE_TAGS = 'SET_ARTICLE_TAGS',
  SET_CATEGORY = 'SET_CATEGORY',
  SET_SOURCE = 'SET_SOURCE',
  SET_PUBLISHED_AT = 'SET_PUBLISHED_AT',
  SET_SELECTED_SLIDES = 'SET_SELECTED_SLIDES',
  SET_SLIDES = 'SET_SLIDES',
  SET_SLIDES_CREDIT = 'SET_SLIDES_CREDIT',
  SET_SLIDES_INDEX = 'SET_SLIDES_INDEX',
  SET_SLIDES_SOURCE = 'SET_SLIDES_SOURCE',
  SET_SLIDE_BUTTON_REL = 'SET_SLIDE_BUTTON_REL',
  SET_SLIDE_BUTTON_TITLE = 'SET_SLIDE_BUTTON_TITLE',
  SET_SLIDE_BUTTON_URL = 'SET_SLIDE_BUTTON_URL',
  SET_SLIDE_CAPTION = 'SET_SLIDE_CAPTION',
  SET_SLIDE_CROP = 'SET_SLIDE_CROP',
  SET_SLIDE_DESCRIPTION = 'SET_SLIDE_DESCRIPTION',
  SET_SLIDE_HAS_BUTTON = 'SET_SLIDE_HAS_BUTTON',
  SET_TITLE = 'SET_TITLE',
  UPDATE_SLIDE = 'UPDATE_SLIDE',
  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT',
  SET_ARTICLE_QUALIFIERS = 'SET_ARTICLE_QUALIFIERS',
}

export interface SlideshowEditAction {
  type: SlideshowEditActionTypeEnum;
  data: any;
}

type SlideshowEditActionsCreators = Record<
  string,
  { (payload: any): SlideshowEditAction }
>;

interface SlideshowEditState {
  article: GetArticle_article | null;
  slidesIndex: string[];
  selectedSlidesIndex: string[];
  slideMap: Record<string, MediaBlock>;
  zoomLevel: number;
}

export interface SlideOptions {
  iframely: IframelyMedia;
  caption?: string;
  slideshow?: {
    description?: any;
    hasButton?: boolean;
    buttonTitle?: string;
    buttonUrl?: string;
    buttonRel?: 'nofollow' | '';
  };
}

export const initialState = {
  article: null,
  slideMap: {},
  selectedSlidesIndex: [],
  slidesIndex: [],
  zoomLevel: 6,
};

export const actions: SlideshowEditActionsCreators = {
  setQualifiers: ({
    articleQualifiers,
  }: {
    articleQualifiers: GetArticle_article_articleQualifiers[];
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_ARTICLE_QUALIFIERS,
    data: {
      articleQualifiers,
    },
  }),
  setCategory: ({ categoryId }: { categoryId: string }) => ({
    type: SlideshowEditActionTypeEnum.SET_CATEGORY,
    data: {
      id: categoryId,
    },
  }),
  setSource: ({
    source,
  }: {
    source: { id: string; title: string } | null;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SOURCE,
    data: {
      source,
    },
  }),
  setTitle: ({ title }: { title: string }) => ({
    type: SlideshowEditActionTypeEnum.SET_TITLE,
    data: {
      title,
    },
  }),
  setPublishedAt: ({ publishedAt }: { publishedAt: string }) => ({
    type: SlideshowEditActionTypeEnum.SET_PUBLISHED_AT,
    data: {
      publishedAt,
    },
  }),
  setSlides: ({ slides }: { slides: MediaBlock[] }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDES,
    data: {
      slides,
    },
  }),
  setSlideCaption: ({
    slideKey,
    caption,
  }: {
    slideKey: string;
    caption: string;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_CAPTION,
    data: {
      slideKey,
      caption,
    },
  }),
  setSlidesCredit: ({
    slideKeys,
    credit,
  }: {
    slideKeys: string[];
    credit: string;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDES_CREDIT,
    data: {
      slideKeys,
      credit,
    },
  }),
  setSlidesSource: ({
    slideKeys,
    source,
  }: {
    slideKeys: string[];
    source: string | undefined;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDES_SOURCE,
    data: {
      slideKeys,
      source,
    },
  }),
  setSelectedSlides: ({ slideKeys }: { slideKeys: string[] }) => ({
    type: SlideshowEditActionTypeEnum.SET_SELECTED_SLIDES,
    data: { slideKeys },
  }),
  setSlidesIndex: ({ slidesIndex }: { slidesIndex: string[] }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDES_INDEX,
    data: { slidesIndex },
  }),
  setSlideDescription: ({
    slideKey,
    description,
  }: {
    slideKey: string;
    description: any;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_DESCRIPTION,
    data: { slideKey, description },
  }),
  zoomIn: () => ({ type: SlideshowEditActionTypeEnum.ZOOM_IN, data: {} }),
  zoomOut: () => ({ type: SlideshowEditActionTypeEnum.ZOOM_OUT, data: {} }),
  addSlide: (options: SlideOptions) => ({
    type: SlideshowEditActionTypeEnum.ADD_SLIDE,
    data: options,
  }),
  setSlideHasButton: ({
    slideKey,
    hasButton,
  }: {
    slideKey: string;
    hasButton: boolean;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_HAS_BUTTON,
    data: { slideKey, hasButton },
  }),
  setSlideButtonTitle: ({
    slideKey,
    title,
  }: {
    slideKey: string;
    title: string;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_BUTTON_TITLE,
    data: { slideKey, title },
  }),
  setSlideButtonUrl: ({
    slideKey,
    url,
  }: {
    slideKey: string;
    url: string;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_BUTTON_URL,
    data: { slideKey, url },
  }),
  setSlideButtonRel: ({
    slideKey,
    nofollow,
  }: {
    slideKey: string;
    nofollow: boolean;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_BUTTON_REL,
    data: { slideKey, nofollow },
  }),
  setSlideCrop: ({
    slideKey,
    crop,
    focusPoint,
  }: {
    slideKey: string;
    crop: Crop;
    focusPoint: FocusPoint;
  }) => ({
    type: SlideshowEditActionTypeEnum.SET_SLIDE_CROP,
    data: { slideKey, crop, focusPoint },
  }),
  setArticleStatus: ({ status }: { status: ArticleStatus }) => ({
    type: SlideshowEditActionTypeEnum.SET_ARTICLE_STATUS,
    data: { status },
  }),
  setArticleTags: ({ field, value }: SetArticleTagsActionPayload) => ({
    type: SlideshowEditActionTypeEnum.SET_ARTICLE_TAGS,
    data: { field, value },
  }),
  updateSlide: (options: SlideOptions & { slideKey: string }) => ({
    type: SlideshowEditActionTypeEnum.UPDATE_SLIDE,
    data: { options, key: options.slideKey },
  }),
  removeArticleTag: ({ id }: { id: string }) => ({
    type: SlideshowEditActionTypeEnum.REMOVE_ARTICLE_TAG,
    data: { id },
  }),
  removeSlides: (param: Record<string, string[]>) => ({
    type: SlideshowEditActionTypeEnum.REMOVE_SLIDES,
    data: param,
  }),
};

export const reducer = (
  state: SlideshowEditState,
  action: SlideshowEditAction,
): SlideshowEditState => {
  switch (action.type) {
    case SlideshowEditActionTypeEnum.SET_ARTICLE_QUALIFIERS: {
      return mergeDeepRight(state, {
        article: {
          articleQualifiers: action.data.articleQualifiers,
        },
      }) as SlideshowEditState;
    }
    case SlideshowEditActionTypeEnum.SET_CATEGORY: {
      return mergeDeepRight(state, {
        article: {
          articleCategories: [{ category: { id: action.data.id } }],
        },
      }) as SlideshowEditState;
    }
    case SlideshowEditActionTypeEnum.SET_SOURCE: {
      return mergeDeepRight(state, {
        article: {
          source: action.data.source,
        },
      }) as SlideshowEditState;
    }
    case SlideshowEditActionTypeEnum.SET_TITLE: {
      return mergeDeepRight(state, {
        article: { title: action.data.title },
      }) as SlideshowEditState;
    }
    case SlideshowEditActionTypeEnum.SET_PUBLISHED_AT: {
      return mergeDeepRight(state, {
        article: { publishedAt: action.data.publishedAt },
      }) as SlideshowEditState;
    }
    case SlideshowEditActionTypeEnum.SET_SLIDES: {
      const { slides } = action.data;
      return Object.assign({}, state, {
        slidesIndex: slides.map((slide: MediaBlock) => slide.key),
        selectedSlidesIndex: [],
        slideMap: slides.reduce(
          (acc: Record<string, MediaBlock>, slide: MediaBlock) => {
            acc[slide.key] = slide;
            return acc;
          },
          {} as Record<string, MediaBlock>,
        ),
      });
    }
    case SlideshowEditActionTypeEnum.SET_SELECTED_SLIDES: {
      return Object.assign({}, state, {
        selectedSlidesIndex: action.data.slideKeys,
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDES_INDEX: {
      return Object.assign({}, state, { slidesIndex: action.data.slidesIndex });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_CAPTION: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              caption: action.data.caption,
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_DESCRIPTION: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              slideshow: {
                description: action.data.description,
              },
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDES_CREDIT: {
      return mergeDeepRight(state, {
        slideMap: {
          ...action.data.slideKeys.reduce(
            (acc: Record<string, any>, key: string) => {
              acc[key] = {
                data: {
                  credit: action.data.credit,
                  iframely: {
                    meta: {
                      credit: action.data.credit,
                    },
                  },
                },
              };
              return acc;
            },
            {},
          ),
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDES_SOURCE: {
      return mergeDeepRight(state, {
        slideMap: {
          ...action.data.slideKeys.reduce(
            (acc: Record<string, any>, key: string) => {
              acc[key] = {
                data: {
                  iframely: {
                    meta: {
                      source: action.data.source,
                    },
                  },
                },
              };
              return acc;
            },
            {},
          ),
        },
      });
    }
    case SlideshowEditActionTypeEnum.ZOOM_OUT: {
      return { ...state, zoomLevel: state.zoomLevel + 1 };
    }
    case SlideshowEditActionTypeEnum.ZOOM_IN: {
      return { ...state, zoomLevel: Math.max(state.zoomLevel - 1, 0) };
    }
    case SlideshowEditActionTypeEnum.ADD_SLIDE: {
      const key = genKey();
      let defaultDraftDescription;
      const { defaultDescription } = action.data.iframely.meta;
      if (defaultDescription) {
        const contentState = ContentState.createFromText(defaultDescription);
        const editorState = EditorState.createWithContent(contentState);
        defaultDraftDescription = editorStateToJSON(editorState);
      }
      return mergeDeepRight(state, {
        slideMap: {
          [key]: {
            key,
            type: 'atomic',
            data: {
              type: 'media',
              caption: action.data.iframely.meta.defaultTitle || '',
              ...(defaultDraftDescription
                ? {
                    slideshow: {
                      description: JSON.parse(defaultDraftDescription),
                    },
                  }
                : {}),
              ...action.data,
            },
            text: '',
          },
        },
        slidesIndex: [...state.slidesIndex, key],
      }) as SlideshowEditState;
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_HAS_BUTTON: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              slideshow: {
                hasButton: action.data.hasButton,
              },
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_BUTTON_TITLE: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              slideshow: {
                buttonTitle: action.data.title,
              },
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_BUTTON_URL: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              slideshow: {
                buttonUrl: action.data.url,
              },
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_BUTTON_REL: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              slideshow: {
                buttonRel: action.data.nofollow ? 'nofollow' : '',
              },
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.SET_ARTICLE_STATUS: {
      return {
        ...state,
        article: {
          ...state.article,
          status: action.data.status,
        } as GetArticle_article,
      };
    }
    case SlideshowEditActionTypeEnum.SET_ARTICLE_TAGS: {
      return {
        ...state,
        article: {
          ...state.article,
          [action.data.field]: action.data.value,
        } as GetArticle_article,
      };
    }
    case SlideshowEditActionTypeEnum.SET_SLIDE_CROP: {
      return mergeDeepRight(state, {
        slideMap: {
          [action.data.slideKey]: {
            data: {
              crop: action.data.crop,
              focusPoint: action.data.focusPoint,
            },
          },
        },
      });
    }
    case SlideshowEditActionTypeEnum.UPDATE_SLIDE: {
      const { options, key } = action.data;
      let defaultDraftDescription;
      const { defaultDescription } = options.iframely.meta;
      if (defaultDescription) {
        const contentState = ContentState.createFromText(defaultDescription);
        const editorState = EditorState.createWithContent(contentState);
        defaultDraftDescription = editorStateToJSON(editorState);
      }
      return {
        ...state,
        slideMap: {
          ...state.slideMap,
          [key]: {
            key,
            type: 'atomic',
            data: {
              type: 'media',
              caption: options.iframely.meta.defaultTitle || '',
              ...(defaultDraftDescription
                ? {
                    slideshow: {
                      description: JSON.parse(defaultDraftDescription),
                    },
                  }
                : {}),
              ...options,
            },
            text: '',
          },
        },
      };
    }
    case SlideshowEditActionTypeEnum.REMOVE_ARTICLE_TAG: {
      return {
        ...state,
        article: {
          ...state.article,
          [action.data.field]: action.data.value,
        } as GetArticle_article,
      };
    }
    case SlideshowEditActionTypeEnum.REMOVE_SLIDES: {
      const { slideKeys } = action.data;
      const newSlideMap = filter(
        (item) => !slideKeys.includes(item.key),
        state.slideMap,
      );
      const newSlidesIndex = state.slidesIndex.filter(
        (key) => !slideKeys.includes(key),
      );
      return {
        ...state,
        slideMap: newSlideMap,
        selectedSlidesIndex: [],
        slidesIndex: newSlidesIndex,
      };
    }
  }
  return state;
};
