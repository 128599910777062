import { ItemSerialized, MultimediaPlugin } from '@prismamedia/one-components';
import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AdvancedSearch } from '../../../../../../../../components/AppBar/AdvancedSearch';
import { searchByPluginType } from '../../../../../../../../components/Draft/plugins/shared/searchArticleForMedia';
import {
  getItemToIframely,
  getItemToRender,
} from '../../../../../../../../components/MediaInput/utils';
import { getAllowedMedias } from './utils';

interface ContentMultimediaProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onContentSelected: (content: ItemSerialized[]) => void;
}

export const ContentMultimedia: FC<ContentMultimediaProps> = ({
  isOpen,
  setIsOpen,
  onContentSelected,
}) => {
  const { brandKey } = useParams();
  const allowedMedias = useMemo(() => getAllowedMedias(brandKey), [brandKey]);

  const handleMultimediaPluginSearch = useCallback(
    async (searchParams, pluginType, opts) =>
      await searchByPluginType({
        searchParams,
        pluginType,
        opts,
        brandKey,
      }),
    [brandKey],
  );

  const handleSaveMultimediaContent = useCallback(
    (content: ItemSerialized[]) => {
      onContentSelected(content);
      setIsOpen(false);
    },
    [onContentSelected, setIsOpen],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <MultimediaPlugin
      advancedSearch={
        brandKey && {
          component: AdvancedSearch,
          props: { brandKey },
        }
      }
      allowedMedias={allowedMedias}
      defaultActions={false}
      itemToIframely={async (item, pluginType) =>
        (await getItemToIframely(item, pluginType))!
      }
      itemToRender={(item, pluginType) =>
        getItemToRender(brandKey, item, pluginType)
      }
      onClose={() => {
        setIsOpen(false);
      }}
      onSave={handleSaveMultimediaContent}
      searchFn={async (searchParams, opts, pluginType) =>
        handleMultimediaPluginSearch(searchParams, pluginType, opts)
      }
    />
  );
};
