import {
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
} from '@mui/material';
import { PageWithDrawer, useOnMount } from '@prismamedia/one-components';
import type { FC } from 'react';
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import FlipMove from 'react-flip-move';
import { useHistory, useParams } from 'react-router-dom';
import { usePrevious, useUnmount } from 'react-use';
import { Skeletons } from '../../../../components/Skeletons';
import { CategoriesContext } from '../../categories.context';
import { FormContext } from '../../form.context';
import { getCategoryForm } from '../../models/categoryForm.model';
import { Category } from '../../types';
import {
  getActivePathCategoryId,
  isPageEditView,
  navigateTo,
} from '../../utils';
import { DrawerContent } from './components/DrawerContent';
import { GeneralConfiguration } from './components/GeneralConfiguration';
import { Section } from './components/Section';
import { useEditSection } from './components/Section/useEditSection';
import {
  ALLOWED_CATEGORY_FORM_SECTIONS,
  GENERAL_CONFIG_ANCHOR_HASH,
  LIST_ANIMATION_DELAY,
  LIST_ANIMATION_DURATION,
  LIST_ENTER_ANIMATION,
  SECTION_PLACEHOLDER_HEIGHT,
} from './constants';
import { useStyles } from './styles';

let AUTO_SCROLL = false;

export const Edit: FC = () => {
  const { activeCategory, brandKey, isCategoriesLoading } = useContext(
    CategoriesContext,
  );
  const { form, handlers: formHandlers } = useContext(FormContext);

  const { handlers: sectionHandlers } = useEditSection();

  const classes = useStyles();
  const drawerCenterRef = useRef(null);
  const history = useHistory();
  const prevFormSectionsLength = usePrevious(form.sections.length);
  const routeParams = useParams();

  const handleSetForm = useCallback(
    (category: Category) => {
      const newForm = isPageEditView()
        ? getCategoryForm(category)
        : getCategoryForm({
            brandKey,
            format: category.format,
            level: category.level + 1,
            medias: `[]`,
            parent: { id: category.id },
          });
      formHandlers.setForm(newForm);
    },
    [formHandlers, brandKey],
  );

  /**
   * Fill the form as empty or as current active category
   * depending on the path (/create and /edit)
   */
  useEffect(() => {
    if (activeCategory) {
      const activeCategoryPathname = getActivePathCategoryId(routeParams);

      if (activeCategory.id === activeCategoryPathname) {
        handleSetForm(activeCategory!);
      }
    }
    // eslint-disable-next-line
  }, [activeCategory]);

  /**
   * Put the default hash in path when it doesn't exists
   */
  useOnMount(() => {
    if (!history.location.hash) {
      navigateTo({ opts: { hash: GENERAL_CONFIG_ANCHOR_HASH } });
    }
  });

  /**
   * Jump to the active section DOM node when the view component mounts
   */
  useLayoutEffect(() => {
    const { hash } = history.location;
    const isAllowedToScroll = Boolean(
      !isCategoriesLoading &&
        AUTO_SCROLL &&
        form.sections.length &&
        hash !== GENERAL_CONFIG_ANCHOR_HASH &&
        hash,
    );

    if (isAllowedToScroll && drawerCenterRef?.current) {
      AUTO_SCROLL = false;
      // We jump until end of drawerCenter element
      const DrawerCenterNode: HTMLDivElement = drawerCenterRef.current;
      DrawerCenterNode.scrollTop = DrawerCenterNode.scrollHeight;
    }
  }, [form.sections.length, history.location, isCategoriesLoading]);

  /**
   * Reset the form when the view component unmounts
   */
  useUnmount(() => {
    formHandlers.resetForm();
  });

  return (
    <Box className={classes.wrapper} component="div">
      <PageWithDrawer
        classes={{ leftDrawer: classes.leftDrawer }}
        leftDrawer={<DrawerContent />}
        ref={drawerCenterRef}
      >
        {/* GENERAL CONFIGURATION */}
        <GeneralConfiguration />

        {/* SECTIONS CONFIGURATION */}
        <Stack>
          {isCategoriesLoading && isPageEditView() ? (
            <Skeletons
              height={SECTION_PLACEHOLDER_HEIGHT}
              quantity={2}
              spacing={3}
            />
          ) : (
            <FlipMove
              appearAnimation={true}
              delay={LIST_ANIMATION_DELAY}
              disableAllAnimations={
                !form.sections.length ||
                form.sections.length === prevFormSectionsLength
              }
              duration={LIST_ANIMATION_DURATION}
              enterAnimation={LIST_ENTER_ANIMATION}
              leaveAnimation="fade"
            >
              {form.sections.map((sectionProps) => {
                return (
                  <Section
                    key={sectionProps.id}
                    {...sectionProps}
                    ref={createRef()}
                  />
                );
              })}
            </FlipMove>
          )}
        </Stack>
      </PageWithDrawer>

      {/* ADD SECTION BUTTON */}
      {!isCategoriesLoading && (
        <SpeedDial
          ariaLabel="Page edit actions"
          className={classes.speedDial}
          direction="up"
          icon={<SpeedDialIcon />}
        >
          {ALLOWED_CATEGORY_FORM_SECTIONS.map((action) => (
            <SpeedDialAction
              key={action.label}
              icon={action.icon}
              tooltipTitle={action.label}
              onClick={() => {
                sectionHandlers.handleSectionAdd({
                  type: action.type,
                  cb: (newSection) => {
                    // We jump the scroll until new section node
                    AUTO_SCROLL = true;
                    navigateTo({ opts: { hash: newSection.id } });
                  },
                });
              }}
            />
          ))}
        </SpeedDial>
      )}
    </Box>
  );
};
