import { ListSubheader, Typography } from '@mui/material';
import type { Asset } from '@prismamedia/fotoweb-api-client';
import type { BrandKey } from '@prismamedia/one-brandkey';
import { MultimediaList, ViewMode } from '@prismamedia/one-components';
import { FC, useCallback, useEffect, useState } from 'react';
import type { FotoProps } from '..';
import { loadStorySubjectPhoto } from '../../../Draft/plugins/fotoweb/loadStorySubjectPhoto';
import { PhotoCollapse } from '../components/PhotoCollapse';
import { useStyles as usePhotoCollapseStyles } from '../components/styles';
import { PhotoIcon } from '../Icon';
import { PHOTOS_TECHNICAL_SOURCES } from '../constants';
import { FotoItemSerialized, PhotoCollapsesTypes } from '../types';
import { SUBJECT_PHOTOS_TEXT } from './constants';
import { useStyles } from './styles';
import { uploadAndEmbedImageUrl } from '../../../../utils/upload';
import { useParams } from 'react-router-dom';
import { getMetadata } from '../../../../apollo/utils/getMetadata';
import { MetadataNameEnumType } from '../../../../__generated__/queries-photo';

interface OneStoryProps extends FotoProps {
  brandKey: BrandKey;
  isCollapseOpen: boolean;
  onCollapseClick: (summary: PhotoCollapsesTypes | null) => void;
  topicId?: string;
}

export const OneStory: FC<OneStoryProps> = ({
  isCollapseOpen,
  isDisabled,
  isLoading,
  itemToRender,
  items,
  onCollapseClick,
  onItemClick,
  pluginType,
  selectedItems,
  setItems,
  topicId,
}) => {
  const { brandKey } = useParams();
  const [defaultItemsLength, setDefaultItemsLength] = useState<number | null>(
    null,
  );
  const [isStoryPhotoLoading, setIsStoryPhotoLoading] = useState(false);

  const classes = useStyles({ isOpen: isCollapseOpen });
  const photoCollaspeClasses = usePhotoCollapseStyles({});

  const getItemToIframely = async (item: any) => {
    const iframely = await uploadAndEmbedImageUrl(
      item.downloadUrl,
      brandKey,
      getMetadata(MetadataNameEnumType.Credit, item) || undefined,
    );

    if (iframely) {
      iframely.meta.technicalSource = PHOTOS_TECHNICAL_SOURCES.ONE_STORY;
    }

    return iframely;
  };

  const searchStoryPhotos = useCallback(async () => {
    setIsStoryPhotoLoading(true);

    const data = await loadStorySubjectPhoto(topicId!);
    if (data && Array.isArray(data)) {
      const newItems = data.map((photo: any) => ({
        ...photo,
        from: PhotoCollapsesTypes.ONE_STORY,
        // Because story photo id can be null, we using another unique fallback value as id
        id: photo.id || photo.filename || photo.href,
        pluginType,
      }));

      setItems(newItems);
      setDefaultItemsLength(newItems.length);
    }

    setIsStoryPhotoLoading(false);
  }, [pluginType, setItems, topicId]);

  useEffect(() => {
    if (isCollapseOpen && topicId) {
      searchStoryPhotos();
    }
    // eslint-disable-next-line
  }, [isCollapseOpen, topicId]);

  return (
    <PhotoCollapse
      collapseType={PhotoCollapsesTypes.ONE_STORY}
      defaultItemsLength={defaultItemsLength}
      Icon={<PhotoIcon fill="#009688" />}
      isOpen={isCollapseOpen}
      isPhotoLoading={isStoryPhotoLoading}
      onCollapseClick={onCollapseClick}
    >
      <div className={classes.topInnerWrapper}>
        <ListSubheader
          className={classes.headerText}
          component="span"
          disableSticky
        >
          <Typography variant="body2" gutterBottom>
            <span className={photoCollaspeClasses.suggestText}>
              {SUBJECT_PHOTOS_TEXT} (
              {isStoryPhotoLoading ? '...' : items.length})
            </span>
          </Typography>
        </ListSubheader>
      </div>
      <MultimediaList
        className={classes.wrapperList}
        isDisabled={isDisabled}
        isLoading={isLoading || isStoryPhotoLoading}
        itemToRender={itemToRender}
        items={items}
        onItemClick={(rowItem: FotoItemSerialized) => {
          onItemClick(rowItem, pluginType, () =>
            getItemToIframely((rowItem as unknown) as Asset),
          );
        }}
        pluginType={pluginType}
        selectedItems={selectedItems}
        viewMode={ViewMode.GRID}
      />
    </PhotoCollapse>
  );
};
