import { CloudOff, MobileScreenShare, RemoveRedEye } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { brandTitleByKey } from '@prismamedia/one-brandkey';
import {
  CardItem,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { map, property } from 'lodash';
import { FC, MouseEvent, memo, useMemo, useState } from 'react';
import {
  Link as RouterLink,
  generatePath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  ArticleFormat,
  ArticleStatus,
  GetArticles_articles,
  GetArticles_articles_articleCategories,
  GetArticles_articles_articleChannels,
} from '../../../../../__generated__/queries-web';
import {
  useArticleUpdater,
  useUpdateArticleLockerId,
} from '../../../../../apollo/mutations/article.web.graphql';
import { ArticleMenu } from '../../../../../components/ArticleMenu';
import { ListMediasIcons } from '../../../../../components/ListMediasIcons';
import { paths } from '../../../../../routing/Router/paths';
import { auth } from '../../../../../utils/auth';
import { brandsOptions } from '../../../../../utils/brands';
import { formatDate } from '../../../../../utils/dateUtils';
import { getImageFromArticleBody } from '../../../../../utils/getImageFromArticleBody';
import {
  getImageFromMedias,
  getVideoThumbnail,
} from '../../../../../utils/media';
import { statusToLabel } from '../../../../../utils/statuses';
import { isOneWeb, standardizeArticleUrl } from '../../../../../utils/url';
import { useMediasIcons } from '../../../../../utils/useMediasIcons';
import { PAID_ARTICLE } from './constants';
import { ArticleItemStyleProps, useStyles } from './styles';

type ArticleItemProps = GetArticles_articles & {
  brand?: string | null;
};

export const ArticleItem: FC<ArticleItemProps> = memo(
  ({
    id,
    body,
    title,
    status,
    publishedAt,
    editedAt,
    articleEvents = [],
    articleAuthors = [],
    articleCategories = [],
    articleChannels = [],
    articleCreatorData,
    brandKey,
    brand,
    format,
    medias,
    pushCount,
    live,
    contextualizedUrl,
    articleExtension,
    articleQualifiers,
  }) => {
    const [upsert, { loading }] = useArticleUpdater();
    const { pushNotification } = useNotification();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
    const updateLockerId = useUpdateArticleLockerId();
    const classes = useStyles() as ArticleItemStyleProps;
    const currentBrand = brand || '';
    const articleLink =
      format === ArticleFormat.Slideshow
        ? `${generatePath(paths.SLIDESHOW_EDIT, { id })}${
            currentBrand !== '' ? `?brand=${currentBrand}` : ''
          }`
        : generatePath(paths.ARTICLE_EDIT, {
            brandKey: brandKey!,
            articleType: format === ArticleFormat.Rich ? 'article' : 'video',
            search: brand ? 'search' : undefined,
            id,
          });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const history = useHistory();
    const open = Boolean(anchorEl);
    const isLocked =
      articleExtension?.lockedBy &&
      articleExtension?.lockedBy.id === auth.user?.id;

    const handleClose = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    const handleMutation = async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(null);

      const props = {
        variables: { where: { id }, data: { status: ArticleStatus.Draft } },
      };
      const result = await upsert(props).catch(() => {
        pushNotification({
          message: 'Erreur durant la modification du status',
          type: NotificationTypeEnum.error,
        });
      });
      if (result && result.data && result.data.updateArticle) {
        pushNotification({
          message: 'Le status est passé en brouillon',
          type: NotificationTypeEnum.success,
        });
      }
    };

    const image = useMemo(() => {
      switch (format) {
        case ArticleFormat.Slideshow:
          return getImageFromArticleBody(body, true);
        case ArticleFormat.Video:
          return getVideoThumbnail(medias);
        default:
          return getImageFromMedias(medias).mediaForDisplay;
      }
    }, [format, body, medias]);

    const displayTitle = (
      articleData:
        | GetArticles_articles_articleChannels[]
        | GetArticles_articles_articleCategories[],
    ) => map(articleData, property('category.title'));

    const categoryTitle: any = useMemo(() => {
      const _title =
        format === ArticleFormat.Video
          ? displayTitle(articleChannels)
          : displayTitle(articleCategories);
      if (!_title.length) {
        return null;
      }
      return _title.length === 2 ? `${_title[0]} / ${_title[1]}` : _title[0];
    }, [format, articleCategories, articleChannels]);

    const eventTitle = useMemo(() => {
      if (articleEvents.length > 0) {
        return articleEvents.length === 2
          ? `${articleEvents[0].category.title}, ${articleEvents[1].category.title}`
          : articleEvents[0].category.title;
      }
    }, [articleEvents]);

    const { pathname } = useLocation();
    const displayUnPublish = status !== 'Draft';
    const displayPushNotification = isOneWeb(pathname);

    const publicUrl = standardizeArticleUrl(contextualizedUrl) || '';
    const displaySeeArticle = !!publicUrl;
    const isPublished = status === ArticleStatus.Published;
    const isScheduled = status === ArticleStatus.Scheduled;

    const handleMobilePush = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      history.push(`/${brandKey}/mobilepush/add?id=${id}`);
    };

    const UnPublishItem = () => {
      const coreItem = (
        <>
          <ListItemIcon>
            <CloudOff fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Dépublier</Typography>
        </>
      );

      return isSmallDevice ? (
        <ListItem button data-testid="unPublish" onClick={handleMutation}>
          {coreItem}
        </ListItem>
      ) : (
        <MenuItem data-testid="unPublish" onClick={handleMutation}>
          {coreItem}
        </MenuItem>
      );
    };
    const SendPushItem = () => {
      const coreItem = (
        <>
          <ListItemIcon>
            <MobileScreenShare fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Envoyer un push</Typography>
        </>
      );
      return isSmallDevice ? (
        <ListItem button data-testid="pushButton" onClick={handleMobilePush}>
          {coreItem}
        </ListItem>
      ) : (
        <MenuItem data-testid="pushButton" onClick={handleMobilePush}>
          {coreItem}
        </MenuItem>
      );
    };
    const onClick = (event: MouseEvent<HTMLElement>) => {
      window.open(publicUrl);
      handleClose(event);
    };

    const DisplayItem = () => {
      const coreItem = (
        <>
          <ListItemIcon>
            <RemoveRedEye fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Voir en front</Typography>
        </>
      );
      return isSmallDevice ? (
        <ListItem button data-testid="seeArticle" onClick={onClick}>
          {coreItem}
        </ListItem>
      ) : (
        <MenuItem data-testid="seeArticle" onClick={onClick}>
          {coreItem}
        </MenuItem>
      );
    };

    const mediasIcons = useMediasIcons(medias, live?.postCount || 0, pushCount);
    const isPaidArticle = articleQualifiers?.some(
      ({ qualifier }) => qualifier.title === PAID_ARTICLE,
    );

    return (
      <RouterLink
        to={articleLink}
        className={classes.link}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CardItem
          titleOverflow={true}
          hasPush={Boolean(pushCount)}
          pushCount={pushCount}
          isPaidArticle={isPaidArticle}
          ActionsMenu={
            (isPublished || isScheduled) && (
              <ArticleMenu
                displayPushNotification={displayPushNotification}
                displaySeeArticle={displaySeeArticle}
                displayUnPublish={displayUnPublish}
                articleId={id}
                publicUrl={publicUrl}
                brandKey={brandKey}
              />
            )
          }
          Icons={<ListMediasIcons listMedias={mediasIcons} />}
          id={id}
          title={title}
          media={image!}
          format={format}
          topTitle={`${
            articleAuthors.length ? `${articleAuthors[0].author.name} -` : ''
          } ${statusToLabel(format)[status]} ${
            publishedAt
              ? `le ${formatDate(new Date(publishedAt), 'dd/MM/yyyy à HH:mm')}`
              : ''
          } ${
            editedAt
              ? `(modifié le ${formatDate(
                  new Date(editedAt),
                  'dd/MM/yyy à HH:mm',
                )} par ${
                  articleCreatorData ? articleCreatorData.name : 'inconnu'
                })`
              : ''
          }`}
          brandKey={brandTitleByKey[brandKey]}
          eventTitle={eventTitle}
          categoryTitle={categoryTitle}
          lockedBy={articleExtension?.lockedBy}
          isLocked={Boolean(isLocked)}
          updateLocker={updateLockerId}
        />
        {!loading && (displayUnPublish || displaySeeArticle) && isSmallDevice && (
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onOpen={() => {}}
            onClose={handleClose}
          >
            <List>
              {displayUnPublish && <UnPublishItem />}
              {displaySeeArticle && <DisplayItem />}
              {!!brandsOptions[brandKey].pushPlatforms?.length &&
                displayPushNotification && <SendPushItem />}
            </List>
          </SwipeableDrawer>
        )}
      </RouterLink>
    );
  },
);
