import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { TagType } from '../../../../__generated__/queries-web';
import { ImageLoader, ImageLoaderFallback } from '@prismamedia/one-components';
import React, { FC, Fragment, useEffect } from 'react';
import { useSearchCategoryMedias } from './getCategoryMedias.web.graphql';
import { TagIcons } from './constants';
import { useStyles } from './styles';

const IMAGE_REQUEST_TIMEOUT = 3000;
interface Thumbnails {
  src: string | null;
  srcList: ImageLoaderFallback[];
}

const defaultThumbnails = {
  src: null,
  srcList: [],
};
interface ThumbnailProps {
  id: string | null;
  type: TagType | 'Event' | 'Guide';
}
const Thumbnail: FC<ThumbnailProps> = ({ id, type }) => {
  const { loading, data } = useSearchCategoryMedias(id || '');

  const [thumbnails, setThumbnails] = React.useState<Thumbnails>(
    defaultThumbnails,
  );
  const classes = useStyles();
  const theme = useTheme();
  const tagThumbnailStyle = {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    backgroundSize: 'cover',
    display: 'flex',
    flex: '0 0 163px',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '123px',
  };

  const Icon = TagIcons[type] || Fragment;

  useEffect((): void => {
    if (data?.category?.medias) {
      let medias: Record<string, any>;

      try {
        medias = JSON.parse(data?.category?.medias);
      } catch (e) {
        return;
      }

      const srcList = medias.reduce((thumbnailList: string[], media: any) => {
        const mediasThumbnails = media.iframely.links.thumbnail || [];
        const isMediasHasThumbnail = Boolean(mediasThumbnails.length);

        if (isMediasHasThumbnail) {
          const thumbnailsHrefs = mediasThumbnails.map(
            (thumbnail: Record<'href', string>) => ({
              src: thumbnail.href,
              timeout: IMAGE_REQUEST_TIMEOUT,
            }),
          );

          thumbnailList.push(...thumbnailsHrefs);
        }

        return thumbnailList;
      }, []) as ImageLoaderFallback[];

      if (srcList.length) {
        setThumbnails({
          src: srcList[0].src || null,
          srcList: srcList.slice(1),
        });
      }
    }
  }, [data]);

  return loading ? (
    <div style={tagThumbnailStyle}>
      <CircularProgress />
    </div>
  ) : (
    <ImageLoader
      fallbacks={thumbnails.srcList}
      fitting="cover"
      image={<Icon className={classes.icon} />}
      loaderSize={40}
      src={thumbnails.src}
      style={tagThumbnailStyle}
      timeout={IMAGE_REQUEST_TIMEOUT}
    />
  );
};

export { Thumbnail };
