import React, { forwardRef, FC } from 'react';
import { Delete, DragIndicator } from '@mui/icons-material';
import {
  Box,
  capitalize,
  Fab,
  Grid,
  IconButton,
  Switch,
  Typography,
  Tooltip,
} from '@mui/material';
import { ArticleState } from '../../../../../pages/ArticleEdit/reducer';
import { TAG_ITEM_DATA_TESTID } from '../../../TagItem/constants';
import { DragAndDropItem } from '../../../../DragAndDrop';
import { AdditionalSortableItemProps } from '../../../../DragAndDrop/SortableItem';
import type { DefaultProps } from '../';
import { TagThumbnail } from './TagThumbnail';
import { useStyles } from './styles';

interface TagProps
  extends DragAndDropItem,
    Pick<ArticleState, 'brandKey'>,
    Partial<AdditionalSortableItemProps>,
    Pick<DefaultProps, 'controls'> {
  onChange?: { (item: DragAndDropItem): void };
  onDelete?: { (item: Record<'id' | 'title', string>): void };
}
const Tag: FC<TagProps> = forwardRef<HTMLButtonElement, TagProps>(
  (
    {
      attributes,
      brandKey,
      controls,
      children,
      isDragging,
      isSortable,
      listeners,
      onChange,
      onDelete,
      ...tag
    },
    ref,
  ) => {
    const sortableProps = { attributes, isSortable, listeners };
    const isActivationControlAllowed = controls?.activation;
    const classes = useStyles({
      isDragging,
      withStatus: isActivationControlAllowed,
    });

    return (
      <Grid className={classes.wrapper} data-testid={TAG_ITEM_DATA_TESTID} item>
        <Box className={classes.innerWrapper} data-testid={tag.id} ref={ref}>
          {isActivationControlAllowed && (
            <Box component="span" className={classes.statusWrapper}>
              <Switch
                checked={tag.isActive}
                color="primary"
                classes={{ thumb: classes.statusSwitchDumb }}
                onChange={(e) => {
                  onChange?.({ ...tag, isActive: e.target.checked });
                }}
                size="small"
              />
            </Box>
          )}

          <Box>
            <Tooltip arrow title={tag.type} placement="top">
              <span>
                <TagThumbnail
                  brandKey={brandKey}
                  isDragging={isDragging}
                  isOverlay={!sortableProps.attributes?.role}
                  relationId={tag.relationId}
                  type={tag.type}
                />
              </span>
            </Tooltip>

            <Typography className={classes.title} component="h6">
              {capitalize(tag.title)}
            </Typography>

            {/* Drag button */}
            <IconButton
              className={classes.dragIconButton}
              disableRipple
              size="small"
              {...sortableProps.attributes}
              {...sortableProps.listeners}
            >
              <DragIndicator fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        {onDelete && (
          <Fab
            className={classes.deleteIcon}
            color="primary"
            onClick={() => {
              !isDragging && onDelete({ id: tag.id, title: tag.title });
            }}
            size="small"
          >
            <Delete />
          </Fab>
        )}
      </Grid>
    );
  },
);

Tag.displayName = 'Tag';

export { Tag };
