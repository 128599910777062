import { CircularProgress } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { OrphanEdit } from '.';
import { BrandKey } from '../../../__generated__/queries-web';
import { useGetOrphanById } from '../graphql/getOrphanPage.web.graphql';
interface editPageParams {
  brandKey: BrandKey;
  id: string;
}

export const EditOrphanPage = () => {
  const { brandKey, id } = useParams<editPageParams>();
  const { data, loading } = useGetOrphanById(id);
  if (loading) return <CircularProgress />;
  const {
    status,
    title,
    uri,
    metas,
    content,
    isCMPEnabled,
    isAdsEnabled,
    isFullScreen,
    publishedAt,
  } = data!.orphanPage!;

  return (
    <OrphanEdit
      defaultState={{
        brandKey,
        id,
        status,
        title,
        publishedAt,
        content: content || '',
        url: uri || '',
        metas: JSON.parse(metas || '[]'),
        isCMPEnabled: isCMPEnabled || false,
        isAdsEnabled: isAdsEnabled || false,
        isFullScreen: isFullScreen || false,
      }}
    />
  );
};
