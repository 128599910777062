import { Button, TextField, Typography } from '@mui/material';
import {
  ImagePlugin,
  MediaPlugin,
  parseDraftContent,
} from '@prismamedia/one-components';
import clsx from 'clsx';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { searchImageSourceCategories } from '../../../apollo/sharedQueries/getSourcesCategories.web.graphql';
import { Draft } from '../../../components/Draft';
import { CharCounter } from '../../../components/Draft/CharCounter';
import { actions as draftActions } from '../../../components/Draft/DraftActions';
import { ArticleVideoPlugin } from '../../../components/Draft/plugins/articleVideo';
import { embedImage, uploadAndEmbedImage } from '../../../utils/upload';
import { actions, ActionType } from '../reducer';
import { useStyles } from './styles';

interface EditorContentProps {
  id?: string | null;
  dispatch: Dispatch<ActionType>;
  title: string | null;
  body: string | null;
  displaySubmitButton: boolean;
  isWritable: boolean;
  onFormSubmit: any;
  resetDraft?: any;
  isResetButtonEnabled?: boolean;
}

export const EditorContent: FC<EditorContentProps> = ({
  id,
  dispatch,
  body,
  title,
  onFormSubmit,
  isWritable,
  displaySubmitButton,
  resetDraft,
  isResetButtonEnabled = false,
}) => {
  const [isValid, setIsValid] = useState(false);

  const mediaPlugin = MediaPlugin({ handleEmbed: embedImage });
  const imagePlugin = (brandKey: string) =>
    ImagePlugin({
      handleEmbed: (file: File) => uploadAndEmbedImage(file, brandKey),
      maxUploadSize: config.IMAGE_MAX_SIZE,
      fetchSources: searchImageSourceCategories,
    });
  const classes = useStyles();

  const handleOnTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const values = {
        title: event.target.value,
      };
      const action = id
        ? actions.updateLivePostById(values, id)
        : actions.updateLivePost(values);
      dispatch(action);
    },
    [dispatch, id],
  );

  const onBodyChange = useCallback(
    (contentState: any) => {
      const isSaveEnabled = contentState
        ? parseDraftContent(JSON.stringify(contentState))?.trim() !== ''
        : false;

      const values = {
        body: JSON.stringify(contentState),
      };

      setIsValid(isSaveEnabled);

      const action = id
        ? actions.updateLivePostById(values, id)
        : actions.updateLivePost(values, isSaveEnabled);

      dispatch(action);
    },
    [dispatch, id],
  );

  const rawDraftBody = body && JSON.parse(body);
  return useMemo(
    () => (
      <div className={clsx(classes.editItem, { readOnly: !isWritable })}>
        <div className={classes.inputs}>
          {isWritable ? (
            <>
              <TextField
                data-testid="LiveTitleMainText"
                fullWidth
                className={classes.textField}
                label={'Titre'}
                InputProps={{
                  classes: { input: classes.fontTitle },
                }}
                onChange={handleOnTitleChange}
                value={title ? title : ''}
                variant="standard"
              />
              <CharCounter content={title} />
            </>
          ) : (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.disabledText}
            >
              {title ? title : ''}
            </Typography>
          )}
        </div>
        <div className={clsx(classes.inputs)}>
          <Draft
            readOnly={!isWritable}
            placeholder="Contenu"
            rawDraft={rawDraftBody}
            onChange={onBodyChange}
            actions={[
              draftActions.BOLD,
              draftActions.ITALIC,
              draftActions.LINK,
              draftActions.INTERNAL_LINK,
            ]}
            plugins={[mediaPlugin, ArticleVideoPlugin, imagePlugin('BONE')]}
          />
        </div>

        {displaySubmitButton && (
          <div className={classes.toolsContainer}>
            {resetDraft && (
              <Button
                data-testid="LiveMainResetBtn"
                className={classes.button}
                onClick={resetDraft}
                variant="outlined"
                size="medium"
                color="secondary"
                disabled={!isResetButtonEnabled}
              >
                Reset
              </Button>
            )}
            <Button
              data-testid="LiveMainValidationBtn"
              className={classes.button}
              onClick={onFormSubmit}
              variant="outlined"
              size="medium"
              color="primary"
              disabled={!isWritable || !isValid}
            >
              Poster
            </Button>
          </div>
        )}
      </div>
    ),
    [
      classes,
      isValid,
      displaySubmitButton,
      handleOnTitleChange,
      isResetButtonEnabled,
      mediaPlugin,
      onBodyChange,
      onFormSubmit,
      rawDraftBody,
      resetDraft,
      title,
      isWritable,
    ],
  );
};
