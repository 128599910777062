import { BurstMode, Headset, PhotoCamera, Videocam } from '@mui/icons-material';
import {
  MEDIA_TYPES_LABEL,
  MediaTypesEnum,
  MediasWrapper,
  NotificationTypeEnum,
  PreviewSize,
  PushNotificationFn,
  UploadProgress,
  UploadStatesEnum,
} from '@prismamedia/one-components';
import Axios from 'axios';
import { startsWith as _startsWith, when as _when } from 'ramda';
import React, { useMemo } from 'react';
import ReactGA from 'react-ga4';
import { SetterOrUpdater } from 'recoil';
import { MetadataNameEnumType } from '../../__generated__/queries-photo';
import { RecipeStatus } from '../../__generated__/queries-recipe';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  SearchArticles_searchArticles,
} from '../../__generated__/queries-web';
import { getMetadata } from '../../apollo/utils/getMetadata';
import { history } from '../../history';
import { assert } from '../../utils/assert';
import { brandsOptions } from '../../utils/brands';
import { getImageFromArticleBody } from '../../utils/getImageFromArticleBody';
import {
  MediaFormat,
  getDefaultDailymotionMedia,
  getFirstMedia,
  getImageFromMedias,
  getMediasSite,
  getThumbnailUrlFromPhoto,
  getVideoThumbnail,
  parseMedias,
} from '../../utils/media';
import { sleep } from '../../utils/sleep';
import { statusToLabel } from '../../utils/statuses';
import {
  embedImage,
  uploadAndEmbedImage,
  uploadImage,
} from '../../utils/upload';
import { isPage, standardizeArticleUrl } from '../../utils/url';
import { baseRecipesURLs } from '../InternalLinkModal/useInternalLinksGetter';
import { FotoSearch } from './FotoSearch';
import { ProviderIcon } from './FotoSearch/ProvidersPhoto/Icons';
import { PhotoCollapsesTypes } from './FotoSearch/types';
import { PodcastSearch } from './PodcastSearch';
import {
  ARTICLE_FORMAT,
  BRAND_FORMAT,
  DEFAULT_MAX_IMAGE_SIZE,
  EMPTY_FORMAT,
  FB_FORMAT,
  FLAG_FORMAT,
  ILLUSTRATION_FORMAT,
  LOGO_FORMAT,
  MAX_HEIGHT,
  MAX_WIDTH,
  MEDIA_TYPES,
  MIN_HEIGHT,
  MIN_WIDTH,
  PINTEREST_FORMAT,
  THUMBNAIL_ERROR,
  THUMBNAIL_MAX_HEIGHT,
  THUMBNAIL_MAX_SIZE,
  THUMBNAIL_MAX_WIDTH,
  THUMBNAIL_MIN_HEIGHT,
  THUMBNAIL_MIN_WIDTH,
  THUMBNAIL_UPLOADED,
} from './constants';

enum FormatType {
  Cerise = 'Cerise',
  Page = 'Page',
  Default = 'Default',
}

export const postImages = (brandKey: string) => async (files: File[]) => {
  const iframelys = [];
  for (let i = 0; i < files.length; i++) {
    const iframely = await uploadAndEmbedImage(files[i], brandKey, {
      withFileName: true,
    });
    iframely && iframelys.push(iframely);
  }
  return iframelys;
};

const isImageMinPixels = (imageUrl: string, type: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const width = type === 'width' && img.width;
      const height = type === 'height' && img.height;
      resolve((width && width < MIN_WIDTH) || (height && height < MIN_HEIGHT));
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

const isImageMaxPixels = (imageUrl: string, type: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const width = type === 'width' && img.width;
      const height = type === 'height' && img.height;
      resolve((width && width > MAX_WIDTH) || (height && height > MAX_HEIGHT));
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

const handleThumbnail = async (
  file: File,
  imageUrl: string,
  pushNotification: PushNotificationFn,
) => {
  if (file.size > DEFAULT_MAX_IMAGE_SIZE) {
    pushNotification({
      message: THUMBNAIL_MAX_SIZE(file.name),
      type: NotificationTypeEnum.error,
    });
    return NotificationTypeEnum.error;
  } else if (await isImageMinPixels(imageUrl, 'width')) {
    pushNotification({
      message: THUMBNAIL_MIN_WIDTH(file.name),
      type: NotificationTypeEnum.error,
    });
    return NotificationTypeEnum.error;
  } else if (await isImageMinPixels(imageUrl, 'height')) {
    pushNotification({
      message: THUMBNAIL_MIN_HEIGHT(file.name),
      type: NotificationTypeEnum.error,
    });
    return NotificationTypeEnum.error;
  } else if (await isImageMaxPixels(imageUrl, 'width')) {
    pushNotification({
      message: THUMBNAIL_MAX_WIDTH(file.name),
      type: NotificationTypeEnum.error,
    });
    return NotificationTypeEnum.error;
  } else if (await isImageMaxPixels(imageUrl, 'height')) {
    pushNotification({
      message: THUMBNAIL_MAX_HEIGHT(file.name),
      type: NotificationTypeEnum.error,
    });
    return NotificationTypeEnum.error;
  }
};

export const postThumbnail = (
  brandKey: string,
  updateThumbnail: any,
  pushNotification: PushNotificationFn,
  videoId?: string,
  setThumbnail?: SetterOrUpdater<string | undefined>,
) => async (files: File[]) => {
  const imageUrl = await uploadImage(files[0], brandKey);
  const iframely = await uploadAndEmbedImage(files[0], brandKey, {
    withFileName: true,
  });
  const handler = await handleThumbnail(
    files[0],
    imageUrl.url,
    pushNotification,
  );

  if (handler === NotificationTypeEnum.error) {
    return [];
  }

  const result = await updateThumbnail({
    variables: {
      brandKey: brandKey as BrandKey,
      thumbnailUrl: imageUrl.url,
      videoId: videoId || '',
    },
  }).catch(() => {
    pushNotification({
      message: THUMBNAIL_ERROR,
      type: NotificationTypeEnum.error,
    });
  });
  if (result?.data?.dailymotionUpdate) {
    setThumbnail?.(imageUrl.url);
    pushNotification({
      message: THUMBNAIL_UPLOADED,
      type: NotificationTypeEnum.success,
    });
  }

  return [iframely];
};
const getUrlForDiapo = (domain: string, path: string) => {
  const removeSlash = _when(_startsWith('/'), (data) => data.slice(1));
  path = removeSlash(path);
  domain = removeSlash(domain);
  return `https://${domain}/${path}`;
};

const embedMediaFromArticle = async (
  article: {
    isSuggested: boolean;
  } & Pick<SearchArticles_searchArticles, 'articleUrls' | 'format' | 'medias'>,
  articleFormat: ArticleFormat,
) => {
  let mediaUrl = '';

  if (articleFormat === ArticleFormat.Slideshow) {
    const { domain, path } = article.articleUrls[0].url;
    mediaUrl = getUrlForDiapo(domain, path);
  } else if (
    [ArticleFormat.Video, ArticleFormat.Rich].includes(articleFormat)
  ) {
    const media = getFirstMedia(parseMedias(article.medias), MediaFormat.Video);
    if (media) {
      mediaUrl = media.iframely.meta.canonical;
    }
  }

  if (mediaUrl) {
    if (article.isSuggested) {
      ReactGA.event({
        category: 'Article Edit',
        action: `Suggestion ${article.format}`,
        label: window.location.href,
      });
    }

    return embedImage(mediaUrl);
  }
};

export const postVideo = async (
  localFile: File,
  brandKey: BrandKey,
  getDailymotionUploadURL: any,
  setProgress?: React.Dispatch<React.SetStateAction<UploadProgress[]>>,
) => {
  const cancelTokenSource = Axios.CancelToken.source();
  const uploadURLs = await getDailymotionUploadURL(
    { brandKey, count: 1 },
    { fetchPolicy: 'network-only' },
  );

  const handleProgess = async (
    name: string,
    size: number,
    state: UploadStatesEnum,
    url: string,
    hostName: string,
  ) => {
    await sleep(1000);
    const response = await Axios.get(`${url}`);

    setProgress?.((previousState) => {
      const result = [...previousState];
      const target = result.find((item) => item.name === name);
      if (target) {
        if (hostName === MediaTypesEnum.DAILYMOTION_TYPE) {
          switch (response.data.state) {
            case 'uploading':
              target.state = UploadStatesEnum.UPLOADING;
              target.received = response.data.received;
              break;
            case 'done':
              target.state = UploadStatesEnum.DONE;
              target.received = response.data.received;
              break;
            default:
              target.state = UploadStatesEnum.INTERRUPTED;
              break;
          }
        }
        if (target.received < size && state === UploadStatesEnum.UPLOADING) {
          handleProgess(
            target.name,
            target.size,
            target.state,
            url,
            target.host,
          );
        }
      } else {
        cancelTokenSource.cancel();
      }
      return result;
    });
  };

  const handleUpload = async (
    file: File,
    uploadURL: string,
    progressURL: string,
  ) => {
    const process = {
      name: file.name,
      size: file.size,
      received: 0,
      url: progressURL,
      host: MediaTypesEnum.DAILYMOTION_TYPE,
      state: UploadStatesEnum.UPLOADING,
    };

    setProgress?.((previous) => [...previous, process]);
    handleProgess(
      process.name,
      process.size,
      process.state,
      process.url,
      process.host,
    );

    const data = new FormData();
    data.append('file', file);
    try {
      const response = await Axios.post(`${uploadURL}`, data, {
        cancelToken: cancelTokenSource.token,
      });
      return response;
    } catch (error) {
      setProgress?.((previousState) => {
        const result = [...previousState];
        const target = result.find((item) => item.name === file.name);
        if (target) {
          target.state = UploadStatesEnum.INTERRUPTED;
        }
        return result;
      });
      throw { message: 'Le chargement a été interrompu' };
    }
  };

  const response = await handleUpload(
    localFile,
    uploadURLs.dailymotionUploadURL[0].uploadURL,
    uploadURLs.dailymotionUploadURL[0].progressURL,
  );

  return getDefaultDailymotionMedia(response?.data.name, response?.data.url);
};

export const useFormats = (brandKey: BrandKey) => {
  const formatType = brandsOptions[brandKey].isCerise
    ? FormatType.Cerise
    : isPage(history.location.pathname)
    ? FormatType.Page
    : FormatType.Default;

  return useMemo(() => {
    return {
      Cerise: [
        {
          label: ARTICLE_FORMAT,
          value: ARTICLE_FORMAT.toLowerCase(),
        },
        {
          label: FB_FORMAT,
          value: FB_FORMAT.toLowerCase(),
        },
        {
          label: PINTEREST_FORMAT,
          value: PINTEREST_FORMAT.toLowerCase(),
        },
      ],
      Page: [
        {
          label: EMPTY_FORMAT,
          value: ' ',
        },
        {
          label: ILLUSTRATION_FORMAT,
          value: 'illustration',
        },
        {
          label: LOGO_FORMAT,
          value: 'logo',
        },
        {
          label: FLAG_FORMAT,
          value: 'banner',
        },
        {
          label: BRAND_FORMAT,
          value: 'brand',
        },
      ],
      Default: undefined,
    }[formatType];
  }, [formatType]);
};

export const getAllowedMedias = (
  allowedMedia: MEDIA_TYPES[],
  { subjectId }: { subjectId?: string },
) => {
  const allowedMedias: MediasWrapper[] = [];

  allowedMedia.forEach((mediaType) => {
    if (mediaType === MEDIA_TYPES.ARTICLE_VIDEO) {
      allowedMedias.push({
        Icon: Videocam,
        label: 'Video',
        type: MEDIA_TYPES_LABEL.VIDEOS,
      });
    } else if (mediaType === MEDIA_TYPES.SLIDESHOW) {
      allowedMedias.push({
        Icon: BurstMode,
        label: 'Diapo',
        type: MEDIA_TYPES_LABEL.SLIDESHOWS,
      });
    } else if (mediaType === MEDIA_TYPES.PODCAST) {
      allowedMedias.push({
        Component: PodcastSearch,
        Icon: Headset,
        label: 'Podcast',
        type: MEDIA_TYPES_LABEL.PODCASTS,
      });
    } else if (mediaType === MEDIA_TYPES.FOTOWEB) {
      allowedMedias.push({
        Component: FotoSearch,
        Icon: PhotoCamera,
        label: 'Photo',
        props: { topicId: subjectId },
        type: MEDIA_TYPES_LABEL.IMAGES,
      });
    }
  });

  return allowedMedias;
};

export const getItemToRender = (
  brandKey: BrandKey,
  item: any,
  pluginType?: string,
) => {
  switch (pluginType) {
    case MEDIA_TYPES_LABEL.ARTICLES:
      return {
        chip: item.brandKey,
        id: item.id,
        image:
          getImageFromMedias(item.medias)?.mediaForDisplay ||
          item?.imageSrc?.mediaForDisplay,
        publicUrl: standardizeArticleUrl(item?.contextualizedUrl) || item?.url,
        publishDate: item.publishedAt,
        text: item.articleCreatorData?.name,
        title: item.title,
        status: scheduledStatusLabel(item.status),
      };
    case MEDIA_TYPES_LABEL.RECIPES:
      return {
        chip: item.brandKey,
        id: item.id,
        image:
          getImageFromMedias(item.medias)?.mediaForDisplay ||
          item?.imageSrc?.mediaForDisplay,
        title: item.title,
        publishDate: item.publishedAt,
        publicUrl:
          item?.url ||
          baseRecipesURLs[brandKey] +
            assert(item, ({ recipeUrls }) => recipeUrls[0].url?.path),
        status: scheduledStatusLabel(item.status),
      };
    case MEDIA_TYPES_LABEL.VIDEOS:
      return {
        chip: item.brandKey,
        id: item.id,
        image:
          getVideoThumbnail(item.medias) || item?.imageSrc?.mediaForDisplay,
        publicUrl: standardizeArticleUrl(item?.contextualizedUrl) || item?.url,
        publishDate: item.publishedAt,
        site: getMediasSite(item.medias)?.[0],
        status: `${
          (statusToLabel(ArticleFormat.Video) as any)[item.status]
        } le`,
        text: item.articleCreatorData?.name,
        title: item.title,
      };
    case MEDIA_TYPES_LABEL.PODCASTS:
      return {
        feed: item.feed,
        id: item.id,
        image: item.image,
        isLoading: false,
        text: item.text,
        title: item.title,
      };
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return {
        chip: item.brandKey,
        id: item.id,
        image: getImageFromArticleBody(item.body, false),
        publicUrl: standardizeArticleUrl(item?.contextualizedUrl),
        publishDate: item.publishedAt,
        text: item.articleCreatorData?.name,
        title: item.title,
        status: scheduledStatusLabel(item.status),
        slideShowArticleCount: item.slideShowArticleCount,
      };
    case MEDIA_TYPES_LABEL.IMAGES:
      return {
        ...(item.from === PhotoCollapsesTypes.PROVIDERS && {
          chip: <ProviderIcon provider={item.provider} />,
        }),
        id: item.id || item.idSubject,
        image:
          item.from === PhotoCollapsesTypes.PROVIDERS
            ? item.medium || item.large || item.small
            : getThumbnailUrlFromPhoto(item, PreviewSize.xs),
        text:
          item.from === PhotoCollapsesTypes.ONE_PHOTOS
            ? getMetadata(MetadataNameEnumType.Source, item)
            : item.from === PhotoCollapsesTypes.ONE_STORY
            ? item.source
            : item.name,
        title:
          item.from === PhotoCollapsesTypes.ONE_PHOTOS
            ? getMetadata(MetadataNameEnumType.Credit, item) ||
              getMetadata(MetadataNameEnumType.title, item) ||
              getMetadata(MetadataNameEnumType.description, item)
            : item.credit || item.title || item.description,
      };
    default:
      return {
        id: 'no-plugin-type',
      };
  }
};

export const getItemToIframely = async (item: any, pluginType: string) => {
  switch (pluginType) {
    case MEDIA_TYPES_LABEL.ARTICLES:
      return await embedMediaFromArticle(item, ArticleFormat.Rich);
    case MEDIA_TYPES_LABEL.RECIPES:
      return await embedImage(getRecipeMediaUrl(item.medias));
    case MEDIA_TYPES_LABEL.VIDEOS:
      return await embedMediaFromArticle(item, ArticleFormat.Video);
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return await embedMediaFromArticle(item, ArticleFormat.Slideshow);
  }
};

const getRecipeMediaUrl = (medias: string | null) => {
  const imageSrc = getImageFromMedias(medias);

  if (imageSrc?.media) {
    return imageSrc.media.iframely.meta.canonical;
  }
};

const scheduledStatusLabel = (status: ArticleStatus | RecipeStatus) => {
  if (status === ArticleStatus.Scheduled || status === RecipeStatus.Scheduled)
    return 'Programmé le';
};
