import type { SelectProps } from '@mui/material';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControlLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  Zoom,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  CorrectionSession,
  DraftjsProlexis,
  ErrorStatusEnum,
  ProlexisClient,
  ProlexisError,
  getSelectionState,
} from '@prismamedia/draftjs-prolexis';
import { Unit } from '@prismamedia/one-brandkey';
import { ContentState, EditorState, RichUtils, SelectionState } from 'draft-js';
import React, { ChangeEvent } from 'react';
import { renderPluginsFromTemplate } from '../../pages/ArticleEdit/utils/renderFromTemplate';
import { DraftEditor } from '../Draft/DraftEditor';
import { styles } from './styles';
import { ProlexisDialogProps, ProlexisDialogState } from './types';

const getProlexisConfig = (unitTitle?: string) => {
  let apiKey;

  switch (unitTitle) {
    case Unit.NGE:
      apiKey = 'juT4aP84s9eouTfh4mqu47p8HFr4tf';
      break;
    case Unit.CAM:
      apiKey = 'odh45r8r36s9mkpHy4qDEMhD74Aspo';
      break;
    case Unit.VOICI:
      apiKey = 'ezlds7q8qljo6d8b9pkdJ4qF4XWpM4';
      break;
    case Unit.GALA:
      apiKey = 'bf476h2g3d68HgtFmp468Dse8kJHxp';
      break;
    case Unit.GEO:
      apiKey = '2qMPoigRdo439q7amp4JtFdCB486Iu';
      break;
    case Unit.PTVS:
      apiKey = 'nchsg78r9r8tpoHYTF4MPs4268gtDf';
      break;
    case Unit.PECO:
      apiKey = 'dqeatr478J7L2mspJHG6fgpwvc45JU';
      break;
    case Unit.NEON:
      apiKey = '362p98jutfDFtio48zpoH46Lk58HY7';
      break;
    default:
      apiKey = '92f56s89qpojrFTpq439dgTFD4psj'; // PFEM by default
  }

  return { url: 'https://prisma-media-ws.prolexis.com', apiKey };
};

let draftjsProlexis = new DraftjsProlexis(
  new ProlexisClient(getProlexisConfig().url),
);

class ProlexisDialogWithoutStyles extends React.Component<
  ProlexisDialogProps,
  ProlexisDialogState
> {
  state: ProlexisDialogState = {
    explanationLoading: false,
    currentCorrection: '',
    correctionError: false,
    multiple: false,
    explanation: '',
    currentText: this.props.texts[0].name,
    texts: this.props.texts.map(({ label, name, type, value }) => ({
      label,
      name,
      originalType: type,
      value:
        type === 'TEXT'
          ? EditorState.createWithContent(
              ContentState.createFromText(value as string),
            )
          : (value as EditorState),
    })),
    showNotextMessage: false,
    isCloseDialogOpen: false,
    loading: true,
  };

  editorChange = (editor: EditorState) => {
    const { texts } = this.state;
    const newTexts = [...texts];
    const index = newTexts.findIndex(
      (field) => field.name === this.state.currentText,
    );
    if (index !== -1) {
      newTexts[index].value = editor;
    }
    this.setState({
      texts: newTexts,
    });
  };

  /**
   * Switch between body/lead/title
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  changeCurrentText = (
    event: ChangeEvent<{ name?: string; value: string }>,
  ) => {
    const { currentError } = this.state;

    // remove CORRECTION style for current error
    if (currentError) {
      const editorState = this.getCurrentEditor();

      const unstyledEditorState = this.toggleCorrectionStyle(
        editorState,
        currentError.location,
      );
      this.editorChange(unstyledEditorState);
    }

    this.setState({ currentText: event.target.value }, async () => {
      const newEditor = this.getCurrentEditor();
      if (newEditor.getCurrentContent().getPlainText() === '') {
        this.setState({ showNotextMessage: true, errors: [] });
        return;
      }
      this.setState({ showNotextMessage: false });
      await this.startNewSession();
    });
  };

  getCurrentEditor = () => {
    return (
      this.state.texts.find((field) => field.name === this.state.currentText)
        ?.value || new EditorState()
    );
  };

  setMultiple = (multiple: boolean) => {
    this.setState({ multiple }, () => {
      this.highlightMulti();
    });
  };

  setExplanation = async () => {
    const { session, currentErrorIndex } = this.state;
    if (!session || currentErrorIndex === undefined) {
      this.setState({ explanationLoading: false });
      return;
    }
    this.setState(
      () => ({
        explanationLoading: true,
      }),
      async () => {
        try {
          const response = await session.getExplanation(currentErrorIndex);
          this.setState({ explanation: response, explanationLoading: false });
        } catch (e) {
          this.setState({ explanationLoading: false });
        }
      },
    );
  };

  toggleCorrectionStyle = (
    editorState: EditorState,
    location: { offset: number; length: number },
  ): EditorState => {
    const selectionState = getSelectionState(
      editorState.getCurrentContent().getBlocksAsArray(),
      location,
    );
    if (selectionState === false) {
      return editorState;
    }

    // we force it to be able to scrollIntoView later
    const newEditorState = EditorState.forceSelection(
      editorState,
      selectionState,
    );

    const styledEditorState = RichUtils.toggleInlineStyle(
      newEditorState,
      'CORRECTION',
    );

    return styledEditorState;
  };

  updateEditor = (editorState: EditorState, session: CorrectionSession) => {
    if (
      !session.getCurrentError() ||
      [ErrorStatusEnum.ignored, ErrorStatusEnum.corrected].includes(
        session.getCurrentError().status,
      )
    ) {
      this.setState(
        {
          errors: session.getErrors(),
          currentError: undefined,
          closeDialogMessage: 'Toutes les erreurs ont été traitées',
        },
        () => {
          this.editorChange(editorState);
          this.openCloseDialog();
        },
      );
      return;
    }
    const newCurrentErrorIndex = session
      .getErrors()
      .findIndex((error) => error === session.getCurrentError());

    const styleEditorState = this.toggleCorrectionStyle(
      editorState,
      session.getCurrentError().location,
    );

    this.setState(
      {
        errors: session.getErrors(),
        currentErrorIndex: newCurrentErrorIndex,
        currentError: session.getCurrentError(),
        currentCorrection:
          session.getCurrentError() &&
          session.getCurrentError().corrections.length > 0
            ? session.getCurrentError().corrections[0]
            : '',
        correctionError: false,
        multiple: false,
      },
      () => {
        this.editorChange(styleEditorState);
        this.setExplanation();
        this.scrollToError();
      },
    );
  };
  applyCorrection = async () => {
    const {
      session,
      currentCorrection,
      currentErrorIndex,
      multiple,
    } = this.state;
    if (!session || currentErrorIndex === undefined) {
      return;
    }

    let newEditorState;
    try {
      if (multiple) {
        newEditorState = await session.correctMultiple(
          currentCorrection,
          currentErrorIndex,
        );
      } else {
        newEditorState = await session.correct(
          currentCorrection,
          currentErrorIndex,
        );
      }
      this.updateEditor(newEditorState, session);
    } catch (e) {
      console.error(e);
      this.setState(
        {
          closeDialogMessage:
            'Une désynchronisation avec le serveur Prolexis est survenue.',
        },
        () => {
          this.openCloseDialog();
        },
      );
    }
  };

  ignoreCorrection = async () => {
    const { session, currentErrorIndex, multiple } = this.state;
    if (!session || currentErrorIndex === undefined) {
      return;
    }

    let newEditorState;
    if (multiple) {
      newEditorState = await session.ignore(currentErrorIndex, true);
    } else {
      newEditorState = await session.ignore(currentErrorIndex);
    }

    this.updateEditor(newEditorState, session);
  };

  highlightMulti = () => {
    const { session, currentErrorIndex, errors } = this.state;
    if (!session || currentErrorIndex === undefined || !errors) {
      return;
    }
    const editorState = this.getCurrentEditor();
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const currentError = errors[currentErrorIndex];
    let newEditorState = editorState;
    let processingError = errors[currentError.duplicateNextErrorId];
    let loop = 0;
    do {
      const selectionState = getSelectionState(
        blocks,
        processingError.location,
      );
      if (!selectionState) {
        continue;
      }
      newEditorState = EditorState.acceptSelection(
        newEditorState,
        selectionState,
      );
      newEditorState = RichUtils.toggleInlineStyle(
        newEditorState,
        'CORRECTION',
      );
      processingError = errors[processingError.duplicateNextErrorId];
      loop++;
    } while (processingError !== currentError && loop < 1000);

    this.editorChange(newEditorState);
  };

  setCurrentError = (error: ProlexisError, errorIndex: number) => {
    const { currentError } = this.state;

    const editorState = this.getCurrentEditor();

    let unstyledEditorState = editorState;

    // remove CORRECTION style for current error
    if (currentError) {
      unstyledEditorState = this.toggleCorrectionStyle(
        editorState,
        currentError.location,
      );
    }

    // apply CORRECTION style for new error
    const newEditorState = this.toggleCorrectionStyle(
      unstyledEditorState,
      error.location,
    );

    this.setState(
      {
        currentError: error,
        currentErrorIndex: errorIndex,
        currentCorrection:
          error.corrections.length > 0 ? error.corrections[0] : '',
      },
      () => {
        this.editorChange(newEditorState);
        this.setExplanation();
        this.scrollToError();
      },
    );
  };

  scrollToError = () => {
    setTimeout(() => {
      const sel = window.getSelection();
      if (sel && sel.focusNode && sel.focusNode.parentElement) {
        sel.focusNode.parentElement.scrollIntoView();

        // remove current selection otherwise we have correction style + selection not pretty
        const editorState = this.getCurrentEditor();
        const firstKey = editorState
          .getCurrentContent()
          .getFirstBlock()
          .getKey();
        const emptySelection = SelectionState.createEmpty(firstKey);
        const editorWithoutSelection = EditorState.forceSelection(
          editorState,
          emptySelection,
        );
        this.editorChange(editorWithoutSelection);
      }
    }, 0);
  };

  setCorrection = (correction: string) => {
    this.setState({ currentCorrection: correction });
  };

  clearSelection = (editorState: EditorState) => {
    return EditorState.acceptSelection(
      editorState,
      SelectionState.createEmpty(
        editorState.getCurrentContent().getBlocksAsArray()[0].getKey(),
      ),
    );
  };
  openCloseDialog = () => {
    this.setState({
      isCloseDialogOpen: true,
    });
  };

  close = (apply = true) => {
    const { handleClose } = this.props;
    const { currentError, texts } = this.state;

    const editorState = this.getCurrentEditor();

    let newEditorState: EditorState = editorState;
    // remove CORRECTION style for current error
    if (currentError) {
      newEditorState = this.toggleCorrectionStyle(
        editorState,
        currentError.location,
      );
    }
    newEditorState = this.clearSelection(newEditorState);
    if (apply && texts) {
      const newTexts = [...texts];
      const index = newTexts.findIndex(
        (field) => field.name === this.state.currentText,
      );
      if (index !== -1) {
        newTexts[index].value = newEditorState;
      }
      handleClose(
        newTexts.map(({ label, name, originalType, value }) => ({
          label,
          name,
          type: originalType,
          value:
            originalType === 'TEXT'
              ? value.getCurrentContent().getPlainText()
              : value,
        })),
      );
    } else {
      handleClose(false);
    }
  };

  startNewSession = async () => {
    this.setState(
      () => ({
        loading: true,
      }),
      async () => {
        const editorState = this.getCurrentEditor();
        if (!editorState.getCurrentContent().hasText()) {
          this.setState({
            showNotextMessage: true,
            errors: [],
            loading: false,
          });
          return;
        }
        const cleanEditorState = this.clearSelection(editorState);
        const autocorrectEditorState = await draftjsProlexis.autoCorrect(
          cleanEditorState,
        );
        this.editorChange(autocorrectEditorState);
        const session = await draftjsProlexis.startCorrectionSession(
          autocorrectEditorState,
        );
        this.setState(
          {
            session,
            errors: session.getErrors(),
            currentError: undefined,
            loading: false,
          },
          () => {
            if (session.getErrors().length > 0) {
              this.setCurrentError(session.getCurrentError(), 0);
            }
          },
        );
      },
    );
  };

  async componentDidMount() {
    const { unitTitle } = this.props;
    const config = getProlexisConfig(unitTitle);
    draftjsProlexis = new DraftjsProlexis(
      new ProlexisClient(config.url, config.apiKey),
    );

    this.startNewSession();
  }

  getErrorIndex = (wantedError: ProlexisError): number => {
    const { session } = this.state;
    if (!session) {
      throw new Error('getErrorIndex on inactive session');
    }
    return session.getErrors().findIndex((error) => error === wantedError);
  };

  render() {
    const { classes } = this.props;
    const editorState = this.getCurrentEditor();
    const {
      errors,
      currentError,
      currentCorrection,
      correctionError,
      multiple,
      explanation,
      currentText,
      showNotextMessage,
      explanationLoading,
      isCloseDialogOpen,
      closeDialogMessage,
      loading,
      texts,
    } = this.state;
    const styleMap = {
      CORRECTION: {
        color: 'white',
        backgroundColor: 'red',
      },
    };

    return (
      <Dialog open={true} fullScreen TransitionComponent={Zoom}>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              Correction - Prolexis
            </Typography>
            <Button color="inherit" onClick={this.openCloseDialog}>
              X
            </Button>
          </Toolbar>
        </AppBar>
        <Dialog open={isCloseDialogOpen}>
          <DialogTitle id="alert-dialog-title">
            Fin de la session de correction.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {closeDialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.close(false)} color="primary">
              Annuler les modifications
            </Button>
            <Button onClick={() => this.close()} color="primary" autoFocus>
              Appliquer les modifications
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.wrapper}>
          {!loading ? (
            <>
              <Drawer
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <MenuList>
                  {errors &&
                    errors.map((error) => (
                      <MenuItem
                        disabled={error.status !== ErrorStatusEnum.pending}
                        key={`${error.location.offset}-${error.location.length}-${error.diagnosis}`}
                        selected={currentError === error}
                        onClick={() =>
                          this.setCurrentError(error, this.getErrorIndex(error))
                        }
                      >
                        <ListItemText
                          className={classes[error.type]}
                          primary={error.label}
                        />
                      </MenuItem>
                    ))}
                </MenuList>
              </Drawer>
              <div className={classes.main}>
                <Paper className={classes.correctionAside}>
                  {currentError ? (
                    <>
                      <div className={classes.caseWrapper}>
                        <Typography variant="h4">
                          {currentError.diagnosis}
                        </Typography>
                      </div>
                      <div className={classes.caseWrapper}>
                        <div className={classes.correctionList}>
                          <MenuList dense>
                            {currentError.corrections.map((correction) => (
                              <MenuItem
                                key={correction}
                                onClick={() => this.setCorrection(correction)}
                              >
                                {correction}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </div>
                      </div>
                      <div className={classes.caseWrapper}>
                        <div className={classes.correctionInputWrapper}>
                          <TextField
                            error={correctionError}
                            label={correctionError && 'Champ obligatoire'}
                            placeholder={'correction'}
                            value={currentCorrection}
                            onChange={(event) =>
                              this.setCorrection(event.target.value)
                            }
                            InputProps={{
                              classes: {
                                input: classes.inputProps,
                              },
                            }}
                            variant="standard"
                          />
                        </div>
                        {currentError.duplicateErrorCount > 0 && (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={multiple}
                                onChange={(event) =>
                                  this.setMultiple(event.target.checked)
                                }
                                value="multiple"
                              />
                            }
                            label="Sélectionner tous"
                          />
                        )}
                        <div className={classes.actionsWrapper}>
                          <Button onClick={this.applyCorrection}>
                            Corriger
                          </Button>
                          <Button onClick={this.ignoreCorrection}>
                            Ignorer
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Typography variant="h6">
                      Aucune erreur détectée.
                    </Typography>
                  )}
                </Paper>
                <Paper className={classes.draftWrapper}>
                  <Select
                    value={currentText}
                    onChange={this.changeCurrentText as SelectProps['onChange']}
                    className={classes.articlePartSelector}
                    variant="standard"
                  >
                    {texts.map(({ label, name }, index) => (
                      <MenuItem key={index} value={name}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  {showNotextMessage ? (
                    <Typography>
                      Cette partie de l'article n'a pas encore été écrite
                    </Typography>
                  ) : (
                    <DraftEditor
                      readOnly
                      editorState={editorState}
                      onChange={this.editorChange}
                      plugins={renderPluginsFromTemplate(this.props.brandKey)}
                      customStyleMap={styleMap}
                    />
                  )}
                </Paper>
              </div>
              <Drawer
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
                anchor="right"
              >
                {explanationLoading && <CircularProgress />}
                {!explanationLoading && explanation.length > 0 && (
                  // eslint-disable-next-line react/no-danger
                  <div dangerouslySetInnerHTML={{ __html: explanation }} />
                )}
              </Drawer>
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Dialog>
    );
  }
}

export const ProlexisDialog = withStyles(styles)(ProlexisDialogWithoutStyles);
