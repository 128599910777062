import { AddAPhoto, Code } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  EmbedDialog,
  IconLabelButton,
  ItemType,
  MediaType,
  MultimediaPlugin,
  NotificationTypeEnum,
  OneMedia,
  handleDrop,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { ChangeEvent, Dispatch, FC, SetStateAction, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import { BrandKey } from '../../__generated__/queries-web';
import { useDailymotionUpdate } from '../../apollo/mutations/dailymotion.web.graphql';
import { useGetArticle } from '../../apollo/queries/articles.web.graphql';
import { useSuggestArticleQuery } from '../../apollo/queries/suggestArticles.web.graphql';
import { history } from '../../history';
import { useGlobalThumbnail } from '../../utils/globalState';
import { embedImage } from '../../utils/upload';
import { isPage } from '../../utils/url';
import { AdvancedSearch } from '../AppBar/AdvancedSearch';
import { searchByPluginType } from '../Draft/plugins/shared/searchArticleForMedia';
import { MEDIA_TYPES } from './constants';
import {
  getAllowedMedias,
  getItemToIframely,
  getItemToRender,
  postThumbnail,
} from './utils';

export const MediaUploaders: FC<{
  medias: OneMedia[];
  setMedias: Dispatch<SetStateAction<OneMedia[]>>;
  brandKey: BrandKey;
  setSelectedMediaIndex: (index: number) => void;
  allowedMedias: MEDIA_TYPES[];
  subjectId?: string;
  videoId?: string;
  setLoading: (loading: boolean) => void;
}> = ({
  medias,
  setMedias,
  brandKey,
  setSelectedMediaIndex,
  allowedMedias,
  subjectId,
  videoId,
  setLoading,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const { pushNotification } = useNotification();
  const [updateThumbnail] = useDailymotionUpdate();
  const [, setThumbnail] = useGlobalThumbnail();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const multimediaPluginMediaTypes = getAllowedMedias(allowedMedias, {
    subjectId,
  });

  const { id } = useParams();
  const { data: dataArticles } = useGetArticle(id);
  const { suggestQuery } = useSuggestArticleQuery(dataArticles);

  const openEmbedDialog = () =>
    openDialog(
      <EmbedDialog
        fetcher={embedImage}
        onMediaLoaded={(media) => {
          const lastMediaIndex = medias.length - 1;
          setMedias((prev) => [...prev, media]);
          setSelectedMediaIndex(lastMediaIndex + 1);
          closeDialog();
        }}
      />,
      { fullWidth: true },
    );

  const onUploadImages = async (files: File[]) => {
    setLoading(true);
    try {
      const newImages = await handleDrop({
        files: files.filter((file) => !file.type.includes('video')),
        mediaType: MediaType.Image,
        disableImgWidth: true,
        handleFiles: postThumbnail(
          brandKey,
          updateThumbnail,
          pushNotification,
          videoId,
          setThumbnail,
        ),
        pushNotification,
      });

      const lastMediaIndex = medias.length - 1;
      setMedias((prev) => [...prev, ...newImages]);
      setSelectedMediaIndex(lastMediaIndex + 1);
    } catch (error) {
      pushNotification({
        message: (error as Error).message || 'Média non reconnu',
        type: NotificationTypeEnum.error,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {allowedMedias.includes(MEDIA_TYPES.EMBED) && (
        <IconLabelButton label="Lien" onClick={openEmbedDialog}>
          <Code />
        </IconLabelButton>
      )}

      {allowedMedias.includes(MEDIA_TYPES.THUMBNAIL) && (
        <>
          <IconLabelButton
            label="Thumbnail"
            onClick={() => inputRef.current?.click()}
          >
            <AddAPhoto />
          </IconLabelButton>

          <Box
            component="input"
            type="file"
            accept="image/*"
            ref={inputRef}
            multiple
            sx={{ display: 'none' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              e.target.files && onUploadImages(Array.from(e.target.files))
            }
          />
        </>
      )}

      {!!multimediaPluginMediaTypes.length && (
        <MultimediaPlugin
          addMedia={(mediaOrMedias) => {
            const newMedias = Array.isArray(mediaOrMedias)
              ? mediaOrMedias
              : [mediaOrMedias];
            const lastMediaIndex = medias.length - 1;
            setMedias((prev) => [...prev, ...newMedias]);
            setSelectedMediaIndex(lastMediaIndex + 1);
          }}
          suggestArticlesFn={suggestQuery}
          allowedMedias={multimediaPluginMediaTypes}
          searchFn={(searchParams, opts, pluginType) =>
            searchByPluginType({
              searchParams,
              pluginType,
              opts,
              brandKey,
              searchSchedule: isPage(history.location.pathname),
            })
          }
          advancedSearch={
            brandKey &&
            dataArticles?.article?.format && {
              component: AdvancedSearch,
              props: { brandKey, articleFormat: dataArticles.article.format },
            }
          }
          itemToIframely={async (item: ItemType<any>, pluginType: string) => {
            return (await getItemToIframely(item, pluginType))!;
          }}
          itemToRender={(item: ItemType<any>, pluginType?: string) =>
            getItemToRender(brandKey, item, pluginType)
          }
          itemClickCallback={(item) => {
            if (item.isSuggested) {
              ReactGA.event({
                category: 'Article Edit',
                action: `Suggestion ${item.format}`,
                label: window.location.href,
              });
            }
          }}
        />
      )}
    </>
  );
};
