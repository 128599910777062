import uuidv4 from 'uuid/v4';
import {
  ArticleCreateInput,
  ArticleUpdateInput,
  GetArticle_article,
  UpsertArticleVariables,
} from '../../__generated__/queries-web';
import { MediaBlock, RawDraft } from '../../types/draft';

export const computeUpsertArticleVariables = (
  serverVersionArticle: GetArticle_article,
  article: GetArticle_article,
  slideMap: Record<string, MediaBlock>,
  slidesIndex: string[],
): UpsertArticleVariables => {
  const newBody: RawDraft = {
    blocks: slidesIndex.map((id) => slideMap[id]),
    entityMap: [],
  };

  const createArticleData: ArticleCreateInput = {
    title: article.title,
    format: article.format,
    status: article.status,
    publishedAt:
      article.publishedAt && new Date(article.publishedAt).toISOString(),
    body: JSON.stringify(newBody),
    brandKey: article.brandKey,
    editedAt: new Date().toISOString(),
    articleTags: {
      create: article.articleTags.map((articleTag, idx) => ({
        order: idx + 1,
        tag: { connect: { id: articleTag.tag.id } },
      })),
    },
    articleQualifiers: {
      create: article.articleQualifiers.map((qualifier, i) => ({
        order: i + 1,
        qualifier: { connect: { id: qualifier.qualifier.id } },
      })),
    },
    source: article.source
      ? {
          connect: { id: article.source.id },
        }
      : null,
  };

  const updateArticleData: ArticleUpdateInput = {
    ...createArticleData,
    articleTags: {
      ...createArticleData.articleTags,
      delete: serverVersionArticle.articleTags.map((articleTag) => ({
        tag: { id: articleTag.tag.id },
        order: articleTag.order,
      })),
    },
    articleQualifiers: {
      delete: Array.from(Array(100)).map((_, i) => ({ order: i + 1 })),
      create: article.articleQualifiers.map((qualifier, i) => ({
        order: i + 1,
        qualifier: { connect: { id: qualifier.qualifier.id } },
      })),
    },
    source: article.source
      ? {
          connect: { id: article.source.id },
        }
      : { disconnect: true },
  };

  if (article.articleCategories.length) {
    createArticleData.articleCategories = {
      create: [
        {
          order: 1,
          category: {
            connect: {
              id: article.articleCategories[0].category.id,
            },
          },
        },
      ],
    };

    updateArticleData.articleCategories = {
      delete: [{ order: 1 }],
      create: [
        {
          order: 1,
          category: {
            connect: {
              id: article.articleCategories[0].category.id,
            },
          },
        },
      ],
    };
  }

  return {
    create: createArticleData,
    update: updateArticleData,
    where: { id: article.id || uuidv4() },
  };
};
