import { useMutation } from '@apollo/client';
import { IframelyMedia as Iframely } from '@prismamedia/one-components';
import { ChangeEvent } from 'react';
import {
  BrandKey,
  CreatePush,
  CreatePushVariables,
} from '../../../__generated__/queries-web';
import { CREATE_PUSH } from '../../../apollo/mutations/push.web.graphql';
import { uploadAndEmbedImage } from '../../../utils/upload';
import { handleImageWidth } from '../MobilePushForm/validator';
import { PUSH_HISTORY } from '../PushHistory/pushHistory.web.graphql';
import { DecoratedSegment } from '../Segment/segmentList';

export const UPLOAD_ERROR_MESSAGE =
  "L'image doit avoir une taille supérieur à 1280px et inférieur à 4096px";

export const segmentsForPush = (segments: DecoratedSegment[]) => {
  const checkedSegments = segments.filter(({ checked }) => Boolean(checked));
  let selectedSegments;
  if (checkedSegments.length === 0) {
    return null;
  }
  if (checkedSegments.length === 1) {
    selectedSegments = {
      ios: {
        query: checkedSegments?.[0]?.ios
          ? JSON.parse(checkedSegments[0].ios)
          : null,
      },
      android: {
        query: checkedSegments?.[0]?.android
          ? JSON.parse(checkedSegments[0].android)
          : null,
      },
      editorialAlert: {
        query: checkedSegments?.[0]?.editorialAlert
          ? JSON.parse(checkedSegments[0].editorialAlert)
          : null,
      },
    };
  } else {
    selectedSegments = checkedSegments.reduce(
      (acc, val) => {
        acc.android.query.$and = val.android
          ? acc.android.query.$and.concat(JSON.parse(val.android))
          : [];
        acc.ios.query.$and = val.ios
          ? acc.ios.query.$and.concat(JSON.parse(val.ios))
          : [];
        acc.editorialAlert.query.$and = val.editorialAlert
          ? acc.editorialAlert.query.$and.concat(JSON.parse(val.editorialAlert))
          : [];
        return acc;
      },
      {
        android: {
          query: {
            $and: [],
          },
        },
        ios: {
          query: {
            $and: [],
          },
        },
        editorialAlert: {
          query: {
            $and: [],
          },
        },
      },
    );
  }
  return selectedSegments;
};

export const connectPushToSegment = (segments: DecoratedSegment[]) =>
  segments
    .filter(({ checked }) => Boolean(checked))
    .map(({ id }) => ({
      segment: {
        connect: {
          id,
        },
      },
    }));

export const upload = async (
  event: ChangeEvent<HTMLInputElement>,
  brandKey: BrandKey,
) => {
  const { files } = event.target;
  let error = false;
  if (files) {
    const Iframelies = await Promise.all(
      Object.values(files).map(async (file) => {
        if (await handleImageWidth(file)) {
          error = true;
        }
        return uploadAndEmbedImage(file, brandKey || '');
      }),
    );

    if (error) {
      return [UPLOAD_ERROR_MESSAGE];
    }

    const uploads: string[] = Iframelies.filter(
      (iframely: Iframely | false) =>
        iframely && iframely.meta && iframely.meta.url,
    ).map((iframely: Iframely) => iframely.meta.url);

    return uploads;
  }
};

export const useCreatePushMutation = () => {
  const [createPushMutation] = useMutation<CreatePush, CreatePushVariables>(
    CREATE_PUSH,
    { refetchQueries: [PUSH_HISTORY] },
  );
  return createPushMutation;
};
