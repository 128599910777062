import React from 'react';
import { Add, Create } from '@mui/icons-material';

export enum BreadCrumbMenuItemTypes {
  CREATE_ELEMENT = 'CREATE_ELEMENT',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  EDIT_ROOT_TEMPLATE = 'EDIT_ROOT_TEMPLATE',
  SHOW_FAVORIS = 'SHOW_FAVORIS',
}

export const ROOT_CATEGORY_MENU_ITEMS = [
  {
    disabled: false,
    icon: <Add />,
    id: BreadCrumbMenuItemTypes.CREATE_ELEMENT,
    label: 'Ajouter un élément',
    widthDivider: false,
  },
];

export const CATEGORY_MENU_ITEMS = [
  {
    disabled: false,
    icon: <Create />,
    id: BreadCrumbMenuItemTypes.EDIT_TEMPLATE,
    label: 'Modifier le template',
    widthDivider: false,
  },
  {
    disabled: false,
    icon: <Add />,
    id: BreadCrumbMenuItemTypes.CREATE_ELEMENT,
    label: 'Ajouter un élément',
    widthDivider: false,
  },
];
