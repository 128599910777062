import { Paper, TablePagination } from '@mui/material';
import { InfiniteTable } from '@prismamedia/one-components';
import {
  ChangeEvent,
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { GetOrphans_orphanPages } from '../../../__generated__/queries-web';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { paths } from '../../../routing/Router/paths';
import { useStyles } from './styles';
interface OrphanListContentProps {
  page: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  data: GetOrphans_orphanPages[];
}
export const OrphanListContent: FC<OrphanListContentProps> = ({
  page,
  setPageNumber,
  data,
}) => {
  const [rowParPage, setRowsParPage] = useState(50);
  const history = useHistory();
  const onChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    pageNumber: number,
  ) => setPageNumber(pageNumber);
  const onChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsParPage(Number(event.target.value));
    setPageNumber(0);
  };

  const shouldDisplayOrphanPage = (
    _orphan: GetOrphans_orphanPages,
    index: number,
  ) => index >= page * rowParPage && index < (page + 1) * rowParPage;
  const classes = useStyles();

  const isSmallList = rowParPage <= 10;

  return (
    <Paper className={classes.orphanContent}>
      <div
        className={
          isSmallList ? classes.smallTableWrapper : classes.tableWrapper
        }
      >
        <InfiniteTable
          list={data?.filter(shouldDisplayOrphanPage)}
          columns={[
            {
              label: 'Titre de la page',
              key: 'title',
              width: 200,
              flexGrow: 1,
            },
            {
              label: 'url',
              key: 'uri',
              width: 140,
              flexGrow: 1,
            },
            {
              label: 'CMP',
              key: 'isCMPEnabled',
              width: 140,
              cellRenderer({ isCMPEnabled }: GetOrphans_orphanPages) {
                return (
                  <div className={classes.iconMargin}>
                    {isCMPEnabled ? <DoneIcon /> : <CloseIcon />}
                  </div>
                );
              },
              flexGrow: 1,
            },
            {
              label: 'Footer/Header',
              key: 'isFullScreen',
              width: 140,
              cellRenderer({ isFullScreen }: GetOrphans_orphanPages) {
                return (
                  <div className={classes.iconMargin}>
                    {isFullScreen ? <DoneIcon /> : <CloseIcon />}
                  </div>
                );
              },
              flexGrow: 1,
            },
          ]}
          onRowClick={(e, { id, brandKey: brand }: GetOrphans_orphanPages) => {
            e.preventDefault();
            const url = paths.ORPHAN_EDIT.replace(':brandKey', brand).replace(
              ':id',
              id!,
            );
            history.push(url);
          }}
        />
      </div>

      <TablePagination
        component="div"
        count={data?.length || 0}
        rowsPerPage={rowParPage}
        page={page}
        labelRowsPerPage={'Lignes par page'}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Paper>
  );
};
