import { AuthorFragment } from '../../../__generated__/queries-web';
import { auth } from '../../../utils/auth';

export type AuthorContext = Pick<
  AuthorFragment,
  | 'name'
  | 'id'
  | 'userId'
  | 'googleId'
  | 'avatarUrl'
  | 'description'
  | 'facebookId'
  | 'twitterId'
  | 'instagramId'
  | 'linkedinId'
  | 'pinterestId'
  | 'snapshatId'
  | 'isMain'
  | 'hasPublicProfile'
  | 'authorAttributes'
  | 'resume'
  | 'email'
  | 'media'
>;

export const initialState = {
  name: '',
  id: '',
  userId: auth.user?.id || null,
  googleId: null,
  avatarUrl: null,
  description: null,
  facebookId: null,
  twitterId: null,
  instagramId: null,
  linkedinId: null,
  pinterestId: null,
  snapshatId: null,
  isMain: null,
  hasPublicProfile: null,
  authorAttributes: [],
  resume: null,
  email: null,
  media: null,
};
