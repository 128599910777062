import React, { FC, useEffect, useState } from 'react';
import {
  AutocompleteItem,
  ChipsInput,
  isDefined,
} from '@prismamedia/one-components';
import {
  useAuthorsGetter,
  useAuthorGetter,
} from '../../../apollo/queries/authors.web.graphql';
import { AuthorOrderByInput } from '../../../__generated__/queries-web';

interface AuthorsSelectProps {
  label: string;
  className?: string;
  value?: string[];
  onChange?: (value?: string[]) => void;
}

export const AuthorsSelect: FC<AuthorsSelectProps> = ({
  label,
  className,
  value,
  onChange,
}) => {
  const [internalValue, setInternalValue] = useState<AutocompleteItem[]>([]);
  const getAuthors = useAuthorsGetter();
  const getAuthor = useAuthorGetter();

  const fetchList = (search: string) =>
    getAuthors({
      first: 100,
      where: { name_contains: search },
      orderBy: [AuthorOrderByInput.name_ASC],
    }).then((data) =>
      data?.authors
        .filter(({ name }) => !!name)
        .map(({ id, name }) => ({ id, label: name })),
    );

  useEffect(() => {
    if (!value) {
      setInternalValue([]);
      return;
    }
    Promise.all(value.map((id) => getAuthor({ where: { id } }))).then((res) =>
      setInternalValue(
        res
          .map((data) => data.author)
          .filter(isDefined)
          .map((author) => ({
            id: author.id,
            label: author.name,
          })),
      ),
    );
  }, [value, getAuthor]);

  return (
    <ChipsInput
      variant="outlined"
      label={label}
      value={internalValue}
      className={className}
      onChange={(val) =>
        onChange?.(val?.length ? val.map(({ id }) => id) : undefined)
      }
      autocomplete={{ fetchList }}
    />
  );
};
