import { useMutation, useQuery } from '@apollo/client';
import {
  BuildCircle,
  Cached,
  Delete,
  Download,
  FileCopyOutlined,
  FilterCenterFocus,
  History,
  RemoveRedEye,
  Search,
} from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  EditAppBar,
  HistoryDialog,
  ImageSelectionBar,
  LockStatus,
  NotificationTypeEnum,
  getImageUrl,
  notificationQueue,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import downloadFile from 'js-file-download';
import { ChangeEvent, Dispatch, FC, MouseEvent, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
  GetArticle_article_articleHistories,
  GetBrands,
  recipeDescriptions,
  recipeDescriptionsVariables,
} from '../../../__generated__/queries-web';
import { useGetArticleHistoryCount } from '../../../apollo/queries/articleHistoryCount.web.graphql';
import { ArticlesRelatedMediaDialog } from '../../../components/ArticlesRelatedMediaDialog';
import { StatusSelector } from '../../../components/StatusSelector';
import { paths } from '../../../routing/Router/paths';
import { MediaBlock } from '../../../types/draft';
import { uploadAndEmbedImage } from '../../../utils/upload';
import { ImageItem } from '../Grid';
import { mapSlideshowToGalleryItem } from '../Grid/utils';
import { ImageInfos } from '../ImageInfos';
import { SlideOptions, SlideshowEditAction, actions } from '../reducer';
import { GET_BRANDS } from './getBrands.web.graphql';
import { useStyles } from './styles';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { GET_USER_OPEN_AI_PROCESSES } from '../../../apollo/queries/getOpenAiProcess.web.graphql';
import { UPDATE_RECIPE_DESCRIPTIONS } from '../../../apollo/mutations/updateRecipeDescriptions.web.graphql';
import { client } from '../../../apollo';
interface AppbarProps {
  id?: string;
  brandKey: BrandKey | null;
  slidesIndex: string[];
  selectedSlidesIndex: string[];
  title: string;
  format?: ArticleFormat;
  slug?: string | null;
  status: ArticleStatus | null;
  changeStatus: (newStatus: ArticleStatus) => void;
  saveSlideshow: (forcedArticle?: GetArticle_article) => Promise<void>;
  dispatch: Dispatch<SlideshowEditAction>;
  publicUrl?: string | null;
  openCropModal: () => void;
  articleHistories: GetArticle_article_articleHistories[];
  slideMap: Record<string, MediaBlock>;
  concurrentialAccess?: {
    disableLockForV0?: boolean;
    lockStatus?: Pick<LockStatus, 'lockedBy'> | null;
  } | null;
  updateLockerId?: (
    id: string,
    lockerId: string | null,
    noCacheUpdate?: boolean | undefined,
  ) => Promise<any>;
}

export const AppBar: FC<AppbarProps> = ({
  id,
  brandKey,
  status,
  title,
  format,
  slug,
  selectedSlidesIndex,
  slidesIndex,
  changeStatus,
  saveSlideshow,
  dispatch,
  publicUrl,
  openCropModal,
  articleHistories,
  slideMap,
  concurrentialAccess,
  updateLockerId,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { pushNotification, removeNotification } = useNotification();
  const { openDialog, closeDialog } = useDialog();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [downloading, setDownloading] = useState(false);
  const {
    data: articleHistoryCountData,
    loading: historyLoading,
  } = useGetArticleHistoryCount(id || '');
  const [isReplacingImage, setIsReplacingImage] = useState(false);
  const { data } = useQuery<GetBrands>(GET_BRANDS);
  const [updateRecipeDescription] = useMutation<
    recipeDescriptions,
    recipeDescriptionsVariables
  >(UPDATE_RECIPE_DESCRIPTIONS, {
    onCompleted: () => {
      // redirect to the IA process page
      if (brandKey) {
        client.refetchQueries({
          include: [GET_USER_OPEN_AI_PROCESSES],
        });
        return history.push(
          generatePath(paths.SLIDESHOW_IA, {
            brandKey,
            activeTab: 'list',
            slideshowId: undefined,
          }),
        );
      }
      throw Error('Brand key is not defined');
    },
  });

  const handleClick = (key: string) => {
    setAnchorEl(null);
    const newLocation = generatePath(paths.SLIDESHOW_DUPLICATE, {
      id: id || '',
      brandKey: key,
    });
    window.open(`${window.location.origin}#${newLocation}`);
  };

  const downloadSelection = async () => {
    const selectedSlidesContent = selectedSlidesIndex.map(
      (slideId) => slideMap[slideId],
    );

    const queue = selectedSlidesContent.map((slide) => async () => {
      const image = await fetch(getImageUrl(slide.data));
      const imageBlob = await image.blob();
      const { originalName, extension } = slide.data.iframely.meta;
      const fileName = originalName
        ? `${originalName}${
            originalName.endsWith(extension || 'jpeg')
              ? ''
              : `.${extension || 'jpeg'}`
          }`
        : 'sans-titre.jpg';
      return downloadFile(imageBlob, fileName);
    });

    setDownloading(true);
    try {
      await notificationQueue(
        queue,
        pushNotification,
        removeNotification,
        'Téléchargement',
      );
    } catch (e) {
      if (e instanceof Error) {
        pushNotification({
          message: e.message,
          type: NotificationTypeEnum.error,
        });
      }
    }
    setDownloading(false);
  };

  const barActions = [
    {
      id: 'location',
      label: 'Localisation',
      icon: <Search />,
      onClick: () => {
        if (!slug) {
          pushNotification({
            type: NotificationTypeEnum.error,
            message: "L'identifiant du media n'existe pas",
          });
          return;
        }

        openDialog(
          <ArticlesRelatedMediaDialog
            idMedia={slug}
            title={title}
            articleFormat={format}
            handleClose={closeDialog}
          />,
          { maxWidth: 'lg' },
        );
      },
    },
    {
      id: 'history',
      label: 'Historique',
      icon: <History />,
      onClick: () =>
        openDialog(
          <HistoryDialog
            histories={articleHistories}
            historyCount={articleHistoryCountData?.articleHistoryCount}
            loading={historyLoading}
            historyLabel="sur ce diaporama"
            createLabel="Création du diaporama"
          />,
          {
            maxWidth: 'lg',
          },
        ),
    },
    ...(publicUrl &&
    status &&
    [ArticleStatus.Published, ArticleStatus.Scheduled].includes(status)
      ? [
          {
            id: 'see-front',
            label: 'Visualiser',
            icon: <RemoveRedEye />,
            onClick: () => window.open(publicUrl),
          },
        ]
      : []),
    ...(data
      ? [
          {
            id: 'duplicate',
            label: 'Dupliquer',
            icon: <FileCopyOutlined />,
            onClick: (e: MouseEvent<HTMLButtonElement>) =>
              setAnchorEl(e.currentTarget),
          },
        ]
      : []),
    ...(status === 'Published'
      ? [
          {
            id: 'diagnostic',
            label: 'Diagnostic',
            icon: <BuildCircle />,
            onClick: () => {
              window.open(
                `https://diagnostic.prismamedia.com/pipeline/create?manifest=one%2Farticle&id=${id}&_target=all&_format=notify`,
              );
            },
          },
        ]
      : []),
  ];

  const selectionBarActions = [
    {
      id: 'download',
      label: 'Télécharger',
      icon: <Download />,
      onClick: downloadSelection,
      disabled: downloading,
    },
    {
      id: 'crop',
      label: 'Redimensionnement et point de focus',
      icon: <FilterCenterFocus />,
      disabled: selectedSlidesIndex.length !== 1,
      onClick: openCropModal,
    },
    {
      id: 'replace',
      label: 'Remplacer',
      icon: <Cached />,
      disabled: isReplacingImage || selectedSlidesIndex.length !== 1,
      onClick: () => {
        inputRef.current?.click();
      },
    },
    {
      id: 'delete',
      label: 'Supprimer',
      icon: <Delete />,
      onClick: () =>
        openDialog(
          <>
            <DialogTitle>Confirmer la suppression ?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer la sélection ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary" variant="outlined">
                Annuler
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    actions.removeSlides({
                      slideKeys: selectedSlidesIndex,
                    }),
                  );
                  closeDialog();
                }}
                color="primary"
                variant="contained"
                autoFocus
              >
                Oui
              </Button>
            </DialogActions>
          </>,
        ),
    },
  ];

  const replaceCurrentImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const [slideId] = selectedSlidesIndex;
    const selectedSlide = slideMap[slideId];
    if (files?.length !== 1 || !selectedSlide || !brandKey) {
      return false;
    }

    try {
      setIsReplacingImage(true);
      const iframely = await uploadAndEmbedImage(files[0], brandKey);
      if (iframely) {
        iframely.meta = {
          ...iframely.meta,
          source: selectedSlide.data.iframely.meta.source,
        };

        const slideToAdd: SlideOptions = {
          iframely,
          caption: selectedSlide.data.caption,
          slideshow: selectedSlide.data.slideshow as any,
        };

        dispatch(
          actions.updateSlide({
            ...slideToAdd,
            slideKey: selectedSlide.key,
          }),
        );
      }
    } catch (error) {
      console.error(error);
      pushNotification({
        message: "Erreur lors de la récupération de l'image",
        type: NotificationTypeEnum.error,
      });
    } finally {
      setIsReplacingImage(false);
    }
  };

  return (
    <>
      <input
        accept="image/*"
        type="file"
        required
        ref={inputRef}
        multiple={false}
        onChange={replaceCurrentImage}
        className={classes.imageInput}
      />
      <EditAppBar
        onNavigateBack={() => {
          history.push(paths.SLIDESHOW_LIST);
          updateLockerId && id && updateLockerId(id, null).catch(() => {});
        }}
        title={title}
        actions={barActions}
        save={{
          onClick: () => saveSlideshow(),
        }}
        additionalElement={
          <StatusSelector currentStatus={status} changeStatus={changeStatus} />
        }
        concurrentialAccess={concurrentialAccess}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {data?.categories.map(({ brandKey: key, title: brandTitle }) => (
          <MenuItem key={key} onClick={() => handleClick(key)}>
            {brandTitle}
          </MenuItem>
        ))}
      </Menu>
      <ImageSelectionBar
        selection={selectedSlidesIndex.map((key) =>
          mapSlideshowToGalleryItem(key, slideMap[key]),
        )}
        onSelectionClear={() =>
          dispatch(actions.setSelectedSlides({ slideKeys: [] }))
        }
        infoRenderer={(item: ImageItem) => <ImageInfos item={item} />}
        actions={
          brandKey && [BrandKey.CAC, BrandKey.FAC].includes(brandKey)
            ? [
                {
                  id: 'ia-description',
                  label: `I.A. Descriptions Recettes ${
                    status !== ArticleStatus.Draft
                      ? '(Disponible uniquement pour les brouillons)'
                      : ''
                  }`,
                  disabled: status !== ArticleStatus.Draft,
                  icon: <ReadMoreIcon />,
                  onClick: () => {
                    if (status !== ArticleStatus.Draft) {
                      return pushNotification({
                        message:
                          'Cette fonctionnalité est disponible uniquement pour les diaporamas en brouillon',
                        type: NotificationTypeEnum.error,
                      });
                    }
                    const selectedSlidesUrls = selectedSlidesIndex
                      .map(
                        (slideId) =>
                          slideMap[slideId].data.slideshow?.buttonUrl,
                      )
                      .filter(Boolean) as string[];

                    openDialog(
                      <>
                        <DialogTitle>
                          Confirmer la génération des descriptions ?
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Toutes les modifications en cours vont être
                            sauvegardées avant de lancer la génération des
                            descriptions avec I.A.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={closeDialog}
                            color="primary"
                            variant="outlined"
                          >
                            Annuler
                          </Button>
                          <Button
                            onClick={async () => {
                              if (!id) {
                                throw new Error('Slideshow Id is not defined');
                              }
                              try {
                                await saveSlideshow();
                              } catch (e) {
                                pushNotification({
                                  message:
                                    'Erreur lors de la sauvegarde du diaporama',
                                  type: NotificationTypeEnum.error,
                                });
                              }
                              updateRecipeDescription({
                                variables: {
                                  recipesUrls: selectedSlidesUrls,
                                  slideshowEditionId: id,
                                },
                              });
                              closeDialog();
                            }}
                            color="primary"
                            variant="contained"
                            autoFocus
                          >
                            Confirmer
                          </Button>
                        </DialogActions>
                      </>,
                    );

                    return null;
                  },
                },
                ...selectionBarActions,
              ]
            : selectionBarActions
        }
        additionalElement={
          <Button
            color="primary"
            onClick={() =>
              dispatch(actions.setSelectedSlides({ slideKeys: slidesIndex }))
            }
          >
            Tout Sélectionner
          </Button>
        }
      />
    </>
  );
};
