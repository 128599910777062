import { AutoFixHigh, Delete } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  capitalize,
} from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteMode,
  LoadingButton,
  NotificationTypeEnum,
  SearchBar,
  SearchBarVariant,
  SearchParams,
  useAdvice,
  useNotification,
} from '@prismamedia/one-components';
import clsx from 'clsx';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TagsProps } from '../../';
import {
  BrandKey,
  GetArticle_article_articleTags_tag,
  GetSuggestedTagsList_suggestTags_items,
  TagProviderName,
  TagType,
} from '../../../../__generated__/queries-web';
import { useSearchEnabledTagsGetter } from '../../../../apollo/queries/getEnabledTags.web.graphql';
import { useSearchSuggestedTagsGetter } from '../../../../apollo/queries/getSuggestedTagsList.web.graphql';
import { ArticleEditorTagPopper } from '../../../../pages/ArticleEdit/ArticleEditor/poppers';
import { canCreateTag } from '../../../../utils/auth';
import { brandsOptions } from '../../../../utils/brands';
import { DragAndDrop } from '../../../DragAndDrop';
import type { SkeletonsProps } from '../../../Skeletons';
import { Skeletons } from '../../../Skeletons';
import { useEditableTag } from '../../useEditTag';
import {
  AutocompleteListHeader,
  getSuggestedTagsArticleParams,
  getTagsAutoCompleteList,
} from '../../utils';
import { useStyles as useSharedStyles } from '../styles';
import { Tag } from './Tag';
import {
  ALLOWED_TAG_PROVIDERS,
  ALLOWED_TAG_TYPES,
  ARTICLE_ENHANCEMENT_TAGS_TITLE,
  NO_ARTICLE_TAG_SELECTED_TITLE,
  TAGS_DEFAULT_SECTION_DATA_TESTID,
  TAG_CREATE_BUTTON_TEXT,
  TAG_SEARCH_BAR_NO_RESULT_PLACEHOLDER,
  TAG_SEARCH_BAR_PLACEHOLDER,
  TAG_SUGGESTION_RESULT,
} from './constants';
import { useAllowedTags } from './hooks';
import { useStyles } from './styles';
import {
  getTagTypeFromPathname,
  isBrandkeyCanHavePersonTag,
  isTagAlreadyCreated,
  isTagAlreadyListed,
} from './utils';

export interface DefaultProps
  extends Pick<TagsProps, 'article' | 'onTagsChange'> {
  allowedTagProviders?: TagProviderName[];
  allowedTagTypes?: TagType[];
  brandKey: BrandKey;
  className?: string;
  controls?: {
    activation: boolean;
  };
  field?: string;
  height?: number;
  isAllowedToCreateTag?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  maxQuantity?: number;
  SearchBarProps?: {
    className?: string;
    placeholder?: string;
    variant?: SearchBarVariant;
  };
  SkeletonsProps?: SkeletonsProps;
  tags: GetArticle_article_articleTags_tag[];
  title?: string;
  width?: number;
}

const Default: FC<DefaultProps> = ({
  allowedTagProviders,
  allowedTagTypes,
  article,
  brandKey,
  className,
  controls,
  field,
  height,
  isAllowedToCreateTag = false,
  isDisabled = false,
  isLoading = false,
  maxQuantity,
  onTagsChange,
  SearchBarProps,
  SkeletonsProps,
  tags: selectedTags,
  title,
  width,
}) => {
  const isFirstRender = useRef(true);
  const searchTags = useSearchEnabledTagsGetter();
  const searchSuggestedTags = useSearchSuggestedTagsGetter();
  const [suggestLoading, setSuggestLoading] = useState(false);
  const { pushNotification } = useNotification();

  const {
    newTags,
    handlers: {
      handleAddTag,
      handleChangeTag,
      handleCreateTag,
      handleDeleteTag,
      handleReorderTagList,
      clear,
    },
  } = useEditableTag<GetArticle_article_articleTags_tag>(
    selectedTags,
    brandKey,
  );

  const [autocompleteList, setAutocompleteList] = useState<AutocompleteItem[]>(
    [],
  );
  const [defaultSuggestedTagList, setDefaultSuggestedTagList] = useState<
    AutocompleteItem[] | undefined
  >();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
  });
  const { pathname } = useLocation();
  const newAllowedTagTypes = useAllowedTags(
    allowedTagTypes || ALLOWED_TAG_TYPES,
  );

  const newAllowedTagProviders = useAllowedTags(
    allowedTagProviders || ALLOWED_TAG_PROVIDERS,
  );

  const selectedTagList = selectedTags.filter(
    ({ type }) => newAllowedTagTypes.indexOf(type) > -1,
  );

  const [filteredSuggestedTagList, setFilteredSuggestedTagList] = useState<
    GetSuggestedTagsList_suggestTags_items[]
  >([]);

  const sharedClasses = useSharedStyles({
    selectedTagListLength: selectedTagList.length,
  });
  const classes = useStyles({ height, isDisabled, width });

  const maxTagsQuantityReached = !!(
    maxQuantity && selectedTagList.length >= maxQuantity
  );
  const tagFieldRef = useRef(null);
  const { openAdvice } = useAdvice();

  const CreateTagButton: FC<{ className?: string; onClose?: () => void }> = ({
    className: buttonClassName,
    onClose,
  }) => (
    <Button
      className={buttonClassName}
      color="primary"
      disabled={
        isTagAlreadyCreated(selectedTagList, searchParams?.search) ||
        isTagAlreadyListed(autocompleteList, searchParams?.search) ||
        maxTagsQuantityReached
      }
      onClick={() => {
        handleCreateTag({
          title: capitalize(searchParams.search as string),
          type: getTagTypeFromPathname(pathname),
        });

        setSearchParams({ search: '' });
        onClose?.();
      }}
      size="small"
      variant="outlined"
    >
      {TAG_CREATE_BUTTON_TEXT}
    </Button>
  );

  const getFilteredTagList = useCallback(
    async (search: string): Promise<AutocompleteItem[] | undefined> => {
      setSearchParams({ search });
      // If we can't get suggestions (no article provided), we returns empty list
      if (!article && !search.length) {
        return new Promise((resolve) => {
          resolve([]);
        });
      }

      // Get suggestions
      else if (article && !search.length) {
        // Get suggested tags and sorting them by score
        return searchSuggestedTags({
          brandKey,
          article: getSuggestedTagsArticleParams(article),
          providers: newAllowedTagProviders.filter((tagProviderName) =>
            isBrandkeyCanHavePersonTag(tagProviderName, brandKey),
          ),
        }).then((suggestTags) => {
          const tagList = suggestTags?.items || [];
          setFilteredSuggestedTagList(tagList);
          const suggestedTagList = getTagsAutoCompleteList(
            tagList,
            selectedTagList,
            {
              withActivationControl: controls?.activation,
              withSecondaryLabel: !newAllowedTagProviders.includes(
                TagProviderName.VideoTag,
              ),
            },
          );
          setDefaultSuggestedTagList(suggestedTagList);
          return suggestedTagList;
        });
      }

      // Get tags based on field value
      return searchTags({
        providers: newAllowedTagProviders.filter((tagProviderName) =>
          isBrandkeyCanHavePersonTag(tagProviderName, brandKey),
        ),
        brandKey,
        search,
      }).then((sortedTagList = []) => {
        const newList = getTagsAutoCompleteList(
          sortedTagList,
          selectedTagList,
          {
            withActivationControl: controls?.activation,
            withArticleCountSort: true,
            withSecondaryLabel: !newAllowedTagProviders.includes(
              TagProviderName.VideoTag,
            ),
          },
        );

        // We need local autocomplete list to handle "Create tag" button behavior
        if (isAllowedToCreateTag && canCreateTag(newAllowedTagProviders)) {
          setAutocompleteList(newList);
        }

        return newList;
      });
    },
    [
      article,
      brandKey,
      controls?.activation,
      isAllowedToCreateTag,
      newAllowedTagProviders,
      searchSuggestedTags,
      searchTags,
      selectedTagList,
    ],
  );

  const handleInfoIconClick = useCallback(
    (anchorRef, content) => () => {
      openAdvice({
        anchorRef,
        content,
        PopperProps: {
          keepMounted: false,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundariesElement: 'window',
                enabled: false,
                escapeWithReference: true,
              },
            },
          ],
          style: { width: '310px' },
        },
        zIndex: 1,
      });
    },
    [openAdvice],
  );

  const onAddSuggested = useCallback(async () => {
    setSuggestLoading(true);
    try {
      const suggestedTags = await getFilteredTagList('');
      suggestedTags
        ?.filter(({ selected }) => !selected)
        .slice(0, 5)
        .forEach(
          ({ additionnals }) => additionnals && handleAddTag(additionnals),
        );
    } catch (e) {
      pushNotification({
        message: (e as Error).message,
        type: NotificationTypeEnum.error,
      });
    }
    setSuggestLoading(false);
  }, [getFilteredTagList, handleAddTag, pushNotification]);

  const onDeleteAll = useCallback(async () => {
    onTagsChange([], field);
    clear();
  }, [clear, field, onTagsChange]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    newTags && onTagsChange(newTags, field);
    // eslint-disable-next-line
  }, [field, newTags]);

  const { displayNewModule } = brandsOptions[brandKey];

  return (
    <Grid
      alignItems="flex-end"
      className={clsx(sharedClasses.wrapper, className)}
      container
      data-testid={TAGS_DEFAULT_SECTION_DATA_TESTID}
      justifyContent="space-between"
    >
      <Grid item xs={4}>
        <Stack direction={'row'} spacing={1}>
          {!displayNewModule && (
            <InfoIcon
              onClick={handleInfoIconClick(tagFieldRef, ArticleEditorTagPopper)}
              ref={tagFieldRef}
            />
          )}
          <Typography className={sharedClasses.subTitle} component="h3">
            {title || ARTICLE_ENHANCEMENT_TAGS_TITLE}{' '}
            <span>
              ({selectedTagList.length}
              {maxQuantity && `/${maxQuantity}`})
            </span>
          </Typography>
          {displayNewModule && (
            <InfoIcon
              fontSize="small"
              onClick={handleInfoIconClick(tagFieldRef, ArticleEditorTagPopper)}
              ref={tagFieldRef}
            />
          )}
        </Stack>
      </Grid>

      <Grid item xs={8}>
        <SearchBar
          autocomplete={{
            disabled: maxTagsQuantityReached || isLoading || isDisabled,
            fetchList: getFilteredTagList,
            listHeader: (onClose) => (
              <AutocompleteListHeader
                {...(searchParams?.search?.length && {
                  label: TAG_SUGGESTION_RESULT,
                  showListLength: false,
                  ...(isAllowedToCreateTag &&
                    canCreateTag(newAllowedTagProviders) && {
                      secondaryLabel: (
                        <CreateTagButton
                          className={classes.listHeaderButton}
                          onClose={onClose}
                        />
                      ),
                    }),
                })}
                listLength={filteredSuggestedTagList.length}
              />
            ),
            suggestedTagList: defaultSuggestedTagList,
            mode: AutocompleteMode.MULTIPLE,
            onSelect: ({ additionnals }) => {
              additionnals && handleAddTag(additionnals);
            },
            ...(article && {
              placeholder: (onClose) => (
                <>
                  <span className={classes.searchBarNoResultPlaceholder}>
                    {TAG_SEARCH_BAR_NO_RESULT_PLACEHOLDER}
                  </span>

                  {isAllowedToCreateTag &&
                    canCreateTag(newAllowedTagProviders) &&
                    Boolean(searchParams?.search?.length) && (
                      <CreateTagButton onClose={onClose} />
                    )}
                </>
              ),
            }),
          }}
          className={SearchBarProps?.className}
          fetchListMinValueLength={0}
          isDisabled={isDisabled || isLoading}
          placeholder={
            SearchBarProps?.placeholder || TAG_SEARCH_BAR_PLACEHOLDER
          }
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          variant={SearchBarProps?.variant || SearchBarVariant.SECONDARY}
        />
      </Grid>

      {isLoading ? (
        <Box className={classes.wrapperSkeletons}>
          <Skeletons
            {...SkeletonsProps}
            height={SkeletonsProps?.height || height}
            width={SkeletonsProps?.width || width}
          />
        </Box>
      ) : (
        <Box className={sharedClasses.innerWrapper} component="section">
          <Box className={classes.dragAndDropWrapper}>
            <DragAndDrop
              itemList={selectedTagList}
              renderItem={(itemProps) => (
                <Tag
                  brandKey={brandKey}
                  controls={controls}
                  onChange={handleChangeTag}
                  onDelete={handleDeleteTag}
                  SkeletonsProps={SkeletonsProps}
                  {...itemProps}
                />
              )}
              onDragEnd={handleReorderTagList}
            />
          </Box>

          {!selectedTagList.length && (
            <Typography component="p">
              {NO_ARTICLE_TAG_SELECTED_TITLE}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              mt: selectedTagList.length ? 2 : undefined,
              justifyContent: 'center',
              mb: selectedTagList.length ? undefined : 2,
            }}
          >
            <LoadingButton
              loading={suggestLoading}
              startIcon={<AutoFixHigh />}
              variant="outlined"
              onClick={onAddSuggested}
              disabled={!article?.title}
            >
              Suggestions
            </LoadingButton>
            {!!selectedTagList.length && (
              <Button
                startIcon={<Delete />}
                variant="outlined"
                sx={{ ml: 2 }}
                onClick={onDeleteAll}
              >
                Tout supprimer
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export { Default };
