import { SuggestItemType } from './types';

export const flatten = (items: SuggestItemType[] = []) =>
  items.reduce((acc: SuggestItemType[], item: SuggestItemType) => {
    acc = acc.concat({
      title: item.title,
      level: item.level,
      id: item.id,
    });
    if (item.children && item.children.length > 0) {
      acc = acc.concat(flatten(item.children));
    }
    return acc;
  }, []);
