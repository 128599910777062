import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { Edit, History } from '@mui/icons-material';
import {
  getThumbnail,
  HistoryDialog,
  InfiniteList,
  useDialog,
} from '@prismamedia/one-components';
import React, {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { GetAuthors_authors } from '../../../__generated__/queries-web';
import { FormDialog } from '../FormDialog';
import { useStyles } from './styles';
import { paths } from '../../../routing/Router/paths';
import { generatePath, useHistory } from 'react-router-dom';

interface AuthorListProps {
  authors: GetAuthors_authors[];
  isFullScreen: boolean;
  tabValue: string;
  loadMore: (skip: number) => Promise<GetAuthors_authors[]>;
  setAuthor: Dispatch<SetStateAction<GetAuthors_authors[] | []>>;
}

export const AuthorList: FC<AuthorListProps> = ({
  authors,
  isFullScreen,
  tabValue,
  loadMore,
  setAuthor,
}) => {
  const classes = useStyles({});
  const history = useHistory();
  const [handleOpen, setOpen] = useState<boolean>(false);
  const [currentAuthor, setCurrentAuthor] = useState<GetAuthors_authors>();
  const { openDialog } = useDialog();
  const resetStates = () => {
    setOpen(false);
    setCurrentAuthor(undefined);
  };

  useEffect(() => {
    resetStates();
  }, [tabValue]);

  if (!authors) {
    return null;
  }

  if (authors.length === 0) {
    return (
      <div className={classes.root}>
        <Typography>Aucun résultat...</Typography>
      </div>
    );
  }

  const handleEditClick = (
    _: MouseEvent<HTMLButtonElement>,
    author: GetAuthors_authors,
  ) => {
    setOpen(true);
    setCurrentAuthor(author);
    history.push(generatePath(paths.AUTHORS_EDIT, { id: author.id }));
  };

  const handleEditClose = () => {
    setOpen(false);
    history.goBack();
  };

  return (
    <>
      <div className={classes.list}>
        <InfiniteList
          list={authors}
          rowRenderer={(author: GetAuthors_authors) => (
            <ListItem key={author.id} role={undefined} dense button>
              <ListItemAvatar>
                <Avatar src={getThumbnail(author.media) || author.avatarUrl!} />
              </ListItemAvatar>
              <ListItemText id={author.id} primary={author.name} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={(_) => handleEditClick(_, author)}
                  edge="end"
                  aria-label="edit"
                  size="large"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    openDialog(
                      <HistoryDialog
                        histories={author.authorHistories.map(
                          (historyAuthor) => {
                            return {
                              updatedAt: historyAuthor.updatedAt,
                              createdAt: historyAuthor.createdAt,
                              id: historyAuthor.id,
                              status: null,
                              user: historyAuthor.user,
                            };
                          },
                        )}
                        historyLabel="par les auteurs"
                      />,
                    );
                  }}
                  edge="end"
                  aria-label="edit"
                  size="large"
                >
                  <History />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
          rowHeight={50}
          fetchMore={({ startIndex }) => loadMore(startIndex)}
        />
        {handleOpen && (
          <FormDialog
            author={currentAuthor}
            isOpen={handleOpen}
            handleClose={handleEditClose}
            {...{ isFullScreen, setAuthor }}
          />
        )}
      </div>
    </>
  );
};
