import { BrandMenu } from '@prismamedia/one-components';
import { FC, useEffect, useMemo } from 'react';
import { BrandKey } from '../../__generated__/queries-web';
import { useGetBrandKey } from '../../apollo/sharedQueries/getBrandKeys.web.graphql';
import { brandsOptions } from '../../utils/brands';

const ALL_ID = 'ALL';

interface BrandSwitchProps {
  brandKey?: BrandKey;
  setBrandKey?: (brandKey: BrandKey | undefined) => void;
}

export const BrandSwitch: FC<BrandSwitchProps> = ({
  brandKey,
  setBrandKey,
}) => {
  const { data } = useGetBrandKey();

  const brandKeyItemList = useMemo(
    () =>
      (data?.categories || [])
        .filter((cat) => !brandsOptions[cat.brandKey].disabledForCerise)
        .sort((a, b) => a.brandKey.localeCompare(b.brandKey))
        .map(({ brandKey: label }) => ({
          id: label,
          label,
          selected: brandKey === label,
        })) || [],
    [brandKey, data],
  );

  useEffect(() => {
    if (!brandKey && data?.categories.length === 1) {
      setBrandKey?.(data.categories[0]?.brandKey);
    }
  }, [data, brandKey, setBrandKey]);

  const brandKeyItemListWithAll = [
    {
      id: ALL_ID,
      label: 'Toutes',
      selected: !brandKey,
    },
    ...brandKeyItemList,
  ];

  return (
    <BrandMenu
      defaultLabel="Marque"
      items={brandKeyItemListWithAll}
      onItemSelect={({ id }) =>
        setBrandKey?.(id === ALL_ID ? undefined : (id as BrandKey))
      }
    />
  );
};
