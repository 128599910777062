import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Uri from 'urijs';

export function useQueryParams<T extends Record<string, any>>(keyParam: T) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(() => {
    const uri = new Uri(location.search);
    const parsedSearch = uri.search(true);
    const paramNames = Object.keys(keyParam);
    const values: T = { ...keyParam };
    for (let i = 0; i < paramNames.length; ++i) {
      values[paramNames[i] as keyof T] = parsedSearch[paramNames[i]];
    }
    return values;
  }, [keyParam, location.search]);

  const setQueryParam = useCallback(
    (key: keyof T, newValue: any, action: 'push' | 'replace' = 'push') => {
      const uri = new Uri(location.search);
      const newRedirect = uri.setSearch({ [key]: newValue });

      history[action]({ search: newRedirect.toString() });
    },
    [location.search, history],
  );

  return { queryParams, setQueryParam };
}
