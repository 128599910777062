import { gql, useApolloClient } from '@apollo/client';
import { getProvidersByBrandKey } from '../../components/Tags/utils';
import { useFetch } from '../../utils/useFetch';
import {
  BrandKey,
  GetSearchEnabledTags,
  GetSearchEnabledTagsVariables,
  TagProviderName,
  TagSearchTypes,
  TagType,
  GetTag,
  GetTagVariables,
} from '../../__generated__/queries-web';

export const SEARCH_TAG_FRAGMENT = gql`
  fragment SearchTagFragment on TagProviderResult {
    id
    title
    type
    relationId
    articleCount
    isEnabled
  }
`;

const GET_SEARCH_ENABLED_TAGS = gql`
  query GetSearchEnabledTags(
    $providers: [TagProviderName!]!
    $brandKey: BrandKey!
    $query: String!
    $first: Int!
    $types: [TagSearchTypes!]!
  ) {
    searchEnabledTags(
      providers: $providers
      brandKey: $brandKey
      query: $query
      first: $first
      types: $types
    ) {
      name
      itemCount
      items {
        ...SearchTagFragment
      }
    }
  }
  ${SEARCH_TAG_FRAGMENT}
`;

interface SearchTagsEnabledTags {
  brandKey: BrandKey;
  opts?: { sort?: boolean };
  providers?: TagProviderName[];
  search: string;
  types?: TagSearchTypes[];
}

export const useSearchEnabledTagsGetter = () => {
  const client = useApolloClient();
  return ({
    brandKey,
    providers = getProvidersByBrandKey(brandKey),
    search,
    types,
  }: SearchTagsEnabledTags) => {
    return client
      .query<GetSearchEnabledTags, GetSearchEnabledTagsVariables>({
        query: GET_SEARCH_ENABLED_TAGS,
        variables: {
          brandKey,
          providers,
          query: search,
          first: 25,
          types: types || [],
        },
      })
      .then(({ data, errors }) => {
        if (errors?.length) {
          throw errors[0];
        }
        const flattenedTags = data?.searchEnabledTags.flatMap(
          ({ items }) => items,
        );

        const flattenedOrderedTags = flattenedTags.sort(
          ({ articleCount }, { articleCount: articleCountNext }) =>
            (articleCountNext || 0) - (articleCount || 0),
        );

        // We removing potential dupplicate title tags btw defaults and people tags
        if (
          providers.indexOf(TagProviderName.Tag) !== -1 &&
          providers.indexOf(TagProviderName.Person) !== -1
        ) {
          const filteredFlatenedTags = flattenedOrderedTags.filter(
            (tag, _, self) =>
              !self.find(
                // When we found a dupplicate title, we keep the people tags
                (t) =>
                  tag.type === TagType.Tag &&
                  t.type === TagType.Person &&
                  t.title.toLowerCase() === tag.title.toLowerCase(),
              ),
          );

          return filteredFlatenedTags;
        }

        return flattenedOrderedTags;
      });
  };
};

const GET_TAG = gql`
  query GetTag($where: TagWhereUniqueInput!) {
    tag(where: $where) {
      id
      title
    }
  }
`;

export const useTagGetter = () => useFetch<GetTag, GetTagVariables>(GET_TAG);
