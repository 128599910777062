import { Breadcrumbs, Chip, CircularProgress, Typography } from '@mui/material';
import { Lock, NavigateNext } from '@mui/icons-material';
import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { paths } from '../../../../../../routing/Router/paths';
import { CategoriesContext } from '../../../../categories.context';
import { DataTestIdListPage } from '../../../../tests/constants';
import { getFlattenedBrandCategories } from '../../../../utils';
import type { Category } from '../../../../types';
import { Menu as BreadCrumbMenu } from './components/ActionsMenu';
import { MAX_SEGMENTS_BREADCRUMBS } from './constants';
import { useStyles } from './styles';
import { auth } from '../../../../../../utils/auth';
import { DEFAULT_ROOT_CATEGORY } from '../../../../constants';

const isRootCategory = (category: Category | null): boolean =>
  category?.level === DEFAULT_ROOT_CATEGORY.level;

export interface BreadCrumb {
  id: string;
  isActive?: boolean;
  pathname?: string;
  title: string;
}

export const BreadCrumbs: FC = () => {
  const { activeCategory, categories, isCategoriesLoading } = useContext(
    CategoriesContext,
  );

  const routeParams = useParams();
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);
  const isRootCategoryActive = isRootCategory(activeCategory);
  const classes = useStyles({
    isRootCategoryActive,
  });

  const isAllowedToInteractWithRootCategory = auth.user?.isSuperAdmin();

  useEffect(() => {
    const {
      brandKey,
      format,
      lvl1 = categories[0]?.id,
      lvl2,
      lvl3,
      lvl4,
      lvl5,
    } = routeParams;
    const filteredBrandCategories = getFlattenedBrandCategories<Category[]>(
      categories,
      {
        filter: { key: 'id', filterList: [lvl1, lvl2, lvl3, lvl4, lvl5] },
      },
    );
    const newBreadCrumbs = filteredBrandCategories.map(
      ({ id, path, title }, index) => ({
        id,
        isActive: index + 1 === filteredBrandCategories.length,
        pathname: `${generatePath(paths.PAGE_LIST, {
          brandKey,
          format,
        })}${path}`,
        title: !index ? (
          <span className={classes.wrapperBreadCrumbRootCategory}>
            <Chip
              color="primary"
              label="Racine"
              size="small"
              {...(!isAllowedToInteractWithRootCategory && {
                deleteIcon: <Lock />,
                onDelete: () => {},
              })}
            />
            {title}
          </span>
        ) : (
          title
        ),
      }),
    );

    setBreadCrumbs(newBreadCrumbs as BreadCrumb[]);
    // eslint-disable-next-line
  }, [
    categories,
    routeParams,
    activeCategory,
    isAllowedToInteractWithRootCategory,
  ]);
  const canInteractWithRootCategory =
    isAllowedToInteractWithRootCategory &&
    (activeCategory?.children || []).length === 0;
  return (
    <div className={classes.wrapper}>
      <Breadcrumbs
        aria-label="breadcrumb"
        maxItems={MAX_SEGMENTS_BREADCRUMBS}
        separator={<NavigateNext fontSize="small" />}
      >
        {breadCrumbs.map((breadCrumb: BreadCrumb, index: number) => {
          const level = index + 1;
          const shouldShowBreadCrumbMenu =
            breadCrumb.isActive &&
            (level !== DEFAULT_ROOT_CATEGORY.level ||
              canInteractWithRootCategory);

          const shouldShowNavLink = breadCrumb.pathname && level > 1;

          if (shouldShowBreadCrumbMenu) {
            return (
              <BreadCrumbMenu
                activeCategory={activeCategory}
                breadCrumb={breadCrumb}
                data-testid={DataTestIdListPage.LIST_BREADCRUMBS_MENU}
                key={breadCrumb.id || index}
              />
            );
          } else if (shouldShowNavLink) {
            return (
              <NavLink
                className={
                  isRootCategoryActive
                    ? classes.breadCrumbRootLink
                    : classes.breadCrumbLink
                }
                data-testid={DataTestIdListPage.LIST_BREADCRUMBS_LINK}
                key={breadCrumb.id || index}
                to={breadCrumb.pathname!}
              >
                {breadCrumb.title}
              </NavLink>
            );
          }

          return (
            <Typography
              className={classes.breadCrumbLink}
              data-testid={DataTestIdListPage.LIST_BREADCRUMBS_TEXT}
              key={breadCrumb.id || index}
            >
              {breadCrumb.title}
            </Typography>
          );
        })}

        {isCategoriesLoading && (
          <CircularProgress
            className={classes.loader}
            color="primary"
            size={20}
          />
        )}
      </Breadcrumbs>
    </div>
  );
};
