import { AppName } from '@prismamedia/one-components';

export const changeFavicon = (appName?: AppName) => {
  const faviconName =
    appName === AppName.Diapo
      ? 'favicon2'
      : appName === AppName.Page
      ? 'favicon3'
      : 'favicon';

  const link = document.createElement('link'),
    oldLink = document.getElementById(faviconName);
  link.id = faviconName;
  link.rel = 'shortcut icon';
  link.href = `${process.env.PUBLIC_URL}/${faviconName}.ico`;

  if (oldLink) {
    document.head.removeChild(oldLink);
  }

  document.head.appendChild(link);
};
