import { useMutation, useQuery } from '@apollo/client';
import { DeleteForever } from '@mui/icons-material';
import {
  Fab,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import {
  AppName,
  HomeAppBar,
  InfiniteTable,
  useOnMount,
} from '@prismamedia/one-components';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps, generatePath } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  BrandKey,
  PushOrderByInput,
  getPushesByBrandKey,
  getPushesByBrandKeyVariables,
  getPushesByBrandKey_pushes,
  updatePush,
  updatePushVariables,
} from '../../../__generated__/queries-web';
import { useGetBrandKey } from '../../../apollo/sharedQueries/getBrandKeys.web.graphql';
import { OneDrawerMenu } from '../../../components/AppBar';
import { BrandSwitch } from '../../../components/BrandSwitch';
import {
  SpeedDialStyleProps,
  useStyles as speedDialStyle,
} from '../../../components/SpeedDial/styles';
import { paths } from '../../../routing/Router/paths';
import { gertArticleFormatToDisplay } from '../../../utils/articleFormatMapper';
import { auth } from '../../../utils/auth';
import { brandsOptions, getBrandkeys } from '../../../utils/brands';
import { PUSH_HISTORY } from '../PushHistory/pushHistory.web.graphql';
import { CANCEL_PUSH } from '../cancelPush.web.graphql';
import { MobilePushStatusMapper } from '../types';
import { GET_PUSH_BY_BRANDKEY } from './getPushByBrankey.web.graphql';
import { useStyles } from './styles';

interface Push extends getPushesByBrandKey_pushes {
  additionnalIds?: string[];
}

export const MobilePushList: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const speedDialStyles = speedDialStyle() as SpeedDialStyleProps;
  const [brandKey, setBrandKey] = useState<BrandKey | undefined>(undefined);
  const [brandKeyUrl, setBrandKeyUrl] = useQueryParam('brand', StringParam);
  const [open, setOpen] = useState(false);
  const { data: brandKeyData } = useGetBrandKey();
  const [cancelPushMutation] = useMutation<updatePush, updatePushVariables>(
    CANCEL_PUSH,
    { refetchQueries: [GET_PUSH_BY_BRANDKEY, PUSH_HISTORY] },
  );

  const cancelPushHandler = async (id: string) => {
    await cancelPushMutation({
      variables: {
        where: {
          id,
        },
        data: {
          isCancelled: true,
        },
      },
    });
  };

  const variables = {
    first: 100,
    where: {
      article: {
        brandKey_in: brandKey
          ? [brandKey]
          : getBrandkeys(
              ({ editorialAlertAllowed, pushPlatforms }) =>
                !!editorialAlertAllowed || !!pushPlatforms?.includes('android'),
            ),
      },
      isCancelled: null,
      sentAt_is_null: false,
    },
    orderBy: [PushOrderByInput.sentAt_DESC],
  };

  const { loading, error, data, fetchMore } = useQuery<
    getPushesByBrandKey,
    getPushesByBrandKeyVariables
  >(GET_PUSH_BY_BRANDKEY, { variables });

  // We separate the scheduled from the already sent to put the scheduled on top but keep the orderBy sentAt_DESC
  const { data: scheduledData } = useQuery<
    getPushesByBrandKey,
    getPushesByBrandKeyVariables
  >(GET_PUSH_BY_BRANDKEY, {
    variables: {
      ...variables,
      where: {
        ...variables.where,
        sentAt_is_null: true,
      },
      orderBy: [PushOrderByInput.scheduledAt_DESC],
    },
  });

  const allPushes = scheduledData?.pushes &&
    data?.pushes && [...scheduledData.pushes, ...data.pushes];

  const pushes = allPushes
    ?.filter((push) => !brandKey || push.article.brandKey === brandKey)
    ?.map((push) => ({
      ...push,
      targetDevice: ['ea', 'editorialAlert'].includes(push.targetDevice)
        ? 'alerte web'
        : push.targetDevice,
    }))
    ?.reduce((prev, push) => {
      const lastPush = prev[prev.length - 1];
      if (
        lastPush?.article.id === push.article.id &&
        lastPush?.sentAt === push.sentAt &&
        lastPush?.user?.id === push.user?.id
      ) {
        return [
          ...prev.slice(0, -1),
          {
            ...lastPush,
            targetDevice: `${lastPush.targetDevice}, ${push.targetDevice}`,
            additionnalIds: [...(lastPush.additionnalIds || []), push.id],
          },
        ];
      }
      return [...prev, push];
    }, [] as Push[]);

  useOnMount(() => {
    brandKeyUrl && setBrandKey(brandKeyUrl as BrandKey);
  });

  useEffect(() => {
    setBrandKeyUrl(brandKey, 'replaceIn');
  }, [brandKey, setBrandKeyUrl]);

  return (
    <div className={classes.root}>
      <HomeAppBar
        currentUser={auth.user}
        disconnectUser={auth.logout}
        appDrawerMenu={
          <OneDrawerMenu app={AppName.Web} currentBrand={brandKey} />
        }
        brandMenu={
          <>
            <Typography className={classes.title} variant="h6">
              Push
            </Typography>
            <BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />
          </>
        }
      />

      {brandKey ? (
        <Link
          to={generatePath(paths.MOBILE_PUSH, {
            brandKey,
          })}
          data-testid="add-ressource"
        >
          <Fab color="primary" className={speedDialStyles.speedDial}>
            <SpeedDialIcon />
          </Fab>
        </Link>
      ) : (
        <SpeedDial
          FabProps={
            {
              'data-testid': 'add-ressource',
            } as any
          }
          ariaLabel="SpeedDial brands"
          className={speedDialStyles.speedDial}
          icon={<SpeedDialIcon />}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          open={open}
          direction="up"
          color="primary"
          classes={{
            actions: classes.actions,
            fab: classes.fab,
          }}
        >
          {brandKeyData?.categories
            .filter(
              ({ brandKey: brand }) =>
                brandsOptions[brand].editorialAlertAllowed,
            )
            .map(({ brandKey: brand }) => (
              <SpeedDialAction
                key={brand}
                icon={
                  <Link
                    data-testid={`add-ressource-${brand}`}
                    to={() =>
                      generatePath(paths.MOBILE_PUSH, {
                        brandKey: brand,
                      })
                    }
                    className={speedDialStyles.link}
                  >
                    {brand}
                  </Link>
                }
                tooltipTitle={brand}
                onClick={() => setOpen(true)}
              />
            ))}
        </SpeedDial>
      )}

      <div className={classes.tableWrapper}>
        <InfiniteTable
          list={pushes}
          loading={loading}
          error={error}
          threshold={50}
          fetchMore={({ startIndex }) =>
            fetchMore({
              variables: {
                skip: startIndex,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  pushes: [...prev.pushes, ...fetchMoreResult.pushes],
                });
              },
            })
          }
          columns={[
            {
              label: 'Titre',
              key: 'title',
              cellDataGetter: ({ rowData }: { rowData: Push }) => rowData.title,
              width: 300,
              flexGrow: 1,
            },
            {
              label: 'Type',
              key: 'format',
              width: 200,
              cellDataGetter: ({ rowData }: { rowData: Push }) =>
                gertArticleFormatToDisplay(rowData.article.format),
            },
            {
              label: 'Device',
              key: 'targetDevice',
              width: 200,
            },
            {
              label: 'Marque',
              key: 'brand',
              cellDataGetter: ({ rowData }: { rowData: Push }) =>
                rowData.article.brandKey,
              width: 100,
            },
            {
              label: 'Envoyé par',
              key: 'userName',
              cellDataGetter: ({ rowData }: { rowData: Push }) =>
                rowData.user?.name,
              width: 200,
            },
            {
              label: 'Statut',
              key: 'sentAt',
              cellDataGetter: ({ rowData }: { rowData: Push }) =>
                rowData.sentAt
                  ? MobilePushStatusMapper.SENT
                  : rowData.scheduledAt
                  ? MobilePushStatusMapper.SCHEDULED
                  : '-',
              width: 200,
            },
            {
              label: "Date d'envoi",
              key: 'sentAt',
              cellDataGetter: ({ rowData }: { rowData: Push }) =>
                format(
                  Date.parse(rowData.sentAt || rowData.scheduledAt),
                  'yyyy-MM-dd HH:mm:ss',
                ),
              width: 200,
            },
          ]}
          onRowClick={(e, rowData: Push) => {
            e.preventDefault();
            history.push(
              generatePath(`${paths.MOBILE_PUSH}?id=${rowData.article.id}`, {
                brandKey: rowData.article.brandKey,
              }),
            );
          }}
          rowHoverRenderer={(push: Push) =>
            !push.sentAt ? (
              <Tooltip title="Annuler ce push">
                <Fab
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    [push.id, ...(push.additionnalIds || [])].forEach((id) =>
                      cancelPushHandler(id),
                    );
                  }}
                >
                  <DeleteForever color="primary" />
                </Fab>
              </Tooltip>
            ) : null
          }
        />
      </div>
    </div>
  );
};
