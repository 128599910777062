import { CircularProgress, Typography } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useGetArticle } from '../../apollo/queries/articles.web.graphql';
import { useGetMainAuthor } from '../../apollo/queries/authors.web.graphql';
import { paths } from '../../routing/Router/paths';
import { auth } from '../../utils/auth';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
} from '../../__generated__/queries-web';
import { ArticleEdit } from './ArticleEdit';
import { useStyles } from './styles';
import { emptyArticle } from './utils/emptyTypes';
import { translateArticleQuery } from '../../apollo/queries/translateArticles.web.graphql';
import { getBrandLang } from '@prismamedia/one-brandkey';

interface ArticleRouteProps {
  articleType: ArticleFormat;
  id: string;
  brandKey: BrandKey;
}

export const ArticleWrapper: FC<RouteComponentProps<ArticleRouteProps>> = (
  params,
) => {
  const classes = useStyles();
  const { match } = params;
  const { id } = match.params;
  const { data, loading, error, startPolling, stopPolling } = useGetArticle(id);

  useEffect(() => {
    if (
      data?.article?.format === ArticleFormat.Video &&
      data?.article?.status === ArticleStatus.Pending
    ) {
      startPolling(5000);
    }
    return () => stopPolling();
  }, [data, startPolling, stopPolling]);

  if (!id) {
    return <EmptyArticleWrapper {...params} />;
  }

  if (loading) {
    return (
      <div className={classes.centered}>
        <CircularProgress size={80} />
      </div>
    );
  }
  if (error || (data && !data.article)) {
    return (
      <Typography variant="h6">
        Une erreur est survenue, veuillez réessayer
      </Typography>
    );
  }

  return <ArticleEdit data={data!.article!} />;
};

const EmptyArticleWrapper: FC<RouteComponentProps<ArticleRouteProps>> = ({
  match,
}) => {
  const { articleType, brandKey } = match.params;
  const { data, loading, error } = useGetMainAuthor(auth.user?.id);

  const articleAuthors: any = data?.authors
    ? [
        {
          order: 1,
          author: data.authors[0],
        },
      ]
    : [];

  const articleData: GetArticle_article = {
    ...emptyArticle(brandKey, articleType),
    articleAuthors,
  };

  return <ArticleEdit data={articleData} loading={loading} error={error} />;
};

export const DuplicateArticleWrapper: FC<RouteComponentProps<
  ArticleRouteProps
>> = ({ match }) => {
  const classes = useStyles();
  const { id, brandKey } = match.params;
  const [articleData, setArticleData] = useState<GetArticle_article | null>(
    null,
  );

  const { pushNotification } = useNotification();
  const { data, loading, error } = useGetArticle(id);
  const { data: authorData, loading: authorLoading } = useGetMainAuthor(
    auth.user?.id,
  );

  if (data?.article && id && brandKey && pushNotification && !articleData) {
    const { lead, body, articleTags, brandKey: articleBrandKey } = data.article;

    const setNonTranslatedArticle = () =>
      setArticleData({
        ...emptyArticle(brandKey, 'article'),
        lead,
        body,
        articleTags,
      });

    const translateArticle = async () => {
      try {
        const { data: deeplApiResult } = await translateArticleQuery(
          id,
          getBrandLang(articleBrandKey),
          getBrandLang(brandKey),
        );

        if (deeplApiResult.translateArticle) {
          const {
            lead: translatedLead,
            body: translatedBody,
            title: translatedTitle,
          } = deeplApiResult.translateArticle;

          setArticleData({
            ...emptyArticle(brandKey, 'article'),
            lead: translatedLead,
            body: translatedBody,
            title: translatedTitle,
          });
        } else {
          setNonTranslatedArticle();
          pushNotification({
            type: NotificationTypeEnum.error,
            message: "Impossible de traduire l'article",
          });
        }
      } catch (e) {
        setNonTranslatedArticle();
        pushNotification({
          type: NotificationTypeEnum.error,
          message: "Impossible de traduire l'article",
        });
      }
    };

    if (getBrandLang(articleBrandKey) !== getBrandLang(brandKey)) {
      translateArticle();
    } else {
      setNonTranslatedArticle();
    }
  }

  if (loading || authorLoading || !auth.user || !articleData) {
    return (
      <div className={classes.centered}>
        <CircularProgress size={80} />
      </div>
    );
  }

  const { id: userId, name, avatarUrl } = auth.user;

  if (error || (data && !data.article)) {
    pushNotification({
      type: NotificationTypeEnum.error,
      message: "L'identifiant de l'article est introuvable.",
    });
    return <Redirect to={paths.ARTICLE_LIST} />;
  }

  const mainAuthor = authorData?.authors[0];

  if (
    mainAuthor &&
    articleData &&
    !articleData?.articleAuthors.some(
      (getAuthors) => getAuthors.author.id === mainAuthor.id,
    )
  ) {
    setArticleData({
      ...articleData,
      articleAuthors: [
        ...articleData.articleAuthors,
        {
          order: 1,
          __typename: 'ArticleAuthor',
          author: {
            __typename: 'Author',
            id: mainAuthor.id,
            name: mainAuthor.name,
            user: {
              __typename: 'User',
              id: userId,
              name,
              avatarUrl,
            },
          },
        },
      ],
    });
  }
  return <ArticleEdit data={articleData} loading={loading || authorLoading} />;
};
