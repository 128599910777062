import { Collections, Description, Restaurant } from '@mui/icons-material';
import {
  MEDIA_TYPES_LABEL,
  MultimediaPlugin,
} from '@prismamedia/one-components';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrandKey } from '../../../__generated__/queries-web';
import { useGetArticle } from '../../../apollo/queries/articles.web.graphql';
import { useSuggestArticleQuery } from '../../../apollo/queries/suggestArticles.web.graphql';
import { AdvancedSearch } from '../../AppBar/AdvancedSearch';
import { getItemToIframely, getItemToRender } from '../../MediaInput/utils';
import { searchByPluginType } from '../plugins/shared/searchArticleForMedia';

interface MultimediaPluginLinkProps {
  searchText?: string;
  brandKey: BrandKey;
  onLinkSelected: (link: any) => void;
  onClose: () => void;
}

export const MultimediaPluginLink: React.FC<MultimediaPluginLinkProps> = ({
  searchText,
  brandKey,
  onLinkSelected,
  onClose,
}) => {
  const [value, setValue] = useState<string>(searchText || '');
  const isRecipe = brandKey === BrandKey.FAC || brandKey === BrandKey.CAC;
  const { id } = useParams();
  const { data } = useGetArticle(id);

  const { suggestQuery } = useSuggestArticleQuery(data);

  const allowedMedias = useMemo(
    () => [
      {
        type: MEDIA_TYPES_LABEL.ARTICLES,
        Icon: Description,
        label: 'Article',
      },
      ...(isRecipe
        ? [
            {
              type: MEDIA_TYPES_LABEL.RECIPES,
              Icon: Restaurant,
              label: 'Recette',
            },
          ]
        : []),
      {
        type: MEDIA_TYPES_LABEL.SLIDESHOWS,
        Icon: Collections,
        label: 'Diapo',
      },
    ],
    [isRecipe],
  );

  const onSearch = React.useCallback(
    async (searchParams, pluginType, opts) =>
      await searchByPluginType({
        searchParams: {
          ...searchParams,
          search: value.length ? value : searchParams.search,
        },
        pluginType: pluginType || MEDIA_TYPES_LABEL.ARTICLES,
        opts,
        brandKey,
      }),
    [brandKey, value],
  );

  return (
    <MultimediaPlugin
      allowedMedias={allowedMedias}
      defaultActions={false}
      defaultText={searchText}
      advancedSearch={
        brandKey &&
        data?.article?.format && {
          component: AdvancedSearch,
          props: { brandKey, articleFormat: data.article.format },
        }
      }
      itemToIframely={async (item: any, pluginType?: string) =>
        (await getItemToIframely(
          item,
          pluginType || MEDIA_TYPES_LABEL.ARTICLES,
        ))!
      }
      itemToRender={(item: any, pluginType?: string) =>
        getItemToRender(
          brandKey,
          item,
          pluginType || MEDIA_TYPES_LABEL.ARTICLES,
        )
      }
      onClick={async (item: any) => {
        onLinkSelected(item);
      }}
      onClose={onClose}
      onSave={onLinkSelected}
      searchFn={async (search, opts, pluginType) => {
        setValue('');
        return onSearch(search, pluginType, opts);
      }}
      suggestArticlesFn={suggestQuery}
    />
  );
};
