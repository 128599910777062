import { FormControlLabel, IconButton, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Add, Delete } from '@mui/icons-material';
import curry from 'lodash/fp/curry';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  InputHTMLAttributes,
  SetStateAction,
} from 'react';
import AceEditor from 'react-ace';
import { EditorState } from './';
import { useStyles } from './styles';

// Ace imports should be declared last
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/webpack-resolver';

interface OrphanEditorProps {
  state: EditorState;
  setState: Dispatch<SetStateAction<EditorState>>;
}
export const OrphanEditor: FC<OrphanEditorProps> = ({ state, setState }) => {
  const classes = useStyles();

  const updateField = curry((field: keyof EditorState, value: any) => {
    setState({
      ...state,
      [field]: value,
    });
  });
  const sharedProps = {
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  const addMeta = () => {
    setState({
      ...state,
      metas: [...state.metas, ''],
    });
  };

  const removeMeta = () => {
    setState({
      ...state,
      metas: [...state.metas.slice(0, -1)],
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            inputProps={
              {
                'data-testid': 'is-fullscreen-btn',
              } as InputHTMLAttributes<HTMLInputElement>
            }
            checked={state.isFullScreen}
            onChange={() => updateField('isFullScreen', !state.isFullScreen)}
          />
        }
        label="Enlever le header/footer"
      />
      <FormControlLabel
        control={
          <Switch
            inputProps={
              {
                'data-testid': 'is-cmp-btn',
              } as InputHTMLAttributes<HTMLInputElement>
            }
            checked={state.isCMPEnabled}
            onChange={() => updateField('isCMPEnabled', !state.isCMPEnabled)}
          />
        }
        label="Activer le bandeau CMP"
      />
      <FormControlLabel
        control={
          <Switch
            inputProps={
              {
                'data-testid': 'is-ads-btn',
              } as InputHTMLAttributes<HTMLInputElement>
            }
            checked={!state.isAdsEnabled}
            onChange={() => updateField('isAdsEnabled', !state.isAdsEnabled)}
          />
        }
        label="Désactiver la pub"
      />
      <TextField
        {...sharedProps}
        className={classes.inputText}
        data-testid="title"
        label="Saisissez le titre :"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          updateField('title', event.currentTarget.value);
        }}
        value={state.title}
        variant="standard"
      />
      <TextField
        {...sharedProps}
        className={classes.inputText}
        data-testid="url"
        label="Saisissez l'url :"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          updateField('url', event.currentTarget.value);
        }}
        value={state.url}
        variant="standard"
      />

      <div className={classes.wrapperMetaButtons}>
        <IconButton
          data-testid="add-meta-btn"
          aria-label="Ajouter des métas"
          onClick={addMeta}
          size="large"
        >
          <Add color="inherit" />
        </IconButton>
        {state.metas.length > 0 && (
          <IconButton
            data-testid="add-meta-btn"
            aria-label="Supprimer des métas"
            onClick={removeMeta}
            size="large"
          >
            <Delete color="inherit" />
          </IconButton>
        )}
      </div>
      {state.metas.length > 0 &&
        state.metas.map((_el, index) => {
          return (
            <TextField
              {...sharedProps}
              className={classes.inputText}
              data-testid={`meta-${index}`}
              defaultValue={state.metas[index]}
              key={`meta-${index}`}
              label={`meta ${index + 1}`}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const newValue = [...state.metas];
                newValue[index] = event.target.value;
                updateField('metas', newValue);
              }}
              variant="standard"
            />
          );
        })}

      <AceEditor
        mode="html"
        theme="github"
        name="monokai_ace_orphan_page"
        value={state.content || ''}
        showPrintMargin={false}
        editorProps={{ $blockScrolling: true }}
        width="100%"
        onChange={updateField('content')}
      />
    </>
  );
};
