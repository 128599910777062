export const executePromisesSequentially = async <T>(
  promises: (() => Promise<T>)[],
) => {
  for (const promiseFn of promises) {
    try {
      const result = await promiseFn();
      return result;
    } catch (error) {
      console.error(error);
    }
  }
};
