import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { useMemo } from 'react';
import { useAuthorUpdater } from '../../../apollo/mutations/author.web.graphql';
import { AuthorContext } from './types';
import { pick as _pick } from 'ramda';

export const useEditAttributes = () => {
  const { pushNotification } = useNotification();
  const [updateAuthor] = useAuthorUpdater();

  const handlers = useMemo(
    () => ({
      handleAddAttribute: async (
        attributeId: string,
        order: number,
        authorContext: AuthorContext,
      ) => {
        const where = { id: authorContext.id };
        const queryParams = _pick(
          [
            'name',
            'userId',
            'googleId',
            'avatarUrl',
            'description',
            'facebookId',
            'twitterId',
            'instagramId',
            'linkedinId',
            'pinterestId',
            'snapshatId',
            'isMain',
            'hasPublicProfile',
          ],
          authorContext,
        );
        try {
          const result = await updateAuthor({
            variables: {
              data: {
                ...queryParams,
                authorAttributes: {
                  create: [
                    {
                      attribute: {
                        connect: {
                          id: attributeId,
                        },
                      },
                      order,
                    },
                  ],
                },
              },
              where,
            },
          });
          if (result) {
            pushNotification({
              type: NotificationTypeEnum.success,
              message: "L'attribut a été correctement ajouté !",
            });
          }
        } catch (error) {
          pushNotification({
            type: NotificationTypeEnum.error,
            message:
              'Une erreur est survenue, veuillez réessayer ultérieurement',
          });
        }
      },
      handleDeleteAttribute: async (
        attributeId: string,
        authorContext: AuthorContext,
      ) => {
        const where = { id: authorContext.id };
        const queryParams = _pick(
          [
            'name',
            'userId',
            'googleId',
            'avatarUrl',
            'description',
            'facebookId',
            'twitterId',
            'instagramId',
            'linkedinId',
            'pinterestId',
            'snapshatId',
            'isMain',
            'hasPublicProfile',
          ],
          authorContext,
        );
        try {
          const result = await updateAuthor({
            variables: {
              data: {
                ...queryParams,
                authorAttributes: {
                  delete: [
                    {
                      attribute: {
                        id: attributeId,
                      },
                    },
                  ],
                },
              },
              where,
            },
          });
          if (result) {
            pushNotification({
              type: NotificationTypeEnum.success,
              message: "L'attribut a été correctement supprimé !",
            });
          }
        } catch (error) {
          pushNotification({
            type: NotificationTypeEnum.error,
            message:
              'Une erreur est survenue, veuillez réessayer ultérieurement',
          });
        }
      },
    }),
    [pushNotification, updateAuthor],
  );

  return { handlers };
};
