import {
  AutocompleteItem,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import React, { FC } from 'react';
import { useAuthorsGetter } from '../../../../apollo/queries/authors.web.graphql';

interface AuthorSelectProps {
  value?: AutocompleteItem;
  onChange?: (value?: AutocompleteItem) => void;
  className?: string;
}

export const AuthorSelect: FC<AuthorSelectProps> = ({
  value,
  onChange,
  className,
}) => {
  const getAuthors = useAuthorsGetter();

  const fetchList = (search: string) =>
    getAuthors({
      where: { name_contains: search },
      first: 20,
      skip: 0,
    }).then(({ authors }) =>
      authors?.map(({ id, name }) => ({ id, label: name || '' })),
    );

  return (
    <AutocompleteSelect
      label="Auteur"
      value={value}
      className={className}
      fetchList={fetchList}
      onChange={onChange}
      mandatoryInput
    />
  );
};
