import { fetchData } from '@prismamedia/one-components';
import { executePromisesSequentially } from './executePromises';

interface openAiResponse {
  choices: {
    message: {
      content: string;
    };
  }[];
}

const gpt4TurboUrl =
  'https://pm-poc-openai.openai.azure.com/openai/deployments/pm-poc-gpt4-turbo/chat/completions?api-version=2023-05-15';
const gpt4Url =
  'https://pm-poc-openai.openai.azure.com/openai/deployments/pm-poc-gpt4/chat/completions?api-version=2023-05-15';
const gpt3TurboUrl =
  'https://pm-poc-openai.openai.azure.com/openai/deployments/pm-poc-gpt3_5-turbo/chat/completions?api-version=2023-05-15';

const payload = (content: string, max_tokens: number) => ({
  method: 'POST',
  headers: {
    'api-key': 'af4c94fbe28b42db92076d9d5a015a8e',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    messages: [{ role: 'user', content }],
    max_tokens,
  }),
});
export const getClickbait = async (content: string) => {
  const result = await executePromisesSequentially<openAiResponse>([
    () => fetchData(gpt4TurboUrl, payload(content, 4096)),
    () => fetchData(gpt4Url, payload(content, 4096)),
    () => fetchData(gpt3TurboUrl, payload(content, 4096)),
  ]);

  return result
    ? result.choices[0].message.content.replace(/"/g, '')
    : "Erreur: La suggestion n'a pas pu etre générée.";
};
