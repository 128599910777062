import { CircularProgress, Dialog } from '@mui/material';
import { dissoc } from 'ramda';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useGetAuthor } from '../../../apollo/queries/authors.web.graphql';
import { GetAuthors_authors } from '../../../__generated__/queries-web';
import { EditContent } from './EditContent';
import { AuthorContext, initialState } from './types';

interface FormDialogProps {
  isOpen: boolean;
  isFullScreen: boolean;
  shouldUseDebounce?: boolean;
  author?: AuthorContext;
  searchId?: string;
  handleClose: () => void;
  title?: string;
  setAuthor: Dispatch<SetStateAction<GetAuthors_authors[] | []>>;
}
export const FormDialog: FC<FormDialogProps> = ({
  isFullScreen,
  isOpen,
  handleClose,
  author,
  shouldUseDebounce = true,
  searchId,
  title = 'Modifier un auteur',
  setAuthor,
}) => {
  let authorToDisplay = author || initialState;
  let loadingMessage;
  const { loading, data } = useGetAuthor(searchId || '');

  if (loading) {
    loadingMessage = (
      <div data-testid="waitingIcon">
        <CircularProgress />
      </div>
    );
  }
  if (data && data.author) {
    authorToDisplay = dissoc('__typename', data.author);
  }

  return (
    <Dialog fullScreen={isFullScreen} open={isOpen} onClose={handleClose}>
      {loadingMessage || (
        <EditContent
          shouldUseDebounce={shouldUseDebounce}
          author={authorToDisplay}
          handleClose={handleClose}
          title={title}
          setAuthor={setAuthor}
        />
      )}
    </Dialog>
  );
};
