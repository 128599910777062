import { gql, useQuery } from '@apollo/client';
import { useFetch } from '../../utils/useFetch';
import {
  AuthorOrderByInput,
  GetAuthor,
  GetAuthors,
  GetAuthorsVariables,
  GetAuthorVariables,
} from '../../__generated__/queries-web';
import { AUTHOR_FRAGMENT } from '../fragments/author.web.graphql';

const GET_AUTHORS = gql`
  query GetAuthors(
    $first: Int!
    $where: AuthorWhereInput
    $skip: Int
    $orderBy: [AuthorOrderByInput!]
  ) {
    authors(first: $first, where: $where, skip: $skip, orderBy: $orderBy) {
      ...AuthorFragment
    }
  }
  ${AUTHOR_FRAGMENT}
`;

export const useAuthorsGetter = () =>
  useFetch<GetAuthors, GetAuthorsVariables>(GET_AUTHORS);

const GET_AUTHOR = gql`
  query GetAuthor($where: AuthorWhereUniqueInput!) {
    author(where: $where) {
      ...AuthorFragment
    }
  }
  ${AUTHOR_FRAGMENT}
`;

export const useGetAuthor = (id: string | undefined) =>
  useQuery<GetAuthor, GetAuthorVariables>(GET_AUTHOR, {
    variables: { where: { id } },
    skip: !id,
  });

export const useAuthorGetter = () =>
  useFetch<GetAuthor, GetAuthorVariables>(GET_AUTHOR);

export const useGetMainAuthor = (userId?: string) =>
  useQuery<GetAuthors, GetAuthorsVariables>(GET_AUTHORS, {
    variables: {
      first: 1,
      where: { userId, isMain: true },
      orderBy: [AuthorOrderByInput.name_ASC],
    },
  });
