import { Box, Grid, Typography } from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteMode,
  SearchBar,
  SearchBarVariant,
  SearchParams,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useRef, useState } from 'react';
import { TagsProps } from '../../';
import { useSearchEnabledTagsGetter } from '../../../../apollo/queries/getEnabledTags.web.graphql';
import { useSearchSuggestedTagsGetter } from '../../../../apollo/queries/getSuggestedTagsList.web.graphql';
import {
  BrandKey,
  GetArticle_article_articleTags_tag,
  GetSuggestedTagsList_suggestTags,
  GetSuggestedTagsList_suggestTags_items,
  TagProviderName,
  TagSearchTypes,
  TagType,
} from '../../../../__generated__/queries-web';
import { TagItem } from '../../TagItem';
import { useEditableTag } from '../../useEditTag';
import {
  AutocompleteListHeader,
  getSuggestedTagsArticleParams,
  getTagsAutoCompleteList,
} from '../../utils';
import { useStyles as useSharedStyles } from '../styles';
import { AdvancedSearch } from './AdvancedSearch';
import {
  ALLOWED_TVBY_TYPES,
  ARTICLE_ENHANCEMENT_PROGRAMS_TITLE,
  NO_PROGRAM_SELECTED_TITLE,
  PROGRAM_SEARCH_BAR_PLACEHOLDER,
  PROVIDER_LABEL,
  SEARCH_BY_PROVIDER,
} from './constants';

export const getCheckedTypesList = (
  searchParams: SearchParams,
): TagSearchTypes[] =>
  Object.keys(searchParams).reduce(
    (acc: TagSearchTypes[], currValue: string) => {
      const isProviderAllowed =
        searchParams[currValue] === true &&
        ALLOWED_TVBY_TYPES.indexOf(currValue as TagSearchTypes) > -1;

      if (isProviderAllowed) {
        acc.push(currValue as TagSearchTypes);
      }

      return acc;
    },
    [],
  );

interface ProgramsProps extends Pick<TagsProps, 'article' | 'onTagsChange'> {
  brandKey: BrandKey;
  field: string;
  suggestedTagList: GetSuggestedTagsList_suggestTags[];
  tags: GetArticle_article_articleTags_tag[];
}

const Programs: FC<ProgramsProps> = ({
  article,
  brandKey,
  field,
  onTagsChange,
  tags: selectedTags,
}) => {
  const isFirstRender = useRef(true);
  const searchTags = useSearchEnabledTagsGetter();
  const searchSuggestedTags = useSearchSuggestedTagsGetter();

  const {
    newTags,
    handlers: { handleAddTag, handleDeleteTag },
  } = useEditableTag<GetArticle_article_articleTags_tag>(
    selectedTags,
    brandKey,
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
    ...ALLOWED_TVBY_TYPES.reduce((acc, currValue) => {
      acc[currValue] = true;
      return acc;
    }, {} as Partial<SearchParams>),
  });
  const [filteredSuggestedTagList, setFilteredSuggestedTagList] = useState<
    GetSuggestedTagsList_suggestTags_items[]
  >([]);

  const selectedProgramList = selectedTags.filter(({ type }) => {
    return type === TagType.TvProgram;
  });

  const allowedTypes = getCheckedTypesList(searchParams);
  const allowedProgramProviders: TagProviderName[] = allowedTypes.map(
    (type: TagSearchTypes) => {
      switch (type) {
        case TagSearchTypes.Broadcast:
          return TagProviderName.TvProgram;
        case TagSearchTypes.Cinema:
          return TagProviderName.TvProgram;
        case TagSearchTypes.VOD:
          return TagProviderName.TvProgram;
        case TagSearchTypes.Collection:
          return TagProviderName.TvCollection;
      }
    },
  );

  const sharedClasses = useSharedStyles({
    selectedTagListLength: selectedProgramList.length,
  });

  const getFilteredProgramList = async (
    search: string,
  ): Promise<AutocompleteItem[] | undefined> => {
    setSearchParams((currSearchParams) => ({
      ...currSearchParams,
      search,
    }));

    if (!search.length) {
      // Get suggested programs tags
      return searchSuggestedTags({
        brandKey,
        article: getSuggestedTagsArticleParams(article),
        providers: allowedProgramProviders,
      }).then((data) => {
        const tagList = data?.items || [];
        setFilteredSuggestedTagList(tagList);

        return getTagsAutoCompleteList(tagList, selectedTags);
      });
    }

    // Get programs tags based on field value
    return searchTags({
      providers: [TagProviderName.TvBy],
      brandKey,
      search,
      types: allowedTypes,
    }).then((sortedTagList = []) =>
      getTagsAutoCompleteList(sortedTagList, selectedTags),
    );
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    newTags && onTagsChange(newTags, field);
  }, [field, newTags, onTagsChange]);

  return (
    <Grid
      alignItems="flex-end"
      className={sharedClasses.wrapper}
      container
      justifyContent="space-between"
    >
      <Grid item xs={4}>
        <Typography className={sharedClasses.subTitle} component="h3">
          {ARTICLE_ENHANCEMENT_PROGRAMS_TITLE}{' '}
          <span>({selectedProgramList.length})</span>
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <SearchBar
          advancedSearch={{
            component: AdvancedSearch,
            title: `${SEARCH_BY_PROVIDER} :`,
          }}
          autocomplete={{
            fetchList: getFilteredProgramList,
            listHeader: !searchParams?.search?.length ? (
              <AutocompleteListHeader
                listLength={filteredSuggestedTagList.length}
              />
            ) : undefined,
            mode: AutocompleteMode.MULTIPLE,
            onSelect: ({ additionnals }) => {
              additionnals && handleAddTag(additionnals);
            },
          }}
          chipAdvancedParamLabel={PROVIDER_LABEL}
          fetchListMinValueLength={0}
          placeholder={PROGRAM_SEARCH_BAR_PLACEHOLDER}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          variant={SearchBarVariant.SECONDARY}
        />
      </Grid>
      <Box className={sharedClasses.innerWrapper} component="section">
        {selectedProgramList.map((program) => (
          <TagItem
            {...program}
            key={program.id || program.title}
            onDelete={handleDeleteTag}
          />
        ))}

        {!selectedProgramList.length && (
          <Typography component="p">{NO_PROGRAM_SELECTED_TITLE}</Typography>
        )}
      </Box>
    </Grid>
  );
};

export { Programs };
