import { CircularProgress } from '@mui/material';
import { ImageLoader, ImageLoaderFallback } from '@prismamedia/one-components';
import React, { FC, Fragment, useEffect } from 'react';
import { useSearchPeopleBio } from '../../../../../../apollo/queries/getPeopleBio.people.graphql';
import { ArticleState } from '../../../../../../pages/ArticleEdit/reducer';
import { TagType } from '../../../../../../__generated__/queries-web';
import { TagIcons } from '../../../../TagItem/Thumbnail/constants';
import { DragAndDropItem } from '../../../../../DragAndDrop';
import { useStyles } from './styles';
import {
  BrandKey,
  GetSearchPeopleBio_people_biographies,
} from '../../../../../../__generated__/queries-people';

const IMAGE_REQUEST_TIMEOUT = 3000;

interface Thumbnails {
  src: string | null;
  srcList: ImageLoaderFallback[];
}

const defaultThumbnails = {
  src: null,
  srcList: [],
};

interface ThumbnailProps
  extends Partial<DragAndDropItem>,
    Pick<ArticleState, 'brandKey'> {
  isDragging: boolean | undefined;
  isOverlay: boolean;
}
const TagThumbnail: FC<ThumbnailProps> = ({
  brandKey,
  isDragging,
  isOverlay,
  relationId,
  type,
}) => {
  const { loading, data } = useSearchPeopleBio(
    !isOverlay && type === TagType.Person ? relationId : null,
    (brandKey as unknown) as BrandKey,
  );
  const [thumbnails, setThumbnails] = React.useState<Thumbnails>(
    defaultThumbnails,
  );

  const classes = useStyles({ isDragging });
  const Icon = TagIcons[type as TagType] || Fragment;

  useEffect((): void => {
    if (data?.people[0]?.biographies) {
      const srcList = data?.people[0]?.biographies.reduce(
        (
          thumbnailList: Pick<ImageLoaderFallback, 'src'>[],
          peopleBio: GetSearchPeopleBio_people_biographies,
        ) => {
          if (peopleBio.media) {
            const peopleBioThumbnails =
              (peopleBio.media?.iframely
                ? peopleBio.media.iframely.links.thumbnail
                : peopleBio.media?.links?.thumbnail) || [];

            if (peopleBioThumbnails.length) {
              const thumbnailsHrefs = peopleBioThumbnails.map(
                (thumbnail: Record<'href', string>) => ({
                  src: thumbnail.href,
                  timeout: IMAGE_REQUEST_TIMEOUT,
                }),
              );

              thumbnailList.push(...thumbnailsHrefs);
            }
          }

          return thumbnailList;
        },
        [],
      );

      if (srcList.length) {
        setThumbnails({
          src: srcList[0].src || null,
          srcList: srcList.slice(1),
        });
      }
    }
  }, [data, brandKey]);

  return loading ? (
    <div className={classes.iconWrapper}>
      <CircularProgress size={35} />
    </div>
  ) : (
    <ImageLoader
      className={classes.iconWrapper}
      fallbacks={thumbnails.srcList}
      fitting="cover"
      image={<Icon className={classes.icon} />}
      loaderSize={35}
      src={thumbnails.src}
      timeout={IMAGE_REQUEST_TIMEOUT}
    />
  );
};

export { TagThumbnail };
