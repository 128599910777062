import { useMutation, useQuery } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import {
  getPush,
  getPushVariables,
  getPush_pushes,
  getPush_pushes_pushSegments,
  updatePush,
  updatePushVariables,
} from '../../../__generated__/queries-web';
import { formatDate } from '../../../utils/dateUtils';
import { CANCEL_PUSH } from '../cancelPush.web.graphql';
import { MobilePushStatusMapper } from '../types';
import { PUSH_HISTORY } from './pushHistory.web.graphql';
import { useStyles } from './styles';

interface HistoryWrapperProps {
  articleId: string | null | undefined;
}

interface HistoryProps {
  pushes: getPush_pushes[];
}

const getSegments = (segments: getPush_pushes_pushSegments[]) => {
  return segments.length
    ? segments.reduce((acc, val) => {
        acc = acc.concat(`${val.segment.title}, `);
        return acc;
      }, '')
    : '-';
};

const PushHistory: FC<HistoryProps> = ({ pushes }) => {
  const classes = useStyles();
  const [cancelPushMutation] = useMutation<updatePush, updatePushVariables>(
    CANCEL_PUSH,
    { refetchQueries: [PUSH_HISTORY] },
  );
  const cancelPushHandler = async (id: string) => {
    await cancelPushMutation({
      variables: {
        where: {
          id,
        },
        data: {
          isCancelled: true,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h3">
        Programmation et historique
      </Typography>
      {pushes.length === 0 ? (
        <Typography>
          Pas de push programmé ou envoyé pour cet article
        </Typography>
      ) : (
        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Heure</TableCell>
                <TableCell align="center">Utilisateur</TableCell>
                <TableCell align="center">Segments</TableCell>
                <TableCell align="center">Device</TableCell>
                <TableCell align="center">Statut</TableCell>
                <TableCell align="center">Annuler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pushes.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    size="medium"
                  >
                    {row.scheduledAt
                      ? formatDate(row.scheduledAt, 'd MMMM')
                      : `no date available`}
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    size="medium"
                  >
                    {row.scheduledAt
                      ? formatDate(row.scheduledAt, 'H:mm')
                      : `no date available`}
                  </TableCell>
                  <TableCell align="center" size="medium">
                    {row?.user?.name}
                  </TableCell>
                  <TableCell align="center" size="medium">
                    {getSegments(row.pushSegments)}
                  </TableCell>
                  <TableCell align="center" size="medium">
                    {['ea', 'editorialAlert'].includes(row.targetDevice)
                      ? 'alerte web'
                      : row.targetDevice}
                  </TableCell>
                  <TableCell align="center" size="medium">
                    {row.isCancelled
                      ? 'annulé'
                      : row.sentAt
                      ? MobilePushStatusMapper.SENT
                      : row.scheduledAt
                      ? MobilePushStatusMapper.SCHEDULED
                      : '-'}
                  </TableCell>
                  <TableCell
                    align="center"
                    size="medium"
                    onClick={() => cancelPushHandler(row.id)}
                  >
                    <Tooltip title="Annuler ce push">
                      <DeleteForeverIcon
                        color={
                          row.isCancelled || row.sentAt ? 'disabled' : 'inherit'
                        }
                        className={classes.deleteIcon}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
};

export const PushHistoryWrapper: FC<HistoryWrapperProps> = ({ articleId }) => {
  const { error, data } = useQuery<getPush, getPushVariables>(PUSH_HISTORY, {
    variables: {
      first: 20,
      where: {
        article: {
          id: articleId,
        },
      },
    },
    fetchPolicy: 'network-only',
  });
  if (error) {
    console.error(error);
    return <p>something went wrong while fetching push history : </p>;
  }
  if (!data || !data.pushes) {
    return null;
  }
  return <PushHistory pushes={data.pushes} />;
};
