import { ApolloQueryResult } from '@apollo/client';
import { GetRawArticle_rawArticle } from '../../../__generated__/queries-topic';
import {
  ArticleFormat,
  BrandKey,
  CategoryFormat,
  GetArticle_article,
  GetArticle_article_articleCategories,
  GetArticle_article_articleCategories_category,
  GetArticle_article_articleTags,
  GetArticle_article_articleTags_tag,
  GetSuggestedCategory,
  GetSuggestedCategory_suggestCategories_items,
  GetSuggestedTagsList,
  TagProviderName,
  TagType,
} from '../../../__generated__/queries-web';
import { useSearchSuggestedTagsGetterPromise } from '../../../apollo/queries/getSuggestedTagsList.web.graphql';
import { useSuggestedCategoriesGetter } from '../../../apollo/queries/suggestCategories.web.graphql';
import { useRawArticleGetter } from '../queries/getSubjectQuery.topic.graphql';

import { brandsOptions } from '../../../utils/brands';
import { createArticleData } from '../utils/emptyTypes';

const mapOrderedRelationEntity = <T, E, U>({
  from,
  to,
  items,
  top,
  map,
}: {
  from: string;
  to: string;
  items: T[];
  top: number;
  map: (item: T) => Partial<U>;
}) =>
  items.slice(0, top).map(
    (item, order) =>
      (({
        __typename: `${from}${to}`,
        order,
        [to.toLowerCase()]: ({
          __typename: to,
          ...map(item),
        } as any) as U,
      } as any) as E),
  );

const getTopSuggestTags = <
  T extends { id: string | null; title: string; type: TagType }
>(
  items: T[] = [],
  top = 5,
) =>
  mapOrderedRelationEntity<
    T,
    GetArticle_article_articleTags,
    GetArticle_article_articleTags_tag
  >({
    items,
    from: 'Article',
    to: 'Tag',
    top,
    map: (item) => ({
      id: item.id!,
      title: item.title,
      type: item.type,
    }),
  });

async function assertQueryResult<
  T extends ApolloQueryResult<any> | PromiseLike<ApolloQueryResult<any>>
>(r: T): Promise<PromiseOf<T>['data']> {
  const { errors = [], data } = await Promise.resolve(r);
  if (errors.length) {
    throw errors[0];
  } else {
    return data;
  }
}

const assertRawArticle = (
  id: string,
  article: any,
): NonNullableKeys<GetRawArticle_rawArticle> => {
  if (!article) {
    throw Error(`Il n'existe pas un article avec l'identifiant ${id}.`);
  }

  if (!article.assignment) {
    throw Error(`Un probleme est détecté avec cet article.`);
  }

  return article;
};

const initWebArticleEntity = (
  articleType: string,
  brandKey: BrandKey,
  rawArticle: NonNullableKeys<GetRawArticle_rawArticle>,
) => {
  const {
    versions: [{ body = '', title = '', lead = '' }],
    assignment: { id: assignmentId },
  } = rawArticle;
  return createArticleData(assignmentId, articleType, {
    brandKey,
    body,
    title,
    lead,
  });
};

export const useDuplicateArticle = async ({
  rawArticleId,
  versionId,
  articleType,
  brandKey,
}: {
  rawArticleId: string;
  versionId: string;
  articleType: ArticleFormat;
  brandKey: BrandKey;
}): Promise<{
  sourceArticle: NonNullableKeys<GetRawArticle_rawArticle>;
  webArticle: GetArticle_article;
}> => {
  const getRawArticle = useRawArticleGetter();
  const getSuggestedTags = useSearchSuggestedTagsGetterPromise();
  const getSuggestedCategories = useSuggestedCategoriesGetter();

  const { rawArticle } = await assertQueryResult(
    getRawArticle(rawArticleId, versionId),
  );

  const sourceArticle = assertRawArticle(rawArticleId, rawArticle);
  const webArticle = initWebArticleEntity(articleType, brandKey, sourceArticle);

  if (!(webArticle.title || webArticle.lead || webArticle.body))
    throw Error(
      "Le contenu de l'article est vide, veuillez sélectionner un autre article",
    );

  const suggestionQueries = [
    getSuggestedTags({
      brandKey: webArticle.brandKey,
      article: webArticle,
      providers: [TagProviderName.Tag],
    }),
    getSuggestedTags({
      brandKey: webArticle.brandKey,
      article: webArticle,
      providers: [TagProviderName.Person],
    }),
    ...[CategoryFormat.Category, CategoryFormat.Event].map((categoryFormat) =>
      getSuggestedCategories({
        search: {
          article: webArticle,
          first: 2,
          threshold: 0.7,
        },
        categoryFormat,
      }),
    ),
  ].map(assertQueryResult);

  const [
    { suggestTags },
    { suggestTags: suggestPersonTags },
    { suggestCategories },
    { suggestCategories: suggestEvents },
  ] = await Promise.all<
    [
      GetSuggestedTagsList,
      GetSuggestedTagsList,
      GetSuggestedCategory,
      GetSuggestedCategory,
    ]
  >(suggestionQueries as any);

  const articleTags: GetArticle_article_articleTags[] = [
    ...getTopSuggestTags(suggestTags?.items, 3),
    ...getTopSuggestTags(suggestPersonTags?.items, 1),
    ...getTopSuggestTags(
      suggestEvents.items.map((e) => ({ ...e, type: 'Event' as TagType })),
      1,
    ),
  ];

  const topCategoriesCount =
    Number(
      !!brandsOptions[brandKey].draft?.richFields.find(
        (o) => o === 'firstCategory',
      ),
    ) +
    Number(
      !!brandsOptions[brandKey].draft?.richFields.find(
        (o) => o === 'secondCategory',
      ),
    );

  const articleCategories = mapOrderedRelationEntity<
    GetSuggestedCategory_suggestCategories_items,
    GetArticle_article_articleCategories,
    GetArticle_article_articleCategories_category
  >({
    items: suggestCategories.items,
    from: 'Article',
    to: 'Category',
    top: topCategoriesCount,
    map: ({ id, title }) => ({
      id,
      title,
    }),
  });

  return {
    sourceArticle,
    webArticle: {
      ...webArticle,
      articleTags,
      articleCategories,
    },
  };
};
