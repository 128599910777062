import { ExpandMore, InfoOutlined, FileCopy } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Box,
  DialogContent,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Loader, pluralize } from '@prismamedia/one-components';
import { grey } from '@mui/material/colors';
import {
  GetArticle_article_articleHistories,
  GetArticle_article_parentArticle,
  GetArticle_article_articleHistories_user,
  ArticleStatus,
} from '../../../../../../__generated__/queries-web';
import uuidv4 from 'uuid/v4';

type HistoryStatusType =
  | 'Publiée'
  | 'Programmée'
  | 'Prêt'
  | 'Gelé'
  | 'Brouillon'
  | 'Corbeille'
  | 'En traitement';

type ArticleStatusType = keyof typeof ArticleStatus;

const articleStatusLabel: Partial<Record<ArticleStatus, HistoryStatusType>> = {
  Published: 'Publiée',
  Scheduled: 'Programmée',
  Ready: 'Prêt',
  Frozen: 'Gelé',
  Draft: 'Brouillon',
  Deleted: 'Corbeille',
  Pending: 'En traitement',
};

interface ResultHistoryType {
  id: string;
  title: string;
  status: HistoryStatusType;
  users: {
    id: string;
    user: GetArticle_article_articleHistories_user | null;
    updatedAt: string;
  }[];
}

export const ArticleHistory = ({
  histories,
  historyCount,
  loading,
  parentArticle,
}: {
  histories: GetArticle_article_articleHistories[];
  historyCount?: number;
  loading?: boolean;
  parentArticle?: GetArticle_article_parentArticle[];
}) => {
  const resultHistory = histories.reduce(
    (acc: ResultHistoryType[], item, index) => {
      const currentStatus =
        articleStatusLabel[item.status as ArticleStatusType];
      const preventItem = histories[index - 1];

      if (
        currentStatus ===
        articleStatusLabel[preventItem?.status as ArticleStatusType]
      ) {
        acc[acc.length - 1].users.push({
          id: uuidv4(),
          user: preventItem.user,
          updatedAt: preventItem.updatedAt,
        });
      } else {
        currentStatus &&
          acc.push({
            id: uuidv4(),
            title: `${currentStatus} par ${item.user?.name} - ${format(
              new Date(item.updatedAt),
              'dd/MM/yyyy à HH:mm',
              { locale: fr },
            )}`,
            status: currentStatus,
            users: [
              {
                id: uuidv4(),
                user: item.user,
                updatedAt: item.updatedAt,
              },
            ],
          });
      }

      return acc;
    },
    [],
  );

  return (
    <>
      <AppBar
        sx={{
          zIndex: 2,
          '& h6': {
            marginLeft: 1,
          },
        }}
        color="inherit"
        position="static"
      >
        <Toolbar>
          <Typography variant="h6">Historique des modifications</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ backgroundColor: grey[200], maxHeight: '60vh' }}>
        <Box
          sx={{
            padding: [2, 1],
            '& table tr': {
              '& svg': {
                marginRight: 1,
                position: 'relative',
                top: 0.5,
                transform: 'rotate(-90deg)',
              },

              '& th': {
                fontSize: '1rem',
                border: 'none',
                padding: 1,

                '&:last-child': {
                  fontWeight: 500,
                },
              },
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              {parentArticle?.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                    marginBottom: 3,
                  }}
                >
                  <FileCopy
                    sx={{
                      position: 'relative',
                      top: '-1px',
                    }}
                  />
                  <Typography>
                    Cet article est une duplication de:&nbsp;
                    <a
                      rel="noreferrer"
                      href={`/#/${parentArticle[0].article.brandKey}/article/edit/${parentArticle[0].article.id}`}
                      target="_blank"
                    >
                      voir article
                    </a>
                  </Typography>
                </Box>
              ) : null}
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: 0.5,
                  marginBottom: 3,
                }}
              >
                <InfoOutlined
                  color="primary"
                  sx={{
                    position: 'relative',
                    top: '-1px',
                  }}
                />
                <Typography>
                  {historyCount} {pluralize('modification', historyCount)}{' '}
                  {pluralize('réalisée', historyCount)} sur cet article
                </Typography>
              </Box>

              {resultHistory.map((history) => {
                return (
                  <Accordion
                    sx={{
                      '.MuiAccordionSummary-root': {
                        boxShadow: 'none',
                      },
                    }}
                    key={history.id}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Tooltip
                          enterDelay={350}
                          placement="top"
                          title="Détails"
                        >
                          <ExpandMore />
                        </Tooltip>
                      }
                      sx={{
                        ...{
                          '.MuiAccordionSummary-content': {
                            '& > div': {
                              flex: '1 auto',
                              display: 'flex',
                              alignItems: 'center',

                              '& > p': {
                                marginLeft: 1.5,
                                fontWeight: 500,
                              },
                            },

                            '& > p:last-child': {
                              color: grey[500],
                              fontSize: 'typography.fontSize',
                              marginRight: 3,
                            },
                          },
                        },
                      }}
                    >
                      <Box>
                        <Typography>{history.title}</Typography>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      sx={{
                        '.MuiAccordionDetails-root': {
                          borderTop: `dashed 1px ${grey[200]}`,
                          padding: 2,
                        },
                      }}
                    >
                      {history.users.map((user) => {
                        return (
                          <Box
                            key={user.id}
                            sx={{ display: 'flex', padding: '10px' }}
                          >
                            <Avatar
                              sx={{ width: 24, height: 24 }}
                              src={user?.user?.avatarUrl || undefined}
                            />
                            <Typography sx={{ marginX: '10px' }}>
                              {user?.user?.name || user?.user?.id}
                            </Typography>
                            <Typography>
                              {format(
                                new Date(user.updatedAt),
                                'dd/MM/yyyy à HH:mm',
                                { locale: fr },
                              )}
                            </Typography>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </Box>
      </DialogContent>
    </>
  );
};
