import {
  ActionStyle,
  ActionType,
  Anchor,
  ClickableLink,
  DraftAnchorIcon,
  DraftBlockquoteIcon,
  DraftBoldIcon,
  DraftCopyIcon,
  DraftHeadlineIcon,
  DraftItalicIcon,
  DraftLinkIcon,
  DraftListIcon,
  DraftOrderedListIcon,
  DraftStrikeThroughIcon,
  DraftTitleIcon,
  DraftUnLinkIcon,
  SmartLink,
  StylableLink,
  anchorsFromEditorState,
  draftActions,
} from '@prismamedia/one-components';
import { DraftDecorator, EditorState } from 'draft-js';
import copy from 'copy-to-clipboard';
import { createTypeStrategy } from 'megadraft';
import { ComponentType, FC } from 'react';
import { defaultTheme } from '../../muiThemes';
import { DraftInternalLinkIcon } from './icons';
import { InternalLink } from './InternalLink';
import { LinkInput as PersonLink } from './LinkInput';

enum PossibleCustomActions {
  COPY_ANCHORS = 'COPY_ANCHORS',
}

export enum PossibleActions {
  ANCHOR_LINK = 'ANCHOR_LINK',
  BLOCKQUOTE = 'BLOCKQUOTE',
  BOLD = 'BOLD',
  INTERNAL_LINK = 'INTERNAL_LINK',
  ITALIC = 'ITALIC',
  LINK = 'LINK',
  LIST = 'LIST',
  ORDERED_LIST = 'ORDERED_LIST',
  PERSON_LINK = 'PERSON_LINK',
  SEPARATOR = 'SEPARATOR',
  STRIKETHROUGH = 'STRIKETHROUGH',
  TITLE = 'TITLE',
  HEADLINE = 'HEADLINE',
  PASTE = 'PASTE',
}

export interface DraftActionType {
  action: {
    type: ActionType;
    label?: string;
    style?: ActionStyle;
    icon?: FC;
    entity?: string;
    action?: (
      editorState: EditorState,
      callback: (editorState: EditorState) => void,
    ) => void;
  };
  entity?: Record<string, ComponentType<any>>;
  decorator?: DraftDecorator;
}

export const actions: Record<PossibleActions, DraftActionType> = {
  [PossibleActions.ANCHOR_LINK]: {
    action: {
      type: ActionType.entity,
      label: 'Link',
      style: ActionStyle.LINK,
      entity: 'ANCHOR',
      icon: DraftAnchorIcon,
    },
    entity: {
      ANCHOR: Anchor,
    },
    decorator: {
      strategy: createTypeStrategy('ANCHOR'),
      component: StylableLink({ color: 'green' }),
    },
  },
  [PossibleActions.BLOCKQUOTE]: {
    action: {
      type: ActionType.block,
      label: 'QT',
      style: ActionStyle.BLOCKQUOTE,
      icon: DraftBlockquoteIcon,
    },
  },
  [PossibleActions.BOLD]: {
    action: {
      type: ActionType.inline,
      label: 'B',
      style: ActionStyle.BOLD,
      icon: DraftBoldIcon,
    },
  },
  [PossibleActions.INTERNAL_LINK]: {
    action: {
      type: ActionType.entity,
      label: 'InternalLink',
      style: ActionStyle.LINK,
      entity: 'INTERNAL_LINK',
      icon: DraftInternalLinkIcon,
    },
    entity: {
      INTERNAL_LINK: InternalLink,
    },
    decorator: {
      strategy: createTypeStrategy('INTERNAL_LINK'),
      component: StylableLink(),
    },
  },
  [PossibleActions.LINK]: {
    action: {
      type: ActionType.entity,
      label: 'Link',
      style: ActionStyle.LINK,
      entity: 'LINK',
      icon: DraftLinkIcon,
    },
    entity: {
      LINK: SmartLink,
    },
    decorator: {
      strategy: createTypeStrategy('LINK'),
      component: ClickableLink,
    },
  },
  [PossibleActions.PERSON_LINK]: {
    action: {
      type: ActionType.entity,
      label: 'PersonLink',
      style: ActionStyle.LINK,
      entity: 'PERSON_LINK',
      icon: DraftUnLinkIcon,
    },
    entity: {
      PERSON_LINK: PersonLink,
    },
    decorator: {
      strategy: createTypeStrategy('PERSON_LINK'),
      component: StylableLink({ color: defaultTheme.palette.primary.main }),
    },
  },
  [PossibleActions.ITALIC]: {
    action: {
      type: ActionType.inline,
      label: 'I',
      style: ActionStyle.ITALIC,
      icon: DraftItalicIcon,
    },
  },
  [PossibleActions.LIST]: {
    action: {
      type: ActionType.block,
      label: 'UL',
      style: ActionStyle.UNORDERED_LIST,
      icon: DraftListIcon,
    },
  },
  [PossibleActions.ORDERED_LIST]: {
    action: {
      type: ActionType.block,
      label: 'OL',
      style: ActionStyle.ORDERED_LIST,
      icon: DraftOrderedListIcon,
    },
  },
  [PossibleActions.SEPARATOR]: {
    action: {
      type: ActionType.separator,
    },
  },
  [PossibleActions.STRIKETHROUGH]: {
    action: {
      type: ActionType.inline,
      label: 'STRIKETHROUGH',
      style: ActionStyle.STRIKETHROUGH,
      icon: DraftStrikeThroughIcon,
    },
  },
  [PossibleActions.TITLE]: {
    action: {
      type: ActionType.block,
      label: 'H2',
      style: ActionStyle.TITLE,
      icon: DraftTitleIcon,
    },
  },
  [PossibleActions.HEADLINE]: {
    action: {
      type: ActionType.block,
      label: 'H3',
      style: ActionStyle.HEADLINE,
      icon: DraftHeadlineIcon,
    },
  },
  [PossibleActions.PASTE]: draftActions.PASTE,
};

export const actionsCustom: Record<
  PossibleCustomActions,
  (...args: string[]) => DraftActionType
> = {
  [PossibleCustomActions.COPY_ANCHORS]: (url: string) => {
    return {
      action: {
        type: ActionType.custom,
        label: 'Copy Summary',
        icon: DraftCopyIcon,
        action: (editorState) => {
          const anchors = anchorsFromEditorState(editorState).toArray();
          copy(
            `
          ${anchors
            .map(
              (anchor) =>
                `<li><a className="editor__link" href="${
                  url.slice(-1) === '/' ? url.slice(0, -1) : url
                }${anchor!.url}" title="${anchor!.url}" >${
                  anchor!.title
                }</a></li>`,
            )
            .join('')}
`,
            { format: 'text/html', message: 'Sommaire copié' },
          );
        },
      },
    };
  },
};
