import { Error, FilterList } from '@mui/icons-material';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { pluralize } from '@prismamedia/one-components';
import type { FC } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import { useQueryParams } from '../../../../utils/useUriHelpers';
import { CategoriesContext } from '../../categories.context';
import { LARGE_CARD_ITEM_HEIGHT } from '../../components/Card/styles';
import { Skeletons } from '../../../../components/Skeletons';
import { DataTestIdListPage } from '../../tests/constants';
import type { Category } from '../../types';
import { BreadCrumbs } from './components/BreadCrumbs';
import { CategoryCard } from './components/CategoryCard';
import { Filter } from './components/Filter';
import { INITIAL_QUERY_PARAMS } from './components/Filter/constants';
import {
  ACTIVE_FILTER_PREFIX,
  ACTIVE_FILTER_SUFFIX,
  ERROR_BUTTON_RELOAD_LABEL,
  ERROR_HAPPENED_TEXT,
  NO_RESULT_TEXT,
} from './constants';
import { useStyles } from './styles';
import { getActiveFilterQuantity } from './utils';
import FlipMove from 'react-flip-move';
const List: FC = () => {
  const {
    activeCategory,
    categoriesError,
    isCategoriesLoading,
    refetchCategories,
  } = useContext(CategoriesContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { queryParams } = useQueryParams(INITIAL_QUERY_PARAMS);

  const classes = useStyles();

  const activeFilterQuantity = useMemo(
    () => getActiveFilterQuantity(queryParams),
    [queryParams],
  );

  const filteredActiveCategoryChildren = useMemo(
    () =>
      (activeCategory?.children || []).filter((childCategory: Category) => {
        const filterList: boolean[] = [];

        // Favorites filter
        if (queryParams.filterFavorites === 'true') {
          filterList.push(childCategory.isHeadlined);
        }

        // Status filter
        if (Array.isArray(queryParams.filterStatus)) {
          filterList.push(
            queryParams.filterStatus.some(
              (status) => status === childCategory.status,
            ),
          );
        } else {
          filterList.push(queryParams.filterStatus === childCategory.status);
        }

        return filterList.every(Boolean);
      }),
    [activeCategory, queryParams],
  );

  return (
    <div
      className={classes.wrapper}
      data-testid={DataTestIdListPage.LIST_WRAPPER}
    >
      <div className={classes.wrapperTopList}>
        <BreadCrumbs />
        <Filter isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} />
      </div>

      <div className={classes.innerWrapper}>
        {/* Loading placeholders */}
        {isCategoriesLoading && (
          <Skeletons height={LARGE_CARD_ITEM_HEIGHT} quantity={4} spacing={2} />
        )}

        {/* API Error */}
        {categoriesError && (
          <div className={classes.wrapperCenter}>
            <Error color="primary" fontSize="large" />
            <Typography color="primary" variant="h5">
              {categoriesError?.message || ERROR_HAPPENED_TEXT}
            </Typography>
            <br />
            <Button
              onClick={() => {
                refetchCategories();
              }}
              variant="outlined"
            >
              {ERROR_BUTTON_RELOAD_LABEL}
            </Button>
          </div>
        )}

        {/* Empty API result */}
        {!isCategoriesLoading &&
          !categoriesError &&
          !filteredActiveCategoryChildren.length && (
            <div className={classes.wrapperCenter}>
              <Typography variant="h5" color="primary">
                {NO_RESULT_TEXT}
              </Typography>

              {!!activeFilterQuantity && (
                <Chip
                  color="primary"
                  className={classes.activeFilterBadge}
                  data-testid={DataTestIdListPage.FILTER_BUTTON}
                  icon={<FilterList />}
                  label={`${activeFilterQuantity} ${pluralize(
                    ACTIVE_FILTER_PREFIX,
                    activeFilterQuantity,
                  )} ${pluralize(ACTIVE_FILTER_SUFFIX, activeFilterQuantity)}`}
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                />
              )}
            </div>
          )}

        {/* List cards */}
        <FlipMove>
          <Stack spacing={2}>
            {filteredActiveCategoryChildren.map((category: Category) => (
              <CategoryCard category={category} key={category.id} />
            ))}
          </Stack>
        </FlipMove>
      </div>
    </div>
  );
};

export { List };
