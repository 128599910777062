import { Settings } from '@mui/icons-material';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import {
  OneMedia,
  SearchBarVariant,
  getOneMediaContentBlocksErrors,
} from '@prismamedia/one-components';
import clsx from 'clsx';
import type { RawDraftContentState } from 'draft-js';
import {
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  BrandKey,
  GetArticle_article_articleTags,
  TagProviderName,
  TagType,
} from '../../../../../../__generated__/queries-web';
import { Draft } from '../../../../../../components/Draft';
import { actions } from '../../../../../../components/Draft/DraftActions';
import { MediaInput } from '../../../../../../components/MediaInput';
import { MEDIA_TYPES } from '../../../../../../components/MediaInput/constants';
import { Default as DefaultTags } from '../../../../../../components/Tags/TagItems/Default';
import { PluginType } from '../../../../../ArticleEdit/templates/utils';
import { renderPluginsFromTemplate } from '../../../../../ArticleEdit/utils/renderFromTemplate';
import { CategoriesContext } from '../../../../categories.context';
import { FormContext } from '../../../../form.context';
import {
  DataTestIdEditPage,
  DataTestIdListPage,
} from '../../../../tests/constants';
import type { CategoryTag } from '../../../../types';
import { isPageEditView } from '../../../../utils';
import {
  GENERAL_CONFIG_ANCHOR_HASH,
  GENERAL_CONFIG_LABEL,
} from '../../constants';
import {
  PEOPLE_TAG_HEIGHT,
  PEOPLE_TAG_WIDTH,
  useStyles as useSharedStyles,
  useTagsStyles,
} from '../../styles';
import { Skeletons } from './Skeletons';
import {
  CATEGORY_FORM_DESCRIPTION_LABEL,
  CATEGORY_FORM_PARTNER_TITLE_LABEL,
  CATEGORY_FORM_SEO_DESCRIPTION_LABEL,
  CATEGORY_FORM_TAG_LABEL,
  CATEGORY_FORM_TITLE_LABEL,
  CATEGORY_FORM_URL_LABEL,
} from './constants';
import { useStyles } from './styles';

export const GeneralConfiguration = () => {
  const { errors, form, handlers } = useContext(FormContext);
  const { isCategoriesLoading } = useContext(CategoriesContext);
  const { brandKey } = useParams();

  const pristineFieldsRef = useRef({
    description: true,
    medias: true,
  });

  const history = useHistory();

  const filteredTags = useMemo(
    () => form.tags.filter(({ title, type }) => Boolean(title && type)),
    [form.tags],
  );
  const tagsClasses = useTagsStyles({ hasTagsLength: !!filteredTags.length });

  const classes = useStyles({
    isSelected:
      history.location.hash.substring(1) === GENERAL_CONFIG_ANCHOR_HASH,
  });
  const sharedClasses = useSharedStyles();

  const handleAddChange = useCallback(
    (tags) => {
      const newTags = tags.map(
        ({ order, tag }: GetArticle_article_articleTags) => ({ order, ...tag }),
      ) as CategoryTag<TagType>[];

      handlers.handleFormChange({ path: ['tags'], value: newTags });
    },
    [handlers],
  );

  const setMedias = (updater: SetStateAction<OneMedia[]>) => {
    const newMedias =
      typeof updater === 'function' ? updater(form.medias || []) : updater;

    handlers.handleFormChange({
      errors: [],
      path: ['medias'],
      value: newMedias,
    });
  };

  const handleDescriptionChange = (newRawDraft: RawDraftContentState) => {
    if (newRawDraft) {
      handlers.handleFormChange({
        errors: {
          description: getOneMediaContentBlocksErrors(newRawDraft.blocks),
        },
        path: ['description'],
        value: newRawDraft,
        opts: { setFormPristine: pristineFieldsRef.current.description },
      });

      if (pristineFieldsRef.current.description) {
        pristineFieldsRef.current.description = false;
      }
    }
  };

  return (
    <div id={GENERAL_CONFIG_ANCHOR_HASH} className={classes.wrapper}>
      <div
        {...(isPageEditView() && {
          ['data-testid']: DataTestIdListPage.EDIT_WRAPPER,
        })}
      >
        <Box className={classes.header} component="header">
          <Settings fontSize="large" />
          <Typography variant="h6">{GENERAL_CONFIG_LABEL}</Typography>
        </Box>

        <Box className={classes.innerWrapper} component="div">
          {isCategoriesLoading ? (
            <Skeletons />
          ) : (
            <>
              <DefaultTags
                allowedTagProviders={[
                  TagProviderName.Tag,
                  TagProviderName.Destination,
                ]}
                allowedTagTypes={[TagType.Tag, TagType.Destination]}
                brandKey={brandKey}
                className={clsx(classes.wrapperTags, tagsClasses.wrapperTags)}
                height={PEOPLE_TAG_HEIGHT}
                onTagsChange={handleAddChange}
                SearchBarProps={{
                  className: tagsClasses.searchBar,
                  variant: SearchBarVariant.TERTIARY,
                }}
                tags={filteredTags}
                title={CATEGORY_FORM_TAG_LABEL}
                width={PEOPLE_TAG_WIDTH}
              />

              <FormControl className={sharedClasses.wrapperField} fullWidth>
                <TextField
                  label={CATEGORY_FORM_URL_LABEL}
                  onChange={(event) => {
                    handlers.handleFormChange({
                      path: ['slug'],
                      value: event.target.value,
                    });
                  }}
                  value={form.slug}
                  variant="standard"
                />
              </FormControl>

              <FormControl className={sharedClasses.wrapperField} fullWidth>
                <TextField
                  {...(errors.title.isTouched && {
                    helperText:
                      errors.title.errorMessages[errors.title.errors[0]],
                  })}
                  inputProps={{
                    'data-testid': DataTestIdEditPage.CATEGORY_FORM_TITLE_LABEL,
                  }}
                  error={errors.title.isTouched && errors.title.hasError}
                  label={CATEGORY_FORM_TITLE_LABEL}
                  onChange={(event) => {
                    handlers.handleFormChange({
                      path: ['title'],
                      value: event.target.value,
                    });
                  }}
                  required
                  value={form.title}
                  variant="standard"
                />
              </FormControl>

              <FormControl className={sharedClasses.wrapperField} fullWidth>
                <TextField
                  inputProps={{
                    'data-testid':
                      DataTestIdEditPage.CATEGORY_FORM_DESCRIPTION_LABEL,
                  }}
                  label={CATEGORY_FORM_PARTNER_TITLE_LABEL}
                  onChange={(event) => {
                    handlers.handleFormChange({
                      path: ['subTitle'],
                      value: event.target.value,
                    });
                  }}
                  value={form.subTitle}
                  variant="standard"
                />
              </FormControl>

              <FormControl className={sharedClasses.wrapperField} fullWidth>
                <TextField
                  fullWidth
                  label={CATEGORY_FORM_SEO_DESCRIPTION_LABEL}
                  multiline
                  onChange={(event) => {
                    handlers.handleFormChange({
                      path: ['descriptionSEO'],
                      value: event.target.value,
                    });
                  }}
                  value={form.descriptionSEO}
                  variant="standard"
                />
              </FormControl>

              <FormControl className={sharedClasses.wrapperField} fullWidth>
                <Draft
                  actions={[
                    actions.BOLD,
                    actions.ITALIC,
                    actions.STRIKETHROUGH,
                    actions.LINK,
                    actions.ANCHOR_LINK,
                    actions.INTERNAL_LINK,
                    actions.TITLE,
                    actions.HEADLINE,
                    actions.LIST,
                    actions.ORDERED_LIST,
                    actions.BLOCKQUOTE,
                  ]}
                  onChange={handleDescriptionChange}
                  placeholder={CATEGORY_FORM_DESCRIPTION_LABEL}
                  plugins={renderPluginsFromTemplate(brandKey as BrandKey, [
                    PluginType.Table,
                  ])}
                  rawDraft={form.description}
                  withErrors={true}
                />
              </FormControl>

              <FormControl className={sharedClasses.wrapperMedias} fullWidth>
                <MediaInput
                  medias={form.medias || []}
                  setMedias={setMedias}
                  brandKey={brandKey}
                  allowedMedias={[MEDIA_TYPES.IMAGE]}
                />
              </FormControl>
            </>
          )}
        </Box>
      </div>
    </div>
  );
};
