import { DocumentNode, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';

export const useUpdate = <Mutation, UpdateInput>(
  mutation: DocumentNode,
  refetchQueries?: DocumentNode[],
  succesMessage = 'Sauvegarde effectuée',
) => {
  const { pushNotification } = useNotification();
  const [updateItem] = useMutation<Mutation>(mutation, {
    refetchQueries: refetchQueries?.map((query) => getOperationName(query)!),
    awaitRefetchQueries: true,
    onError: (e) => {
      pushNotification({
        message: e.message || 'Une erreur est survenue',
        type: NotificationTypeEnum.error,
      });
    },
  });
  return (id: string, data: UpdateInput) =>
    updateItem({
      variables: {
        where: { id },
        data,
      },
    }).then((res) => {
      if (res) {
        pushNotification({
          message: succesMessage,
          type: NotificationTypeEnum.success,
        });
      }
      return res;
    });
};

export const useCreate = <Mutation, CreateInput>(
  mutation: DocumentNode,
  refetchQueries?: DocumentNode[],
) => {
  const { pushNotification } = useNotification();
  const [createItem] = useMutation<Mutation>(mutation, {
    refetchQueries: refetchQueries?.map((query) => getOperationName(query)!),
    awaitRefetchQueries: true,
    onCompleted: () => {
      pushNotification({
        message: 'Création effectuée',
        type: NotificationTypeEnum.success,
      });
    },
    onError: (e) => {
      pushNotification({
        message: e.message || 'Une erreur est survenue',
        type: NotificationTypeEnum.error,
      });
    },
  });
  return (data: CreateInput) =>
    createItem({
      variables: {
        data,
      },
    });
};
