import { Box, Chip, ListSubheader, Typography, useTheme } from '@mui/material';
import type { BrandKey } from '@prismamedia/one-brandkey';
import {
  MultimediaList,
  MultimediaSearch,
  SearchParams,
  ViewMode,
} from '@prismamedia/one-components';
import { FC, useCallback, useEffect, useState } from 'react';
import type { FotoProps } from '..';
import {
  GetPhotos_photos,
  MetadataNameEnumType,
  PhotoOrderByInput,
} from '../../../../__generated__/queries-photo';
import { useGetPhotos } from '../../../../apollo/queries/photos.photo.graphql';
import { getMetadata } from '../../../../apollo/utils/getMetadata';
import { uploadAndEmbedImageUrl } from '../../../../utils/upload';
import { PhotoIcon } from '../Icon';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { PhotoCollapse } from '../components/PhotoCollapse';
import { useStyles as usePhotoCollapseStyles } from '../components/styles';
import { PHOTOS_TECHNICAL_SOURCES } from '../constants';
import { FotoItemSerialized, PhotoCollapsesTypes } from '../types';
import {
  LAST_CREATED_PHOTOS_TEXT,
  ARCHIVES_FILTER_DEFAULT,
  ARCHIVES_FILTER_PREMIUM,
  SEARCH_BAR_PLACEHOLDER,
  ONE_PHOTO_ARCHIVES,
  BRANDS_PREMIUM_ACCESS_ARCHIVES,
} from './constants';
import { useStyles } from './styles';
import { ArchiveIcon } from './Icons';

interface OnePhotoProps extends FotoProps<FotoItemSerialized> {
  brandKey: BrandKey;
  isCollapseOpen: boolean;
  onCollapseClick: (summary: PhotoCollapsesTypes | null) => void;
}
export const OnePhoto: FC<OnePhotoProps> = ({
  brandKey,
  isCollapseOpen,
  isDisabled,
  isLoading,
  itemToRender,
  items,
  onCollapseClick,
  onItemClick,
  pluginType,
  selectedItems,
  setItems,
  value = '',
}) => {
  const [defaultItemsLength, setDefaultItemsLength] = useState<number | null>(
    null,
  );
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: value,
  });
  const allowPremiumArchives = BRANDS_PREMIUM_ACCESS_ARCHIVES.includes(
    brandKey,
  );
  const [archivesFilter, setArchivesFilter] = useState<ONE_PHOTO_ARCHIVES[]>(
    allowPremiumArchives ? ARCHIVES_FILTER_PREMIUM : ARCHIVES_FILTER_DEFAULT,
  );

  const theme = useTheme();

  const handleArchivesFilterChanges = useCallback(
    (selectedArchive: ONE_PHOTO_ARCHIVES) => {
      const providerIndex = archivesFilter.indexOf(selectedArchive);
      const newProvidersFilder =
        providerIndex > -1
          ? archivesFilter.filter(
              (providerFilter) => providerFilter !== selectedArchive,
            )
          : [...archivesFilter, selectedArchive];

      setArchivesFilter(newProvidersFilder);
    },
    [archivesFilter],
  );

  const { data, loading: isOnePhotoLoading } = useGetPhotos(
    {
      first: 50,
      where: {
        isUploaded: true,
        archive: { name_in: archivesFilter },
        AND: [
          ...(searchParams.search
            ? [
                {
                  photoMetadatas_some: {
                    value_contains: searchParams.search,
                  },
                },
              ]
            : []),
        ],
      },
      orderBy: [PhotoOrderByInput.createdAt_DESC],
    },
    !isCollapseOpen,
  );

  const classes = useStyles({ isOpen: isCollapseOpen });
  const photoCollaspeClasses = usePhotoCollapseStyles({});

  const getItemToIframely = async (item: GetPhotos_photos) => {
    if (!item.downloadUrl) {
      throw Error('Download Url not found');
    }

    const source = getMetadata(MetadataNameEnumType.Source, item);
    const credit = getMetadata(MetadataNameEnumType.Credit, item);

    const iframely = await uploadAndEmbedImageUrl(
      item.downloadUrl,
      brandKey,
      credit || undefined,
    );

    if (iframely) {
      iframely.meta.technicalSource = PHOTOS_TECHNICAL_SOURCES.ONE_PHOTOS;

      if (source) {
        iframely.meta.source = source;
      }
    }

    return iframely;
  };

  const resetSearch = useCallback(() => {
    setItems([]);
  }, [setItems]);

  useEffect(() => {
    resetSearch();
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (data?.photos) {
      const newItems = data.photos.map((photo) => ({
        ...photo,
        from: PhotoCollapsesTypes.ONE_PHOTOS,
        pluginType,
      }));

      setItems(newItems);
      setDefaultItemsLength(newItems.length);
    }

    // eslint-disable-next-line
  }, [data]);

  return (
    <PhotoCollapse
      collapseType={PhotoCollapsesTypes.ONE_PHOTOS}
      defaultItemsLength={defaultItemsLength}
      Icon={
        <>
          <LeaderboardIcon
            style={{
              backgroundColor: '#232323',
              color: '#ffffff',
              borderRadius: '50%',
              padding: '3px',
            }}
          />
          {allowPremiumArchives && <PhotoIcon fill="#232323" />}
        </>
      }
      isOpen={isCollapseOpen}
      isPhotoLoading={isOnePhotoLoading}
      onCollapseClick={onCollapseClick}
    >
      <div className={classes.topInnerWrapper}>
        <MultimediaSearch
          className={classes.multimediaSearch}
          searchParams={searchParams}
          placeholder={SEARCH_BAR_PLACEHOLDER}
          setSearchParams={setSearchParams}
        />

        <Box
          sx={{
            display: 'block',
            left: 0,
            position: 'relative',
            paddingLeft: theme.spacing(3),
            zIndex: 2,
            fontSize: '0.8125rem',

            '& > div': {
              marginRight: theme.spacing(1),

              '& > span': {
                marginRight: theme.spacing(-0.5),
              },
            },
          }}
        >
          {allowPremiumArchives &&
            ARCHIVES_FILTER_PREMIUM.map((archive) => (
              <Chip
                color={archivesFilter.includes(archive) ? 'primary' : 'default'}
                disabled={
                  archivesFilter.length === 1 &&
                  archivesFilter.includes(archive)
                }
                key={archive}
                label={
                  <ArchiveIcon
                    archive={archive}
                    enabled={archivesFilter.includes(archive)}
                  />
                }
                onClick={() => {
                  handleArchivesFilterChanges(archive);
                }}
                size="small"
                variant={
                  archivesFilter.includes(archive) ? 'filled' : 'outlined'
                }
              />
            ))}
        </Box>

        <ListSubheader
          className={classes.headerText}
          component="span"
          disableSticky
        >
          <Typography variant="body2" gutterBottom>
            <span className={photoCollaspeClasses.suggestText}>
              {LAST_CREATED_PHOTOS_TEXT} (
              {isOnePhotoLoading ? '...' : items.length})
            </span>
          </Typography>
        </ListSubheader>
      </div>

      <MultimediaList
        className={classes.wrapperList}
        isDisabled={isDisabled}
        isLoading={isLoading || isOnePhotoLoading}
        itemToRender={itemToRender}
        items={items}
        onItemClick={(rowItem: FotoItemSerialized) => {
          onItemClick(rowItem, pluginType, () =>
            getItemToIframely((rowItem as unknown) as GetPhotos_photos),
          );
        }}
        pluginType={pluginType}
        selectedItems={selectedItems}
        viewMode={ViewMode.GRID}
      />
    </PhotoCollapse>
  );
};
