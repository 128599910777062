import { useOnMount } from '@prismamedia/one-components';
import { cloneDeep, isUndefined, get, set } from 'lodash';
import { useContext, useMemo } from 'react';
import uuidv4 from 'uuid/v4';
import type { DragAndDropItem } from '../../../../../../components/DragAndDrop';
import { TabType } from '../../../../../../__generated__/queries-web';
import { DEFAULT_CATEGORY_FORM_SECTION } from '../../../../constants';
import { FormContext } from '../../../../form.context';
import { getCategoryFormSection } from '../../../../models/categoryFormSection.model';
import type { SectionErrors, SectionForm } from './types';
import { getOrderFromList, getSectionFormErrors } from './utils';

interface HandleSectionAddParams {
  cb?: { (section: DragAndDropItem): void };
  type: TabType;
}

interface HandleSectionChangeParams {
  errors?: SectionErrors;
  fieldName: keyof SectionForm;
  opts?: { setFormPristine: boolean };
  path?: string[];
  value: any;
}

interface Handlers {
  handleSectionAdd: { (params: HandleSectionAddParams): void };
  handleSectionChange: { (params: HandleSectionChangeParams): void };
  handleSectionDelete: { (cb?: { (): void }): void };
  handleSectionsReorder: { (ordoredSections: DragAndDropItem[]): void };
}
interface UseEditSectionProps {
  errors: SectionErrors | undefined;
  form: SectionForm | undefined;
  handlers: Handlers;
}

export const useEditSection = (sectionId?: string): UseEditSectionProps => {
  const {
    errors: categoryErrors,
    form: categoryForm,
    handlers: formHandlers,
  } = useContext(FormContext);

  const sectionForm = useMemo(
    () =>
      categoryForm.sections.find((formSection) => formSection.id === sectionId),
    [categoryForm.sections, sectionId],
  );

  const sectionErrors = useMemo(
    () => sectionForm && categoryErrors.sections[sectionForm.order],
    [categoryErrors, sectionForm],
  );

  const handlers = useMemo(
    (): Handlers => ({
      handleSectionAdd: ({ cb, type }) => {
        const lastSectionOrderValue = getOrderFromList(
          categoryForm.sections,
          'last',
        );

        const newSection = getCategoryFormSection({
          ...DEFAULT_CATEGORY_FORM_SECTION,
          id: uuidv4(),
          order: !isUndefined(lastSectionOrderValue)
            ? lastSectionOrderValue + 1
            : 0,
          type,
        } as any);

        const newSectionsErrors = [
          ...categoryErrors.sections,
          getSectionFormErrors({ form: newSection }),
        ];

        const newSections = [...categoryForm.sections, newSection];

        formHandlers.handleFormChange<SectionErrors[]>({
          errors: newSectionsErrors,
          path: ['sections'],
          value: newSections,
        });

        cb?.(newSection);
      },
      handleSectionChange: ({
        errors,
        fieldName,
        path = [],
        ...restParams
      }) => {
        const sectionIndex = categoryForm.sections.findIndex(
          (section) => section.id === sectionId,
        );

        const sectionPath = [fieldName, ...path];
        const newSectionForm = cloneDeep(sectionForm)!;

        set(newSectionForm, sectionPath, restParams.value);

        const newErrors = categoryErrors.sections.map(
          (currentSectionErrors, index) =>
            index === sectionIndex
              ? errors ||
                getSectionFormErrors({
                  errors: currentSectionErrors,
                  form: newSectionForm,
                  path: sectionPath,
                })
              : currentSectionErrors,
        );

        const currentSectionPath = [
          sectionIndex.toString(),
          sectionPath,
        ].flat();

        formHandlers.handleFormChange({
          path: ['sections', ...currentSectionPath],
          errors: get(newErrors, currentSectionPath),
          ...restParams,
        });
      },
      handleSectionDelete: (cb) => {
        const newSections = categoryForm.sections
          .filter((section) => sectionId !== section.id)
          .map((section, index) => ({ ...section, order: index }));

        const newErrors = categoryErrors.sections.filter(
          (_: SectionErrors, index: number) => index !== sectionForm!.order,
        );

        formHandlers.handleFormChange({
          errors: newErrors,
          path: ['sections'],
          value: newSections,
        });

        cb?.();
      },
      handleSectionsReorder: (newOrdoredSections) => {
        const newSections = newOrdoredSections.map((section, index) => ({
          ...section,
          order: index,
        }));

        const newErrors = categoryErrors.sections
          .map((categoryFormError, index: number) => ({
            ...categoryFormError,
            id: categoryForm.sections[index].id,
          }))
          .sort(
            (curr, prev) =>
              newOrdoredSections.findIndex(
                (section) => section.id === curr.id,
              ) -
              newOrdoredSections.findIndex((section) => section.id === prev.id),
          )
          .map(({ id, ...rest }) => ({ ...rest }));

        formHandlers.handleFormChange({
          errors: newErrors,
          path: ['sections'],
          value: newSections,
        });
      },
    }),
    [
      categoryForm.sections,
      categoryErrors,
      formHandlers,
      sectionForm,
      sectionId,
    ],
  );

  useOnMount(() => {
    // We pushing computed section errors on mount
    if (sectionForm) {
      let newErrors: any = sectionErrors;

      if (!sectionErrors) {
        newErrors = categoryForm.sections.map((currentSectionForm) =>
          getSectionFormErrors({ form: currentSectionForm }),
        );
        formHandlers.handleErrorsChange({
          fieldName: 'sections',
          value: newErrors,
        });
      }
    }
  });

  return {
    errors: sectionErrors,
    form: sectionForm,
    handlers,
  };
};
