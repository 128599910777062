import {
  ErrorMessages,
  getDeepObjectValuesByKey,
} from '@prismamedia/one-components';
import { cloneDeep, isString, isUndefined } from 'lodash';
import type { ExtractRouteParams } from 'react-router';
import { generatePath, matchPath } from 'react-router-dom';
import { GetPageCategories_categories } from '../../__generated__/queries-web';
import { history } from '../../history';
import { paths } from '../../routing/Router/paths';
import {
  ALLOWED_CATEGORY_STATUES,
  CATEGORY_TITLE_REQUIRED_ERROR_MESSAGE,
  DEFAULT_ROOT_CATEGORY,
} from './constants';
import type { Category, CategoryErrors, CategoryForm } from './types';

/// CATEGORY UTILS ////////////////////////////////////////////
export const getBrandCategoriesWithPath = <T extends unknown>(
  categories: T,
  prevPathSegments = '',
): Category[] => {
  if (!Array.isArray(categories)) {
    return [];
  }

  return categories.map((category) => {
    const isRootCategory = category.level <= DEFAULT_ROOT_CATEGORY.level;
    const path = isRootCategory
      ? `/${category.id}`
      : `${prevPathSegments}/${category.id}`;

    return {
      ...category,
      ...(category.children && {
        children: getBrandCategoriesWithPath(category.children, path),
      }),
      path,
    };
  });
};

export const getCategoryStatusLabel = (targetStatus?: string): string =>
  ALLOWED_CATEGORY_STATUES?.filter((status) => status.id === targetStatus)[0]
    ?.label || '';

const getCurrentPath = () =>
  matchPath(history.location.pathname, Object.values(paths))?.path || '';

type FlatenedBrandCategory = Omit<Category, 'children' | '__typename'>;
export const getFlattenedBrandCategories = <T extends unknown>(
  list: T,
  opts?: {
    filter?: { key: keyof GetPageCategories_categories; filterList: string[] };
  },
): FlatenedBrandCategory[] => {
  if (!Array.isArray(list)) {
    return [];
  }

  const flatenedBrandCategories = list.reduce(
    (previousValue: string[], { children, ...rest }) => {
      let newChildren: FlatenedBrandCategory[] = [];

      if (children?.length) {
        newChildren = getFlattenedBrandCategories<typeof children>(children);
      }

      return [...previousValue, { children, ...rest }, ...newChildren];
    },
    [],
  );

  return opts?.filter
    ? flatenedBrandCategories.filter(
        (category: GetPageCategories_categories) => {
          if (opts?.filter?.key && opts?.filter?.filterList) {
            const {
              filter: { filterList, key },
            } = opts;

            const valueToFilter = category[key];
            return (
              isString(valueToFilter) && filterList.includes(valueToFilter)
            );
          }

          return true;
        },
      )
    : flatenedBrandCategories;
};

export const getActivePathCategoryId = (
  routeParams: Record<string, any>,
): string =>
  routeParams.lvl5 ||
  routeParams.lvl4 ||
  routeParams.lvl3 ||
  routeParams.lvl2 ||
  routeParams.lvl1 ||
  '';

/// ROUTE UTILS ////////////////////////////////////////////
export const isPageCreateView = () =>
  !!matchPath(history.location.pathname, { path: paths.PAGE_CREATE });

export const isPageEditView = () =>
  !!matchPath(history.location.pathname, { path: paths.PAGE_EDIT });

interface NavigateToProps {
  pathname?: string;
  opts?: {
    method?: 'PUSH' | 'POP' | 'REPLACE';
    hash?: string;
    search?: string;
  };
  routeParams?: ExtractRouteParams<any>;
}

export const navigateTo = (params: NavigateToProps) => {
  const { pathname, routeParams = {}, opts } = params;

  // By default the used pathname is the current one
  let finalPathname = history.location.pathname;

  // If a pathname is passed as param, we using it
  if (pathname) {
    const isPathIsRoutePattern = pathname?.includes(':');
    finalPathname = isPathIsRoutePattern
      ? generatePath(pathname, routeParams)
      : pathname;
  }

  // If routeParams is passed as param, we using it too
  else if (routeParams.brandKey && routeParams.format) {
    finalPathname = generatePath(getCurrentPath(), routeParams);
  }

  // We keeping current hash and search params if they're exists or using those are passed as "opts" param
  (history as any)[(opts?.method || 'PUSH').toLowerCase()]({
    hash: !isUndefined(opts?.hash) ? opts!.hash : history.location.hash,
    pathname: finalPathname,
    search: !isUndefined(opts?.search) ? opts!.search : history.location.search,
  });
};

/// ERROR FORM CATEGORY UTILS ////////////////////////////////////////////
export const getIsFormValid = (errors: CategoryErrors) =>
  !getDeepObjectValuesByKey(errors, 'errors').length;

interface GetComputedCategoryFormErrorsProps {
  errors: CategoryErrors;
  form: CategoryForm;
  path?: string[];
}
export const getCategoryFormErrors = ({
  errors,
  form,
  path,
}: GetComputedCategoryFormErrorsProps): CategoryErrors => {
  const isInitialization = !path;
  let newErrors = cloneDeep(errors);

  // Title check
  if (isInitialization || path[0] === 'title') {
    const hasRequiredRuleError = !form.title?.length;
    const hasError = hasRequiredRuleError;
    const errorList = [
      ...((hasRequiredRuleError && [ErrorMessages.requiredRule]) || []),
    ];

    newErrors = {
      ...newErrors,
      title: {
        errorMessages: {
          [ErrorMessages.requiredRule]: CATEGORY_TITLE_REQUIRED_ERROR_MESSAGE,
        },
        errors: errorList,
        hasError,
        isTouched: !isInitialization,
      },
    };
  }

  return newErrors;
};

/// ERROR UTILS ////////////////////////////////////////////
export const getErrorMessages = (
  errors: any = {},
  messages: string[] = [],
): string[] =>
  Object.keys(errors).reduce((errorMessages: string[], key: string) => {
    const currentSectionErrors = errors[key];

    if (!currentSectionErrors || !('errors' in currentSectionErrors)) {
      return getErrorMessages(currentSectionErrors, errorMessages);
    }

    if (currentSectionErrors.hasError) {
      const currentSectionErrorsMessages = currentSectionErrors.errors.map(
        (errorType: string) =>
          currentSectionErrors.errorMessages[errorType] || '',
      );

      errorMessages.push(...currentSectionErrorsMessages);
    }

    return errorMessages;
  }, messages);
