import { MEDIA_TYPES_LABEL } from '@prismamedia/one-components';
import {
  GetRecipes,
  GetRecipesVariables,
  RecipeOrderByInput,
  RecipeStatus,
} from '../../../../__generated__/queries-recipe';
import {
  ArticleFormat,
  ArticleSearchOrderByInput,
  ArticleStatus,
  BrandKey,
  SearchArticles,
  SearchArticlesVariables,
} from '../../../../__generated__/queries-web';
import { client } from '../../../../apollo';
import { GET_RECIPES } from '../../../../apollo/queries/recipes.recipe.graphql';
import { SEARCH_ARTICLES } from '../../../../apollo/queries/searchArticles.web.graphql';
import {
  AdvancedSearchParams,
  formatArticleSearchBarInput,
} from '../../../../utils/formatArticleSearchInput';
import { articlesWithSlideShowCount } from './articlesWithSlideshowCount';

const PAGE_RESULT = 10;
type SearchResult<T> = T;

const extractPath = (str: string) => {
  try {
    return new URL(str).pathname;
  } catch (e) {
    return undefined;
  }
};

export const searchArticles = async (
  searchParams: AdvancedSearchParams,
  articleFormat: ArticleFormat,
  opts: { first?: number; skip: number },
  brandKey?: BrandKey,
  searchSchedule?: boolean,
  withDiapoCount?: boolean,
) => {
  const isVideo = articleFormat === ArticleFormat.Video;
  const articleVideoStatus = [
    ArticleStatus.Published,
    ArticleStatus.Private,
    ArticleStatus.Ready,
  ];
  const scheduledStatus = [ArticleStatus.Published, ArticleStatus.Scheduled];
  const status_in = isVideo
    ? articleVideoStatus
    : searchSchedule
    ? scheduledStatus
    : [ArticleStatus.Published];
  // can't use hook, used outside components
  const { data } = await client.query<SearchArticles, SearchArticlesVariables>({
    fetchPolicy: 'no-cache',
    query: SEARCH_ARTICLES,
    variables: {
      skip: opts.skip,
      first: opts.first || PAGE_RESULT,
      where: formatArticleSearchBarInput(
        articleFormat,
        {
          ...searchParams,
          status_in: [...status_in, ...(searchParams.status_in || [])],
        },
        ArticleStatus.Published,
        brandKey,
      ),
      orderBy:
        articleFormat === ArticleFormat.Video
          ? [ArticleSearchOrderByInput.createdAt_DESC]
          : [ArticleSearchOrderByInput.publishedAt_DESC],
    },
  });

  if (data) {
    if (articleFormat === ArticleFormat.Slideshow && withDiapoCount) {
      return articlesWithSlideShowCount(data.searchArticles);
    }
    return data.searchArticles;
  }
  return [];
};

const searchRecipes = async (
  searchParams: AdvancedSearchParams,
  brandKey: BrandKey,
  opts: { first?: number; skip: number },
  searchSchedule?: boolean,
) => {
  if (opts.skip) return [];

  const searchUrl = extractPath(searchParams.search || '');

  let id_in: string[] | undefined;

  const { data } = await client.query<GetRecipes, GetRecipesVariables>({
    query: GET_RECIPES,
    variables: {
      first: 100,
      where: {
        id_in,
        recipeUrls_some: searchUrl ? { url: { path: searchUrl } } : undefined,
        status_in: [
          RecipeStatus.Published,
          ...(searchSchedule ? [RecipeStatus.Scheduled] : []),
          ...((searchParams.status_in as RecipeStatus[] | undefined) || []),
        ],
        brandKey,
        ...(searchParams.search && { title_contains: searchParams.search }),
      },
      orderBy: [RecipeOrderByInput.publishedAt_DESC],
    },
  });

  if (!data) return [];

  if (!id_in) return data.recipes;

  return [...data.recipes].sort(({ id: idA }, { id: idB }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const indexA = id_in!.indexOf(idA);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const indexB = id_in!.indexOf(idB);
    return indexA - indexB;
  });
};

interface SearchPluginProps {
  searchParams: AdvancedSearchParams;
  pluginType: string;
  opts: { first: number; skip: number };
  brandKey?: BrandKey;
  searchSchedule?: boolean;
}

export const searchByPluginType = async (
  props: SearchPluginProps,
): Promise<SearchResult<any>[]> => {
  const { searchParams, pluginType, opts } = props;

  switch (pluginType) {
    case MEDIA_TYPES_LABEL.ARTICLES:
      return searchArticles(
        searchParams,
        ArticleFormat.Rich,
        opts,
        props?.brandKey,
        props?.searchSchedule,
      );
    case MEDIA_TYPES_LABEL.RECIPES:
      return searchRecipes(
        searchParams,
        props?.brandKey || BrandKey.FAC,
        opts,
        props?.searchSchedule,
      );
    case MEDIA_TYPES_LABEL.VIDEOS:
      return searchArticles(
        searchParams,
        ArticleFormat.Video,
        opts,
        undefined,
        props?.searchSchedule,
      );
    case MEDIA_TYPES_LABEL.SLIDESHOWS:
      return searchArticles(
        searchParams,
        ArticleFormat.Slideshow,
        opts,
        undefined,
        props?.searchSchedule,
        true,
      );
    default:
      return Promise.reject(`Unknown plugin type: ${pluginType}`);
  }
};
