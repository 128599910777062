import makeStyles from '@mui/styles/makeStyles';

const BREADCRUMB_ITEM_HEIGHT = 43;

export const useStyles = makeStyles((theme) => ({
  buttonItem: {
    fontSize: '1.1rem',
    fontWeight: 500,
    height: `${BREADCRUMB_ITEM_HEIGHT}px`,
    textTransform: 'none',
  },
  breadCrumbLink: {
    color: theme.palette.grey[500],
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 500,
    height: `${BREADCRUMB_ITEM_HEIGHT}px`,
    lineHeight: `${BREADCRUMB_ITEM_HEIGHT}px`,
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
  },
  breadCrumbRootLink: {
    color: theme.palette.primary.main,
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 500,
    height: `${BREADCRUMB_ITEM_HEIGHT}px`,
    lineHeight: `${BREADCRUMB_ITEM_HEIGHT}px`,
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
  },
  breadCrumbPlaceholder: {
    borderRadius: '6px',
    margin: theme.spacing(1.1, 0, 1.2, 0.5),
    padding: theme.spacing(0, 1.5),

    '& svg': {
      margin: '0 !important',
    },

    '& span': {
      display: 'none',
    },
  },
  loader: {
    position: 'relative',
    top: theme.spacing(0.35),
    left: theme.spacing(0.75),
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 auto',
    height: '47px',
    marginLeft: theme.spacing(-1),
    paddingBottom: theme.spacing(0.5),
  },
  wrapperBreadCrumbRootCategory: ({
    isRootCategoryActive,
  }: { isRootCategoryActive?: boolean } | void = {}) => ({
    '& > div': {
      filter: isRootCategoryActive ? 'grayscale(0)' : 'grayscale(1)',
      opacity: isRootCategoryActive ? 1 : 0.85,
      marginRight: theme.spacing(1),
      pointerEvents: 'none',
    },
  }),
}));
