import { MaterialDraft, Plugin } from '@prismamedia/one-components';
import clsx from 'clsx';
import { EditorState, RawDraftContentState } from 'draft-js';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { imagePlugin } from '../../pages/ArticleEdit/templates/utils';
import { DraftActionType } from './DraftActions';
import './megadraft.css';
import { useStyles } from './styles';

interface DraftProps {
  actions: DraftActionType[];
  className?: string;
  handlePastedText?: (
    text: string,
    html: string,
    editorState: EditorState,
  ) => boolean;
  onChange: (value: RawDraftContentState) => void;
  onSelection?: (editorState: EditorState) => void;
  placeholder?: string | JSX.Element;
  plugins?: Plugin[];
  rawDraft: RawDraftContentState | null;
  readOnly?: boolean;
  withErrors?: boolean;
}

export const Draft: FC<DraftProps> = ({
  actions,
  className,
  handlePastedText,
  onChange,
  onSelection,
  placeholder,
  plugins,
  rawDraft,
  readOnly,
  withErrors,
}) => {
  const classes = useStyles();
  const { brandKey } = useParams();

  const draftPlugins = useMemo(
    () =>
      plugins?.map((plugin) =>
        plugin.type === 'image' ? imagePlugin(brandKey, withErrors) : plugin,
      ),
    [brandKey, plugins, withErrors],
  );

  return (
    <div>
      <MaterialDraft
        actions={actions}
        className={clsx(className, classes.draft)}
        disabled={readOnly}
        handlePastedText={handlePastedText}
        label={placeholder}
        onChange={(val: string) => {
          let value: any;
          try {
            value = JSON.parse(val);
          } catch (e) {}
          onChange(value);
        }}
        onSelection={onSelection}
        plugins={draftPlugins}
        value={JSON.stringify(rawDraft)}
        wordCounter
        shrink
      />
    </div>
  );
};
