import { IframelyMedia, resizeImage } from '@prismamedia/one-components';
import { getAxiosClient } from '../getAxiosClient';

export const uploadImage = async (file: File, namespace: string) => {
  const data = new FormData();
  data.append('image', file);
  data.append('namespace', namespace.toLowerCase());

  const axios = await getAxiosClient();
  const result = await axios.post(`${config.API_ONE_IMAGE}/api/images`, data);
  return result.data;
};

export const embedImage = async (
  url: string,
  { fileName }: { fileName?: string } = {},
): Promise<IframelyMedia> => {
  const axios = await getAxiosClient();

  const result = await axios.get(
    `${config.API_ONE_IFRAMELY}/iframely?uri=${encodeURIComponent(url)}`,
  );

  return { ...result.data, ...(fileName && { fileName }) };
};

export const uploadAndEmbedImage = async (
  file: File,
  namespace: string,
  { withFileName }: { withFileName?: boolean } = {},
) => {
  if (file.size > config.IMAGE_MAX_SIZE || !isImageFile(file)) {
    throw Error('Invalid File type and/or size');
  }

  const resizedFile = await resizeImage(file, 3000, 3000);
  const uploadResult = await uploadImage(resizedFile, namespace);

  return embedImage(uploadResult.url, {
    ...(withFileName && { fileName: file.name }),
  });
};

const uploadImageUrl = async (
  url: string,
  brandKey: string,
  credit?: string,
) => {
  const data = new FormData();
  data.append('url', url);
  data.append('namespace', brandKey.toLowerCase());
  credit && data.append('credit', credit);

  const axios = await getAxiosClient();
  const result = await axios.post(`${config.API_ONE_IMAGE}/import/url`, data);
  return result.data;
};

export const uploadAndEmbedImageUrl = async (
  url: string,
  brandKey: string,
  credit?: string,
  { withFileName }: { withFileName?: boolean } = {},
) => {
  const uploadResult = await uploadImageUrl(url, brandKey, credit);

  return embedImage(uploadResult.url, {
    ...(withFileName && { fileName: url }),
  });
};

export const extractImgSrc = ({
  meta,
  links,
}: IframelyMedia): string | undefined => {
  if (meta.url) return meta.url;
  if (links.image && links.image[0] && links.image[0].href) {
    return links.image[0].href;
  }
  if (links.thumbnail && links.thumbnail[0] && links.thumbnail[0].href) {
    return links.thumbnail[0].href;
  }
};

const isImageFile = (file: File) => {
  return file.type.includes('image') || file.type.includes('octet-stream');
};

export const uploadImageWithSignedURL = async (file: File, url: string) => {
  const { type } = file;
  const axios = await getAxiosClient();
  delete axios.defaults.headers['Authorization'];
  const options = {
    headers: {
      'Content-Type': type,
    },
  };

  const result = await axios.put(url, file, options);
  return result.data;
};
