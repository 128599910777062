import { BurstMode, Description } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  MEDIA_TYPES_LABEL,
  MultimediaPlugin,
} from '@prismamedia/one-components';
import { FC, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { BrandKey } from '../../../../__generated__/queries-web';
import { searchByPluginType } from '../../../../components/Draft/plugins/shared/searchArticleForMedia';
import {
  getItemToIframely,
  getItemToRender,
} from '../../../../components/MediaInput/utils';
import { paths } from '../../../../routing/Router/paths';
import { useStyles } from './styles';

interface ArticleSearchDialogProps {
  brandKey: BrandKey;
}

export const ArticleSearchDialog: FC<ArticleSearchDialogProps> = ({
  brandKey,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        className={classes.pickButton}
      >
        Chercher un article ou un diapo
      </Button>
      {open && (
        <MultimediaPlugin
          allowedMedias={[
            {
              type: MEDIA_TYPES_LABEL.ARTICLES,
              Icon: Description,
              label: 'Article',
            },
            {
              type: MEDIA_TYPES_LABEL.SLIDESHOWS,
              Icon: BurstMode,
              label: 'Diapo',
            },
          ]}
          defaultActions={false}
          itemToIframely={async (item: any, pluginType: string) =>
            (await getItemToIframely(item, pluginType))!
          }
          itemToRender={(item: any, pluginType?: string) =>
            getItemToRender(brandKey, item, pluginType)
          }
          onClick={async (item: any) => {
            handleClose();
            history.push(
              generatePath(`${paths.MOBILE_PUSH}?id=${item.id}`, {
                brandKey: brandKey as string,
              }),
            );
          }}
          searchFn={async (searchParams, opts, pluginType) =>
            await searchByPluginType({
              searchParams,
              pluginType,
              opts,
              brandKey,
            })
          }
        />
      )}
    </>
  );
};
