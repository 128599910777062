import { useQuery, gql } from '@apollo/client';
import curry from 'lodash/fp/curry';
import { SearchImageSourceCategories } from '../../__generated__/queries-web';
import { client } from '..';

const GET_IMAGE_SOURCES_CATEGORIES = gql`
  query GetImageSourceCategories {
    imageSourceCategories(first: 100, orderBy: title_ASC) {
      id
      title
      imageSources(first: 100, orderBy: title_ASC) {
        id
        title
      }
    }
  }
`;

const SEARCH_IMAGE_SOURCES_CATEGORIES = gql`
  query SearchImageSourceCategories(
    $where: ImageSourceCategoryWhereInput
    $sourceWhere: ImageSourceWhereInput
  ) {
    imageSourceCategories(first: 100, orderBy: title_ASC, where: $where) {
      id
      title
      imageSources(first: 100, orderBy: title_ASC, where: $sourceWhere) {
        id
        title
      }
    }
  }
`;

export const useGetImageSourceCategories = () =>
  useQuery(GET_IMAGE_SOURCES_CATEGORIES);

export const searchImageSourceCategories = async (search: string) => {
  // can't use hook, used outside components
  const { data } = await client.query<SearchImageSourceCategories>({
    query: SEARCH_IMAGE_SOURCES_CATEGORIES,
    variables: {
      where: {
        imageSources_some: {
          title_contains: search,
        },
      },
      sourceWhere: {
        title_contains: search,
      },
    },
  });
  return data?.imageSourceCategories;
};

// Utils function changing the result of GET_IMAGE_SOURCES_CATEGORIES
// Into a SuggestItemType array
export const mapImageSourceCategoriesToSuggestItems = (
  imageSourceCategories: any,
): any[] => {
  const addLevelToItem = curry(
    (level: number, item: { id: string; title: string }) => ({
      id: item.id,
      title: item.title,
      level,
    }),
  );
  const mapCategory = addLevelToItem(0);
  const mapSource = addLevelToItem(2);
  const result = [];

  for (const category of imageSourceCategories) {
    result.push(mapCategory(category));
    for (const source of category.imageSources) {
      result.push(mapSource(source));
    }
  }

  return result;
};
