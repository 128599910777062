import { closestCorners } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Settings } from '@mui/icons-material';
import { uniq } from 'lodash';
import {
  Box,
  Divider,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Switch,
} from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { DragAndDrop } from '../../../../../../components/DragAndDrop';
import { useScrollToHash } from '../../../../../../utils/useScrollToHash';
import { CategoriesContext } from '../../../../categories.context';
import { ErrorIcon } from '../../../../components/ErrorIcon';
import { Skeletons } from '../../../../../../components/Skeletons';
import { FormContext } from '../../../../form.context';
import type { CategoryForm } from '../../../../types';
import { getErrorMessages, isPageEditView } from '../../../../utils';
import {
  GENERAL_CONFIG_ANCHOR_HASH,
  GENERAL_CONFIG_LABEL,
} from '../../constants';
import { useEditSection } from '../Section/useEditSection';
import { Section } from './Section';
import { PUT_ON_THE_FRONT_PAGE } from './constants';
import { MENU_ITEM_HEIGHT, useStyles } from './styles';

export const DrawerContent: FC = () => {
  const { errors, form, handlers, isFormTouched } = useContext(FormContext);
  const { isCategoriesLoading } = useContext(CategoriesContext);
  const { handlers: sectionHandlers } = useEditSection();

  const { scrollTo } = useScrollToHash({ withHash: true });
  const history = useHistory();
  const classes = useStyles();

  const generalConfigurationErrorMessages = useMemo(() => {
    const { sections, ...restErrors } = errors;
    return uniq(getErrorMessages(restErrors));
  }, [errors]);

  const handleFormChange = useCallback(
    (fieldName: keyof CategoryForm, value: any) => {
      handlers.handleFormChange({ path: [fieldName], value });
    },
    [handlers],
  );

  const handleClickMenu = (id?: string) => {
    scrollTo(id || GENERAL_CONFIG_ANCHOR_HASH);
  };

  return (
    <div className={classes.wrapper}>
      {/* HEAD */}
      <Box component="section">
        {isCategoriesLoading && isPageEditView() ? (
          <Skeletons
            height={MENU_ITEM_HEIGHT}
            quantity={1}
            style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
          />
        ) : (
          <FormControlLabel
            className={classes.headLineField}
            control={
              <Switch
                sx={{ zoom: 1.2 }}
                checked={form.isHeadlined!}
                onChange={(event) => {
                  handleFormChange('isHeadlined', event.target.checked);
                }}
                size="small"
              />
            }
            label={PUT_ON_THE_FRONT_PAGE}
          />
        )}

        <MenuList>
          <MenuItem
            className={classes.menuItem}
            classes={{ selected: classes.menuItemSelected }}
            onClick={() => handleClickMenu()}
            selected={
              history.location.hash.substring(1) === GENERAL_CONFIG_ANCHOR_HASH
            }
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText>{GENERAL_CONFIG_LABEL}</ListItemText>

            {!!generalConfigurationErrorMessages.length && (
              <ErrorIcon
                isTouched={isFormTouched}
                errorMessages={generalConfigurationErrorMessages}
              />
            )}
          </MenuItem>
        </MenuList>
      </Box>

      <Divider />

      {/* SECTIONS ANCHORS */}
      <Box component="section">
        <MenuList className={classes.wrapperSectionItems}>
          {isCategoriesLoading && isPageEditView() ? (
            <Skeletons
              height={MENU_ITEM_HEIGHT}
              quantity={2}
              style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
            />
          ) : (
            <DragAndDrop
              dndContextProps={{
                collisionDetection: closestCorners,
                modifiers: [restrictToVerticalAxis],
              }}
              itemList={form.sections}
              onDragEnd={sectionHandlers.handleSectionsReorder}
              renderItem={(itemProps) => (
                <Section onClickSection={handleClickMenu} {...itemProps} />
              )}
              sortableContextProps={{ startegy: verticalListSortingStrategy }}
              withPortalOverlay={false}
            />
          )}
        </MenuList>
      </Box>
    </div>
  );
};
