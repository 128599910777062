import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, Dispatch, FC, memo, useCallback } from 'react';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  CategoryFormat,
  CategoryStatus,
  GetBrandCategories,
  GetBrandCategoriesVariables,
} from '../../../__generated__/queries-web';
import { client } from '../../../apollo';
import { Suggest } from '../../../components/Suggest';
import { TAGS_SECTION_ID_SELECTOR } from '../../../components/Tags/constants';
import { brandsOptions, getTitleFromBrandKey } from '../../../utils/brands';
import { formatDate } from '../../../utils/dateUtils';
import { useScrollToHash } from '../../../utils/useScrollToHash';
import { GET_BRAND_CATEGORIES } from '../../SlideshowEdit/CategorySelector/getBrandCategories.web.graphql';
import { AuthorsInput } from '../ArticleEditor/AuthorsInput';
import { CategoriesInput } from '../ArticleEditor/CategoriesInput';
import { SlugInput } from '../ArticleEditor/SlugInput';
import { SourceInput } from '../ArticleEditor/SourceInput';
import { ARTICLE_ENHANCEMENT_MAIN_TITLE } from '../constants';
import { ArticleEditAction, ArticleState, actions } from '../reducer';
import { LinksContent } from './LinksContent';
import { LinksToTopic } from './LinksToTopic';
import { Qualifiers } from './Qualifiers';
import { useStyles } from './styles';

type DrawerContentProps = {
  subjectId?: string;
  subjectTitle?: string;
  renderInput: (inputName: string) => boolean;
  dispatch: Dispatch<ArticleEditAction>;
} & Pick<
  ArticleState,
  | 'publishedAt'
  | 'articleCategories'
  | 'articleTags'
  | 'articleAuthors'
  | 'source'
  | 'articleEvents'
  | 'status'
  | 'slug'
  | 'brandKey'
  | 'articleQualifiers'
  | 'articleGuides'
  | 'articleChannels'
  | 'lead'
  | 'title'
  | 'body'
  | 'id'
  | 'format'
  | 'articleMachineTags'
>;

export const DrawerContent: FC<DrawerContentProps> = memo(
  ({
    publishedAt,
    articleCategories,
    articleAuthors,
    source,
    status,
    slug,
    renderInput,
    brandKey,
    articleQualifiers,
    articleGuides,
    articleChannels,
    dispatch,
    subjectId,
    subjectTitle,
    format,
    body,
    articleMachineTags,
  }) => {
    const { scrollTo } = useScrollToHash({ withHash: false });

    const classes = useStyles();

    const { isCerise } = brandsOptions[brandKey];

    const displayNewModule =
      brandsOptions[brandKey].displayNewModule && format === ArticleFormat.Rich;

    const channelsCallback = useCallback(async () => {
      const { data: getBrandsData } = await client.query<
        GetBrandCategories,
        GetBrandCategoriesVariables
      >({
        query: GET_BRAND_CATEGORIES,
        variables: {
          brandKey: brandKey || BrandKey.CAP,
          format: CategoryFormat.Channel,
          status: CategoryStatus.Published,
        },
      });
      return getBrandsData.categories;
    }, [brandKey]);

    const guidesCallback = useCallback(async () => {
      const { data: getCategoriesData } = await client.query<
        GetBrandCategories,
        GetBrandCategoriesVariables
      >({
        query: GET_BRAND_CATEGORIES,
        variables: {
          brandKey: brandKey || BrandKey.CAP,
          format: CategoryFormat.Guide,
        },
      });
      return getCategoriesData.categories;
    }, [brandKey]);

    const topicUrl =
      subjectId && subjectTitle
        ? `${config.FRONT_ONE_TOPIC}/subject/edit/${subjectId}`
        : undefined;

    const scheduleDateComponent = renderInput('publishedAt') && (
      <TextField
        disabled={status === ArticleStatus.Published}
        id="datetime-local"
        label="Date de publication :"
        inputProps={{
          min: '1970-01-01T00:00',
          max: '2100-01-01T00:00',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch(
            actions.updateField({
              field: 'publishedAt',
              value: e.currentTarget.value,
            }),
          );
        }}
        type="datetime-local"
        value={
          publishedAt
            ? formatDate(new Date(publishedAt), "yyyy-MM-dd'T'HH:mm")
            : ''
        }
        variant="standard"
      />
    );

    if (displayNewModule) {
      return (
        <>
          <div className={classes.scheduleDate}>
            <div className="schedule-date-header">
              <DateRangeIcon />
              <Typography>Date de Programmation</Typography>
            </div>
            <div className="schedule-date-content">{scheduleDateComponent}</div>
          </div>
          <LinksContent body={body} />
          <LinksToTopic articleMachineTags={articleMachineTags} />
        </>
      );
    }

    return (
      <div className={classes.grid}>
        <Typography variant="h6">{getTitleFromBrandKey(brandKey)}</Typography>
        {scheduleDateComponent}
        {renderInput('channel') && (
          <FormControl className={classes.formSuggest} variant="standard">
            <InputLabel shrink>Chaîne vidéo :</InputLabel>
            <Suggest
              selectedItems={articleChannels.map(({ category }) => category)}
              placeholder="Lier à une chaine vidéos"
              suggestions={channelsCallback}
              multiple={false}
              isPrefetched
              onChange={(items) => {
                dispatch(
                  actions.updateField({
                    field: 'articleChannels',
                    value: items.map((item) => ({
                      category: { id: item.id, title: item.title },
                    })),
                  }),
                );
              }}
            />
          </FormControl>
        )}
        {(renderInput('firstCategory') || renderInput('secondCategory')) && (
          <FormControl className={classes.formSuggest} variant="standard">
            <CategoriesInput
              brandKey={brandKey}
              categories={articleCategories}
              dispatch={dispatch}
              multiple={
                renderInput('firstCategory') && renderInput('secondCategory')
              }
            />
          </FormControl>
        )}

        <InputLabel shrink>Tags :</InputLabel>
        <Button
          color="primary"
          className={classes.tags}
          onClick={() => {
            scrollTo(TAGS_SECTION_ID_SELECTOR);
          }}
          variant="outlined"
        >
          {ARTICLE_ENHANCEMENT_MAIN_TITLE}
        </Button>

        {renderInput('authors') && (
          <FormControl className={classes.formSuggest} variant="standard">
            <AuthorsInput
              authors={articleAuthors}
              dispatch={dispatch}
              requiredInput={isCerise}
            />
          </FormControl>
        )}
        {renderInput('source') && (
          <FormControl className={classes.formSuggest} variant="standard">
            <SourceInput
              brandKey={brandKey}
              source={source}
              dispatch={dispatch}
            />
          </FormControl>
        )}

        <Qualifiers
          brandKey={brandKey!}
          articleQualifiers={articleQualifiers}
          articleFormat={format}
          onChange={(updatedQualifiers) => {
            dispatch(
              actions.updateField({
                field: 'articleQualifiers',
                value: updatedQualifiers,
              }),
            );
          }}
        />

        {renderInput('guides') && (
          <FormControl className={classes.formSuggest} variant="standard">
            <InputLabel shrink>Guides :</InputLabel>
            <Suggest
              selectedItems={articleGuides.map(({ category }) => category)}
              placeholder="Rechercher un guide"
              suggestions={guidesCallback}
              multiple={false}
              isPrefetched
              onChange={(items) => {
                dispatch(
                  actions.updateField({
                    field: 'articleGuides',
                    value: items.map((item) => ({
                      category: { id: item.id, title: item.title },
                    })),
                  }),
                );
              }}
            />
          </FormControl>
        )}
        {renderInput('slug') && <SlugInput slug={slug} dispatch={dispatch} />}
        {topicUrl && (
          <FormControl variant="standard">
            <InputLabel shrink color="primary">
              Sujet lié :
            </InputLabel>
            <div className={classes.textLink}>
              <a href={topicUrl} target="_blank" rel="noopener noreferrer">
                {subjectTitle}
              </a>
            </div>
          </FormControl>
        )}
      </div>
    );
  },
);
