import { useApolloClient } from '@apollo/client';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { datadogRum } from '@datadog/browser-rum';
import { negate, uniqBy } from 'lodash/fp';
import { Dispatch, useCallback } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  ArticleFormat,
  ArticleStatus,
  GetArticle,
  GetArticle_article_articleEvents,
  GetArticle_article_articleTags,
  GetArticleVariables,
  TagType,
} from '../../__generated__/queries-web';
import {
  GET_ARTICLE_QUERY,
  useGetArticle,
} from '../../apollo/queries/articles.web.graphql';
import { useArticleUpsert } from '../../apollo/mutations/article.web.graphql';
import { paths } from '../../routing/Router/paths';
import { actions, ArticleEditAction, ArticleState } from './reducer';
import { articleMutationVariables } from './utils/articleMutationVariables';
import { validateArticle } from './utils/validateArticle';
import { DATADOG_ACTIONS } from '../../tracking/actions';
import { useCreateArticleHistory } from '../../apollo/mutations/articleHistory.web.graphql';
import { auth } from '../../utils/auth';

export const useSaveArticle = (
  article: ArticleState,
  dispatch: Dispatch<ArticleEditAction>,
) => {
  const client = useApolloClient();
  const { pushNotification } = useNotification();
  const history = useHistory();
  const [upsert, { loading }] = useArticleUpsert();
  const [createArticleHistory] = useCreateArticleHistory();
  const { id } = useParams();
  const { data: getArticle } = useGetArticle(id);
  const isDuplicatedArticle = window.location.href.search('/dup/');

  // TODO : Refacto the event tag behavior
  const handleEventsInTag = useCallback(
    (saveArticle: ArticleState) => {
      const isEvent = (save: GetArticle_article_articleTags) =>
        save.tag.type === ('Event' as TagType);
      const isTagEvent = negate(isEvent);

      const articleTags = saveArticle.articleTags.filter(isTagEvent);
      const eventsFromTag = saveArticle.articleTags.filter(isEvent);

      const newArticleEvents: GetArticle_article_articleEvents[] = eventsFromTag.map(
        (item, index) => ({
          order: index,
          __typename: 'ArticleEvent',
          category: {
            id: item.tag.id,
            title: item.tag.title,
            __typename: 'Category',
          },
        }),
      );
      const articleEvents = uniqBy((event) => event.category.id, [
        ...article.articleEvents,
        ...newArticleEvents,
      ]);

      return {
        ...saveArticle,
        articleTags,
        articleEvents,
      };
    },
    [article],
  );

  const saveArticle = useCallback(
    async (forceArticle?: ArticleState, edited?: boolean) => {
      const finalArticle = handleEventsInTag(forceArticle || article);
      let prevArticleVersion;
      try {
        const prevArticleCachedVersion = client.readQuery<
          GetArticle,
          GetArticleVariables
        >({
          query: GET_ARTICLE_QUERY,
          variables: {
            where: {
              id: finalArticle.id,
            },
          },
        });
        prevArticleVersion = prevArticleCachedVersion
          ? prevArticleCachedVersion.article
          : null;
      } catch {
        prevArticleVersion = null;
      }

      const { isValid, errorMessage } = validateArticle(
        finalArticle,
        prevArticleVersion,
      );
      if (!isValid) {
        /**
         * if the article has errors when switching between status let's get back
         * to the initial article's status
         */
        if (finalArticle.status !== prevArticleVersion?.status) {
          dispatch(
            actions.updatePublishDateAndStatus({
              publishDate: prevArticleVersion?.publishedAt || undefined,
              status: prevArticleVersion?.status || ArticleStatus.Draft,
            }),
          );
        }
        pushNotification({
          type: NotificationTypeEnum.error,
          message: errorMessage,
        });

        datadogRum.addError(errorMessage, {
          action: DATADOG_ACTIONS.SAVE_ARTICLE,
        });

        return;
      }
      try {
        datadogRum.addAction(DATADOG_ACTIONS.SAVE_ARTICLE, {
          article: finalArticle,
        });

        const result = await upsert({
          variables: articleMutationVariables(
            finalArticle,
            prevArticleVersion,
            edited,
          ),
        });

        if (!result || !result.data || !result.data.upsertArticle) {
          pushNotification({
            type: NotificationTypeEnum.error,
            message: "Erreur lors de l'enregistrement",
          });
          return;
        }

        /**
         * if we have no previous article version it's a newly created one
         * so redirect to the url with its new id
         */
        if (!prevArticleVersion) {
          if (isDuplicatedArticle && getArticle?.article && auth.user?.id) {
            await createArticleHistory({
              variables: {
                data: {
                  article: {
                    connect: {
                      id,
                    },
                  },
                  status: getArticle.article.status,
                  duplicatedArticle: {
                    connect: {
                      id: result.data.upsertArticle.id,
                    },
                  },
                  userId: auth.user.id,
                },
              },
            });
          }

          history.replace(
            generatePath(paths.ARTICLE_EDIT, {
              brandKey: finalArticle.brandKey!,
              articleType:
                finalArticle.format === ArticleFormat.Rich
                  ? 'article'
                  : 'video',
              id: result.data.upsertArticle.id,
            }),
          );
        }

        pushNotification({
          type: NotificationTypeEnum.success,
          message: 'Article enregistré',
        });
      } catch (error) {
        if (error && typeof error === 'object' && 'message' in error) {
          const catchMessage = (error as { message: string }).message as string;
          let message = "Erreur lors de l'enregistrement";

          if (catchMessage.includes('HTML')) {
            const matched = catchMessage.match(/<[^>/]\w*/);
            message = `L'insertion de code ${
              matched ? matched[0] : '</'
            }> n'est pas autorisé dans un article.`;
          }
          pushNotification({
            type: NotificationTypeEnum.error,
            message,
          });
        } else {
          pushNotification({
            type: NotificationTypeEnum.error,
            message: "Erreur lors de l'enregistrement",
          });
        }
      }
    },
    [
      article,
      pushNotification,
      client,
      upsert,
      history,
      dispatch,
      handleEventsInTag,
      createArticleHistory,
      getArticle,
      id,
      isDuplicatedArticle,
    ],
  );

  return {
    saveArticle,
    loading,
  };
};
