import {
  NotificationTypeEnum,
  SearchBar,
  useNotification,
} from '@prismamedia/one-components';
import type {
  AutocompleteItem,
  SearchParams,
} from '@prismamedia/one-components';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useMountedState } from 'react-use';
import {
  BrandKey,
  CategoryFormat,
} from '../../../../../../../__generated__/queries-web';
import { useSearchPageCategories } from '../../../../../apollo/queries/categories.web.graphql';
import { CategoriesContext } from '../../../../../categories.context';
import { getBrandCategoriesWithPath } from '../../../../../utils';
import { SEARCHBAR_PLACEHOLDER_PREFIX } from './constants';
import { getBrandCategoriesAutoCompleteList } from './utils';
import { ALLOWED_FORMATS } from '../../../../../constants';

interface SearchBarPageProps {
  brandKey: BrandKey;
  format: CategoryFormat;
  onAutocompleteItemSelect: (item: AutocompleteItem) => void;
}
export const SearchBarPage: FC<SearchBarPageProps> = ({
  brandKey,
  format,
  onAutocompleteItemSelect,
}) => {
  const { activeCategory } = useContext(CategoriesContext);
  const [searchParams, setSearchParams] = useState<SearchParams>({});

  const { pushNotification } = useNotification();
  const searchPageCategories = useSearchPageCategories();
  const isMounted = useMountedState();

  const searchFilteredBrandCategoriesList = useCallback(
    async (search: string): Promise<AutocompleteItem[] | undefined> => {
      setSearchParams({ search });

      try {
        if (!brandKey) {
          return;
        }
        const {
          data: { categories: dataCategories },
        } = await searchPageCategories({
          first: 1000,
          where: {
            brandKey: brandKey!,
            format,
            level: 2,
          },
        });

        if (isMounted() && dataCategories?.length) {
          const categoriesWithPathname = getBrandCategoriesWithPath(
            dataCategories,
          );

          return new Promise((resolve) => {
            const childCategories = categoriesWithPathname?.[0];

            if (childCategories) {
              const autocompleteList = getBrandCategoriesAutoCompleteList(
                activeCategory,
                [childCategories],
                search,
              );

              return resolve(autocompleteList);
            }

            return resolve(undefined);
          });
        }
      } catch (e) {
        if (isMounted()) {
          return new Promise((_, reject) => {
            reject(undefined);

            pushNotification({
              type: NotificationTypeEnum.error,
              message: `
                [Unable to fetch brand category events]
                ${(e as Error).message}
              `,
            });
          });
        }
      }
    },
    [
      activeCategory,
      brandKey,
      format,
      isMounted,
      pushNotification,
      searchPageCategories,
    ],
  );

  const searchBarPlaceholder = useMemo(() => {
    const placeholder = SEARCHBAR_PLACEHOLDER_PREFIX;

    switch (format) {
      case CategoryFormat.Category:
        return `${placeholder} ${ALLOWED_FORMATS.find(
          ({ value }) => value === CategoryFormat.Category,
        )?.label.toLowerCase()}`;
      case CategoryFormat.Event:
        return `${placeholder} ${ALLOWED_FORMATS.find(
          ({ value }) => value === CategoryFormat.Event,
        )?.label.toLowerCase()}`;
      case CategoryFormat.Channel:
        return `${placeholder} ${ALLOWED_FORMATS.find(
          ({ value }) => value === CategoryFormat.Channel,
        )?.label.toLowerCase()}`;
      case CategoryFormat.Guide:
        return `${placeholder} ${ALLOWED_FORMATS.find(
          ({ value }) => value === CategoryFormat.Guide,
        )?.label.toLowerCase()}`;
    }
  }, [format]);

  return (
    <SearchBar
      autocomplete={{
        fetchList: searchFilteredBrandCategoriesList,
        onSelect: onAutocompleteItemSelect,
      }}
      placeholder={searchBarPlaceholder}
      setSearchParams={setSearchParams}
      searchParams={searchParams}
    />
  );
};
