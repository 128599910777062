import { MobileScreenShare, Whatshot } from '@mui/icons-material';
import { Card, CardMedia, Chip, Paper, Typography } from '@mui/material';
import {
  ChipList,
  ImageLoader,
  InfiniteTable,
  MediaIcons,
  SearchParams,
  isDefined,
  useAppBarHeight,
} from '@prismamedia/one-components';
import { format, isAfter, isBefore } from 'date-fns';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  RecipeOrderByInput,
  RecipeStatus,
} from '../../../__generated__/queries-recipe';
import {
  ArticleFormat,
  ArticleSearchOrderByInput,
  ArticleStatus,
  SearchArticles_searchArticles_articleEvents,
} from '../../../__generated__/queries-web';
import { useGetRecipes } from '../../../apollo/queries/recipes.recipe.graphql';
import { useSearchArticles } from '../../../apollo/queries/searchArticles.web.graphql';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { statuses } from '../../../utils/statuses';
import { Filters, Format } from '../FiltersDrawer/utils';
import {
  PAID_ARTICLE,
  articleFormatToFormatMap,
  getItemImage,
  getItemPath,
  getWhere,
  ignoreArticleQuery,
  ignoreRecipeQuery,
} from '../utils';
import { useStyles } from './styles';

const PAGINATION = 50;

interface RowItem {
  publishedAt: string | null;
  paid?: boolean;
  format: Format | undefined;
  brandKey: string;
  articleEvents?: SearchArticles_searchArticles_articleEvents[];
  title: string | null;
  categories: { id: string; label: string }[];
  authorName: string | undefined | null;
  medias: string | null;
  liveCount?: number | null | undefined;
  pushCount?: number;
  status: ArticleStatus | RecipeStatus | null;
  href: string | undefined;
  body?: string | null;
}

interface TableProps {
  disableScroll?: boolean;
  filters: Filters;
  searchParams: SearchParams;
}

export const Table: FC<TableProps> = ({
  disableScroll,
  filters,
  searchParams,
}) => {
  const history = useHistory();
  const [brandKey] = useGlobalBrandKey();
  const appBarHeight = useAppBarHeight();

  const {
    data: articleData,
    fetchMore: articleFetchMore,
    loading: articleLoading,
    error: articleError,
  } = useSearchArticles(
    {
      first: PAGINATION,
      skip: 0,
      orderBy: [
        ArticleSearchOrderByInput.publishedAt_DESC,
        ArticleSearchOrderByInput.editedAt_DESC,
      ],
      where: getWhere(filters, searchParams, brandKey).articleWhere,
    },
    ignoreArticleQuery(filters),
  );

  const {
    data: recipeData,
    fetchMore: recipeFetchMore,
    loading: recipeLoading,
  } = useGetRecipes(
    {
      first: PAGINATION,
      skip: 0,
      orderBy: [
        RecipeOrderByInput.publishedAt_DESC,
        RecipeOrderByInput.editedAt_DESC,
      ],
      where: getWhere(filters, searchParams, brandKey).recipeWhere,
    },
    ignoreRecipeQuery(filters, brandKey),
  );

  const classes = useStyles({
    appBarHeight,
    loadingOrError: !articleData?.articles.length || !!articleError,
  });

  const list: RowItem[] = useMemo(
    () =>
      [
        ...(articleData?.articles || []).map((article) => ({
          publishedAt: article.publishedAt,
          paid: article.articleQualifiers?.some(
            ({ qualifier }) => qualifier.title === PAID_ARTICLE,
          ),
          format: articleFormatToFormatMap[article.format],
          brandKey: article.brandKey,
          articleEvents: article.articleEvents,
          title: article.title,
          categories: article.articleCategories
            .map(({ category }) => category)
            .filter(isDefined)
            .map(({ id, title }) => ({
              id,
              label: title,
            })),
          authorName: article.articleCreatorData?.name,
          medias: article.medias,
          liveCount: article.live?.postCount,
          pushCount: article.pushCount,
          status: article.status,
          body: article.body,
          href: getItemPath(
            articleFormatToFormatMap[article.format],
            article.brandKey,
            article.id,
          ),
        })),
        ...(recipeData?.recipes || []).map((recipe) => ({
          publishedAt: recipe.publishedAt,
          format: Format.Recipe,
          brandKey: recipe.brandKey,
          title: recipe.title,
          categories: recipe.recipeCategories
            .map(({ category }) => category)
            .filter(isDefined)
            .map(({ id, title }) => ({
              id,
              label: title,
            })),
          authorName: recipe.authorName,
          medias: recipe.medias,
          status: recipe.status,
          href: getItemPath(Format.Recipe, recipe.brandKey, recipe.id),
        })),
      ].sort((a, b) => {
        if (!a.publishedAt) return 1;
        if (!b.publishedAt) return -1;
        if (isBefore(new Date(a.publishedAt), new Date(b.publishedAt))) {
          return 1;
        }
        if (isAfter(new Date(a.publishedAt), new Date(b.publishedAt))) {
          return -1;
        }
        return 0;
      }),
    [articleData?.articles, recipeData?.recipes],
  );

  return (
    <Paper className={classes.wrapper}>
      <InfiniteTable
        list={list}
        loading={articleLoading || recipeLoading}
        error={articleError}
        rowHeight={70}
        threshold={5}
        gridStyle={{ overflowY: disableScroll ? 'hidden' : 'auto' }}
        onRowClick={(e, item: RowItem) => {
          if (!item.href || item.format === Format.Recipe) return;
          e.preventDefault();
          history.push(item.href);
        }}
        fetchMore={async () => {
          articleFetchMore({
            variables: {
              skip: articleData?.articles.length || 0,
            },
          });

          recipeFetchMore({
            variables: {
              skip: recipeData?.recipes.length || 0,
            },
          });
        }}
        columns={[
          {
            label: 'Publié le',
            key: 'publishedAt',
            width: 120,
            cellRenderer: (rowData: RowItem) => (
              <div className={classes.dateWrapper}>
                <Typography>
                  {rowData.publishedAt &&
                    format(new Date(rowData.publishedAt), 'P')}
                </Typography>
                <Typography variant="body2" color="GrayText">
                  {rowData.publishedAt &&
                    format(new Date(rowData.publishedAt), 'à p')}
                </Typography>
              </div>
            ),
          },
          {
            label: 'Image',
            key: 'image',
            width: 90,
            cellRenderer: (rowData: RowItem) => (
              <Card elevation={0} className={classes.imageCard}>
                <CardMedia className={classes.image}>
                  <ImageLoader
                    src={getItemImage(
                      rowData.format,
                      rowData.medias,
                      rowData.body,
                    )}
                  />
                </CardMedia>
              </Card>
            ),
          },
          {
            label: 'Description',
            key: 'description',
            width: 100,
            flexGrow: 1,
            cellRenderer: (rowData: RowItem) => (
              <div className={classes.titleWrapper}>
                <Typography>
                  {rowData.paid && (
                    <div className={classes.paidArticleIcon}>★</div>
                  )}
                  <Typography color="GrayText" variant="body2" component="span">
                    {rowData.format === Format.Slideshow ? 'Diapo • ' : ''}
                    {rowData.format === Format.Recipe ? 'Recette • ' : ''}
                    {rowData.brandKey}
                    {rowData.articleEvents?.length ? ' • ' : ''}
                    {rowData.articleEvents
                      ?.map((event) => event.category.title)
                      .join(', ')}
                    {' • '}
                  </Typography>
                  {rowData.title}
                </Typography>
              </div>
            ),
          },
          {
            label: 'Catégories',
            key: 'categories',
            width: 160,
            cellRenderer: (rowData: RowItem) => (
              <ChipList
                className={classes.chipsList}
                items={rowData.categories.slice(0, 1)}
              />
            ),
          },
          {
            label: 'Contributeurs',
            key: 'contributors',
            width: 160,
            cellDataGetter: ({ rowData }: { rowData: RowItem }) =>
              rowData.authorName,
          },
          {
            label: 'Médias',
            key: 'medias',
            width: 125,
            cellRenderer: (rowData: RowItem) => {
              return (
                <MediaIcons
                  className={classes.mediaIconsWrapper}
                  mediasAsJson={rowData.medias}
                  additionnalIcons={
                    rowData.liveCount
                      ? [{ Icon: Whatshot, count: rowData.liveCount }]
                      : undefined
                  }
                />
              );
            },
          },
          {
            label: 'Diffusion',
            key: 'diffusion',
            width: 125,
            cellRenderer: (rowData: RowItem) => {
              return (
                <MediaIcons
                  className={classes.mediaIconsWrapper}
                  mediasAsJson=""
                  additionnalIcons={
                    rowData.pushCount
                      ? [{ Icon: MobileScreenShare, count: rowData.pushCount }]
                      : undefined
                  }
                />
              );
            },
          },
          {
            label: 'Statut',
            key: 'status',
            width: 120,
            cellRenderer: (rowData: RowItem) => {
              if (!rowData.status) return null;
              return (
                <div className={classes.statusWrapper}>
                  <Chip
                    label={statuses(ArticleFormat.Rich)[rowData.status].label}
                    color="primary"
                    style={{
                      backgroundColor: statuses(ArticleFormat.Rich)[
                        rowData.status
                      ].color,
                    }}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </Paper>
  );
};
