import type { ContentBlock } from 'draft-js';

export const cellBlockStyleFn = (contentBlock: ContentBlock): string => {
  const type = contentBlock.getType();
  const classes = [];

  if (type === 'blockquote') {
    classes.push('cellBlockQuote');
  } else if (type === 'unordered-list-item' || type === 'ordered-list-item') {
    classes.push('cellListItem');
  }

  return classes.join(' ');
};
