import { Chip, ListSubheader, Typography } from '@mui/material';
import type { BrandKey } from '@prismamedia/one-brandkey';
import {
  ItemSerialized,
  MultimediaList,
  MultimediaSearch,
  SearchParams,
  ViewMode,
} from '@prismamedia/one-components';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import type { FotoProps } from '..';
import { useGetProvidersPhotos } from '../../../../apollo/queries/photosProviders.web.graphql';
import { pluralizeText } from '../../../../utils/pluralizeText';
import { uploadAndEmbedImageUrl } from '../../../../utils/upload';
import { PhotoProvider } from '../../../../__generated__/queries-web';
import { PhotoCollapse } from '../components/PhotoCollapse';
import { useStyles as usePhotoCollapseStyles } from '../components/styles';
import { PHOTOS_TECHNICAL_SOURCES } from '../constants';
import { FotoItemSerialized, PhotoCollapsesTypes } from '../types';
import {
  AND,
  DEFAULT_FILTER,
  PREFIX_SEARCH_BAR_PLACEHOLDER,
  RESULT_TEXT,
} from './constants';
import { PixabayIcon, ProviderIcon, UnsplashIcon } from './Icons';
import { useStyles } from './styles';

interface ProvidersPhotoProps extends FotoProps<FotoItemSerialized> {
  brandKey: BrandKey;
  isCollapseOpen: boolean;
  onCollapseClick: (summary: PhotoCollapsesTypes | null) => void;
}
export const ProvidersPhoto: FC<ProvidersPhotoProps> = ({
  brandKey,
  isCollapseOpen,
  isDisabled,
  isLoading,
  itemToRender,
  items,
  onCollapseClick,
  onItemClick,
  pluginType,
  selectedItems,
  setItems,
  value = '',
}) => {
  const [defaultItemsLength, setDefaultItemsLength] = useState<number | null>(
    null,
  );
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: value,
  });

  const [providersFilter, setProvidersFilter] = useState(DEFAULT_FILTER);

  const searchBarPlaceholder = useMemo(() => {
    const lastFilterStr = providersFilter.slice(-1)?.[0];
    const restFilterStr = providersFilter.slice(0, -1).join(', ');

    const SUFFIX_SEARCH_BAR_PLACEHOLDER =
      lastFilterStr && lastFilterStr !== restFilterStr
        ? restFilterStr.length
          ? `${restFilterStr} ${AND} ${lastFilterStr}`
          : lastFilterStr
        : restFilterStr;

    return `${PREFIX_SEARCH_BAR_PLACEHOLDER} ${SUFFIX_SEARCH_BAR_PLACEHOLDER}`;
  }, [providersFilter]);

  const { data, loading: isProvidersPhotosLoading } = useGetProvidersPhotos(
    {
      query: searchParams.search || '',
      perPage: 50,
      providers: providersFilter,
    },
    !isCollapseOpen,
  );

  const classes = useStyles({ isOpen: isCollapseOpen });
  const photoCollaspeClasses = usePhotoCollapseStyles({});

  const getItemToIframely = async (item: ItemSerialized) => {
    if (item.medium || item.large || item.small) {
      const iframely = await uploadAndEmbedImageUrl(
        (item.medium || item.large || item.small)!,
        brandKey,
        undefined,
      );

      if (iframely) {
        const source = item.provider.toUpperCase();
        iframely.meta.credit = source;
        iframely.meta.source = source;
        iframely.meta.technicalSource =
          PHOTOS_TECHNICAL_SOURCES[item.provider as PhotoProvider];
      }

      return iframely;
    }

    return Promise.reject();
  };

  const resetSearch = useCallback(() => {
    setItems([]);
  }, [setItems]);

  useEffect(() => {
    resetSearch();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleProvidersFilterChanges = useCallback(
    (selectedProvider: PhotoProvider) => {
      const providerIndex = providersFilter.indexOf(selectedProvider);
      const newProvidersFilder =
        providerIndex > -1
          ? providersFilter.filter(
              (providerFilter) => providerFilter !== selectedProvider,
            )
          : [...providersFilter, selectedProvider];

      setProvidersFilter(newProvidersFilder);
    },
    [providersFilter],
  );

  useEffect(() => {
    if (data?.searchPhotosByProviders) {
      const newItems = data.searchPhotosByProviders
        .map((photosByProviders) =>
          photosByProviders.items.map((photo) => ({
            ...photo,
            from: PhotoCollapsesTypes.PROVIDERS,
            pluginType,
            provider: photosByProviders.name,
          })),
        )
        .flat(1);

      setItems(newItems);
      setDefaultItemsLength(newItems.length);
    }

    // eslint-disable-next-line
  }, [data]);

  return (
    <PhotoCollapse
      collapseType={PhotoCollapsesTypes.PROVIDERS}
      defaultItemsLength={defaultItemsLength}
      Icon={
        <>
          {/* <GettyIcon /> */}
          <PixabayIcon />
          <UnsplashIcon />
        </>
      }
      isOpen={isCollapseOpen}
      isPhotoLoading={isProvidersPhotosLoading}
      onCollapseClick={onCollapseClick}
    >
      <div className={classes.topInnerWrapper}>
        <MultimediaSearch
          className={classes.multimediaSearch}
          searchParams={searchParams}
          placeholder={searchBarPlaceholder}
          setSearchParams={setSearchParams}
        />

        <div className={classes.wrapperProvidersFilter}>
          {Object.values(PhotoProvider)
            .filter((provider) => provider !== PhotoProvider.Getty)
            .map((provider) => (
              <Chip
                color={
                  providersFilter.includes(provider) ? 'primary' : 'default'
                }
                disabled={
                  providersFilter.length === 1 &&
                  providersFilter.includes(provider)
                }
                key={provider}
                label={<ProviderIcon provider={provider} />}
                onClick={() => {
                  handleProvidersFilterChanges(provider);
                }}
                size="small"
                variant={
                  providersFilter.includes(provider) ? 'filled' : 'outlined'
                }
              />
            ))}
        </div>

        <ListSubheader
          className={classes.headerText}
          component="span"
          disableSticky
        >
          <Typography variant="body2" gutterBottom>
            <span className={photoCollaspeClasses.suggestText}>
              {pluralizeText(items.length, RESULT_TEXT)} (
              {isProvidersPhotosLoading ? '...' : items.length})
            </span>
          </Typography>
        </ListSubheader>
      </div>

      <MultimediaList
        className={classes.wrapperList}
        isDisabled={isDisabled}
        isLoading={isLoading || isProvidersPhotosLoading}
        itemToRender={itemToRender}
        items={items}
        onItemClick={(rowItem: FotoItemSerialized) => {
          onItemClick(rowItem, pluginType, () =>
            getItemToIframely((rowItem as unknown) as ItemSerialized),
          );
        }}
        pluginType={pluginType}
        selectedItems={selectedItems}
        viewMode={ViewMode.GRID}
      />
    </PhotoCollapse>
  );
};
