import { FormControlLabel, Switch, TextField } from '@mui/material';
import {
  PageWithDrawer,
  NonEditableField,
  getRequiredErrorMessage,
  objectsAreDifferents,
  EditAppBar,
  FieldValidator,
} from '@prismamedia/one-components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { useGlobalBrandKey } from '../../../utils/globalState';
import {
  BrandKey,
  CreateSegment,
  GetSegment_segment,
  SegmentStatus,
} from '../../../__generated__/queries-web';
import { useGetSegment } from './getSegment.web.graphql';
import { useStyles } from './styles';
import {
  useCreateSegment,
  useUpdateSegment,
} from './updateSegment.web.graphql';

const getEmptyItem = (brandKey: BrandKey): GetSegment_segment => ({
  active: true,
  __typename: 'Segment',
  id: '',
  brandKey,
  title: '',
  ios: null,
  android: null,
  status: SegmentStatus.Published,
  editorialAlert: null,
});

const saveMandatoryFields: FieldValidator<GetSegment_segment>[] = [
  {
    label: 'Titre',
    validate: ({ title }) => !!title,
  },
];

export const SegmentEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [brandKey] = useGlobalBrandKey();
  const [item, setItem] = useState<GetSegment_segment | undefined>(undefined);
  const { id } = useParams();
  const isCreate = id === 'new';
  const { data, loading, error } = useGetSegment(id, isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateItem = useUpdateSegment();
  const createItem = useCreateSegment();
  const emptyItem = useMemo(() => getEmptyItem(brandKey!), [brandKey]);
  const itemDiff = objectsAreDifferents(
    item,
    isCreate ? emptyItem : data?.segment,
  );
  const requiredFields = getRequiredErrorMessage<GetSegment_segment>(
    item,
    saveMandatoryFields,
  );

  useEffect(() => {
    setItem(isCreate ? emptyItem : data?.segment || undefined);
  }, [isCreate, data, emptyItem, setItem]);
  const onSave = async () => {
    if (item) {
      setSaveLoading(true);
      const res = isCreate
        ? await createItem({
            title: item.title,
            brandKey: item.brandKey,
            ios: item.ios,
            android: item.android,
            status: item.status,
            editorialAlert: item.editorialAlert,
            active: true,
          })
        : await updateItem(item.id, {
            title: item.title,
            ios: item.ios,
            android: item.android,
            status: item.status,
            editorialAlert: item.editorialAlert,
            active: item.active,
          });
      setSaveLoading(false);
      if (res && isCreate) {
        history.replace(
          replaceParams(paths.SEGMENT_EDIT, {
            id: (res.data as CreateSegment).createSegment.id,
          }),
        );
      }
    }
  };

  return (
    <>
      <EditAppBar
        title={
          isCreate
            ? item?.title || 'Nouveau segment'
            : data?.segment?.title || ''
        }
        onNavigateBack={() => history.push(paths.SEGMENT_LIST)}
        save={{
          disabled: !itemDiff && !isCreate,
          loading: saveLoading,
          onClick: onSave,
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!item && !data?.segment
            ? Error("Ce segment n'existe pas")
            : undefined)
        }
        rightDrawer={
          <NonEditableField
            className={classes.field}
            label="Marque"
            value={item?.brandKey}
          />
        }
      >
        {item && (
          <>
            <TextField
              className={classes.field}
              label="Titre"
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, title: value } : prev));
              }}
              value={item.title}
              variant="standard"
            />
            <TextField
              className={classes.field}
              label="Query iOS"
              multiline
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, ios: value } : prev));
              }}
              value={item.ios}
              variant="standard"
            />
            <TextField
              className={classes.field}
              label="Query Android"
              multiline
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, android: value } : prev));
              }}
              value={item.android}
              variant="standard"
            />

            <TextField
              className={classes.field}
              label="Query Web"
              multiline
              onChange={({ target: { value } }) => {
                setItem((prev) =>
                  prev ? { ...prev, editorialAlert: value } : prev,
                );
              }}
              value={item.editorialAlert}
              variant="standard"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={item.status === SegmentStatus.Published}
                  onChange={({ target: { checked } }) => {
                    setItem((prev) =>
                      prev
                        ? {
                            ...prev,
                            status: checked
                              ? SegmentStatus.Published
                              : SegmentStatus.Draft,
                          }
                        : prev,
                    );
                  }}
                />
              }
              label={
                item.status === SegmentStatus.Published ? 'Activé' : 'Désactivé'
              }
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
