import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import {
  BrandKey,
  TabType,
} from '../../../../../../../__generated__/queries-web';
import { Draft } from '../../../../../../../components/Draft';
import { actions } from '../../../../../../../components/Draft/DraftActions';
import { PluginType } from '../../../../../../ArticleEdit/templates/utils';
import { renderPluginsFromTemplate } from '../../../../../../ArticleEdit/utils/renderFromTemplate';
import type { CategoryFormSectionTextContent, SectionForm } from '../types';
import { FREE_TEXT_CONTENT_PLACEHOLDER } from './constants';
import { useEditText } from './useEditText';

export type TextProps = SectionForm<
  CategoryFormSectionTextContent,
  TabType.Text
>;

export const Text: FC<TextProps> = memo((sectionProps) => {
  const { handlers: textHandlers, content: textContent } = useEditText(
    sectionProps,
  );

  const { brandKey } = useParams();

  return (
    <Box>
      <Draft
        actions={[
          actions.BOLD,
          actions.ITALIC,
          actions.STRIKETHROUGH,
          actions.LINK,
          actions.ANCHOR_LINK,
          actions.INTERNAL_LINK,
          actions.TITLE,
          actions.HEADLINE,
          actions.LIST,
          actions.ORDERED_LIST,
          actions.BLOCKQUOTE,
        ]}
        onChange={textHandlers.handleChangeText}
        placeholder={FREE_TEXT_CONTENT_PLACEHOLDER}
        plugins={renderPluginsFromTemplate(brandKey as BrandKey, [
          PluginType.Table,
        ])}
        rawDraft={textContent.body}
        withErrors={true}
      />
    </Box>
  );
});
